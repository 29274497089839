

import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { CarouselClientes } from './CarouselClientes';
import '../../../assets/css/sitio/clientes.css';
import { Element } from 'react-scroll';

import img_banner from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Rafael_Castaneda_Fernandez.jpg';
import img_cifras from '../../../assets/images/sitio/clientes/cifras_1.png';
import img_valor from '../../../assets/images/sitio/clientes/proupuestas_valor_1.png';

const { Title, Paragraph } = Typography;

export const Clientes = (params) => {
    return (
        <>
            <Row className="">
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} className="banner_clientes">
                    <Row>
                        <Col span={20} offset={2}>

                            <Paragraph className="titulo_nosotros">
                                <br /><br />
                            </Paragraph>
                        </Col>
                        
                        <Col span={24}>
                            <Row style={{ display: 'non' }} className="contenedor_frase_clientes">
                                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                                    <Row align="middle">
                                        <Col xs={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} >
                                            <img src={img_banner} className="img_frase_doctora" />
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 0 }}>
                                            <Paragraph className="texto_frase_doctora" style={{color:'#000000'}}>
                                                <strong>¡No fuimos los primeros,<br />
                                                pero somos los primeros que nuestros clientes recomiendan!</strong>
                                                <Element name="#propuesta-valor" ></Element>
                                            </Paragraph>
                                            <Paragraph className="texto_frase_doctora_2" style={{color:'#000000'}}>
                                                <i>Rafael Castañeda Fernández</i>
                                            </Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row className="contenedor_seccion_clientes" style={{ display: 'non' }}>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <p className="titulo_seccion_servicios" >NUESTRA PROPUESTA DE VALOR</p>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <p className="titulo_seccion_servicios_movil" >NUESTRA PROPUESTA DE VALOR</p><br />
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
                    <img src={img_valor} style={{width:'100%'}} />
                    <Element name="#maf-cifras" ></Element>
                </Col>
            </Row>
            <Row className="contenedor_seccion_clientes" style={{ display: 'non' }}>
            
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}><br /><br /><br /><br />
                    <p className="titulo_seccion_servicios" >MAF EN CIFRAS</p><br />
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <p className="titulo_seccion_servicios_movil" >MAF EN CIFRAS</p><br />
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
                    <img src={img_cifras} style={{width:'100%'}} />
                </Col>
            </Row>
            
        </>


    );

};