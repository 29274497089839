import React from 'react';
import { Row, Col } from 'antd';

import img_src_header from '../../../assets/images/paginas/roles_genero/testo_roles_genero.jpg';
import img_src_roles from '../../../assets/images/paginas/roles_genero/ejemplo_roles_genero.jpg';

export const Estereotipos = () => {
    return(
        <Row >
            <Col span={24}>
                <img alt="articulo Estereotipos" src={img_src_header} style={{width:'100%'}} />
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                <p className="color_gris_claro texto_tamano" style={{fontWeight:'bold', textAlign:'center', fontSize:'20px', fontStyle:'italic'}}>
                    <br />En repetidas ocasiones, seguramente, hemos escuchado hablar acerca de los roles y estereotipos de género, volviéndose las grandes incógnitas: ¿qué significan esos términos?, ¿cómo diferenciamos uno del otro? o sí, ¿participo en la perpetuidad de estas conductas?<br />
    Si alguna vez te has hecho estas preguntas, ¡estás en el lugar indicado! Aquí te decimos cómo identificarlos y contrarrestarlos.</p>
    <br />
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    Por Evelyn Esquivel
    <br /><br />
    Desde la antigüedad, el reparto de actividades entre mujeres y hombres se determinaba por las capacidades físicas y las características biológicas de cada uno de los géneros. En la actualidad y de acuerdo con los datos del Instituto Internacional de Nutrición y Ciencias del Deporte1, se ha demostrado que los hombres poseen mayor masa muscular, mayor masa ósea y menor contenido de grasa a diferencia de las mujeres. Por otro lado, un estudio realizado en la Universidad de Pensilvania (Estados Unidos2), muestra que las mujeres tienen actividad cerebral en medio del hemisferio derecho e izquierdo, implicando que tengan habilidades para el procesamiento de información que facilite la realización de múltiples tareas al mismo tiempo (multitasking). Dichas diferencias han sido transformadas por ideas socio - culturales que han establecido los lineamientos sobre las pautas que deben seguir los roles genéricos acerca de las expectativas de cada uno de ellos y las relaciones, incluso de subordinación, que hay entre mujeres y hombres, logrando que cada uno de los géneros se logre percibir individualmente con las características o cualidades que al paso de los años la sociedad ha atribuido para mujeres y hombres.

    <br /><br />
Hablar de roles y estereotipos de género implica entender el significado de cada uno de estos términos, razón por la cual, la Comisión Nacional de los Derechos Humanos ha definido el rol de género como los comportamientos, tareas y funciones que una persona debe desempeñar según su sexo, y que, por estar construidos social y culturalmente, pueden cambiar. Mientras tanto, el estereotipo de género se refiere a las creencias y atribuciones sociales sobre cómo debe ser y cómo debe comportarse cada género.

<br /><br />
En palabras más sencillas, ambos se refieren a una construcción social, en donde el rol implica lo que se espera de una mujer o un hombre, mientras que el estereotipo es la “etiqueta” que define las características de cómo debe comportarse socialmente cada persona según su género.

<br /><br />
Desde pequeñas y pequeños hemos atestiguado o sido partícipes de ciertas conductas sociales que marcan las diferencias entre mujeres y hombres, tan es así que al nacer se comienzan a realizar acciones que ponen etiquetas a cada persona; ya sea, desde el color de la ropa y los juguetes que nos compran hasta las figuras televisivas a seguir.

<br /><br />
Es decir, si eres niña te vestimos de color rosa, te compramos muñecas y desearás ser una princesa que conozca a su príncipe azul y vivan felices para siempre. Si eres niño, te vestimos de color azul, te compramos carritos o balones de futbol y aspiramos a ser superhéroes que salven el mundo y realicen acciones importantes. Esto se ha transmitido de generación en generación como un patrón que supuestamente no debe romperse, y que, por lo tanto, cuando creces, sigues reproduciendo a través de diversas acciones que son “socialmente aceptables”, pero que no por ello significa que sean correctas.
<br /><br />

Actualmente, existen diversos estereotipos de género que reproducimos de manera automática y que pasan desapercibidos porque son situaciones que hemos normalizado. El problema de los estereotipos no sólo radica en su existencia en sí, sino en la reproducción y transmisión de estas ideas de manera tal que se convierten en elementos de discriminación, cuyo fin es remarcar la desigualdad ya existente entre mujeres y hombres.

<br /><br />
Si durante el desarrollo de tus actividades cotidianas, has dicho o escuchado a alguien decir alguna frase como: “¿todavía no te casas? Entonces ya te quedaste a vestir santos”; “los videojuegos no son para las niñas”; “los hombres no lloran”; “¿estás ayudando a las labores del hogar? Eres un mandilón”; “¿cuidas tu apariencia física? ¡No seas amanerado, eso es cosa de niñas!”; estás frente a estereotipos de género.
<br /><br />

Pues una frase tan sencilla, como “¡Eso es cosa de niñas!” refleja una realidad en la que la sociedad, se considera con el derecho de emitir juicios de valor sobre las conductas y comportamientos de los demás, que repercuten en la limitación del pleno desarrollo de las mujeres en ámbitos familiares, sociales, profesionales y personales, llegando con ello a atentar contra la integridad y dignidad de las personas.

<br /><br />
Por ello, el punto de partida para identificarlos y contrarrestarlos comienza con la promoción de acciones de participación e inclusión de las mujeres en diversos sectores que creen verdadera igualdad, equidad y respeto.
<br /><br />

Por lo que, si te encuentras reproduciendo algún estereotipo de género, detente y piensa si en realidad es necesario emitir tu opinión. Si te das cuenta que lo que vas a decir puede incidir en el comportamiento, autoestima o integridad psicológica de quien te refieres de forma dañina, ¡no lo hagas! Uno de los mayores retos que tenemos como personas, es cambiar la mentalidad y la creencia tan arraigada de nuestra sociedad de que una mujer debe o puede hacer esto y un hombre puede o debe hacer aquello.

<br /><br />
No basta con que los derechos de las mujeres se encuentren plasmados en el papel de las leyes. No basta con que se tomen medidas para intentar hacerlos valer. Las acciones que pueden marcar una diferencia positiva en la sociedad empiezan en cada uno de sus integrantes; comencemos por nuestra cuenta y por nuestros círculos cercanos; marcar la diferencia en la sociedad haciendo lo correcto es tarea de todas y todos.
    </p>
            </Col>
            
            <Col xs={{span:20, offset:2}} md={{span:16, offset:4}} style={{textAlign:'center'}}>
                <img alt="Articulo estereotipos" src={img_src_roles} style={{width:'100%'}} />
            </Col>
            <Col span={20} offset={2}>
                <br />
                <p style={{fontSize:'14px'}}>
                1 Instituto Internacional de Nutrición y Ciencias del Deporte. <a target="_blank" href="https://iincd.com/composicion-corporal- sexo-edad/" className="link_sin_decoracion" >https://iincd.com/composicion-corporal- sexo-edad/</a><br />
                2 “Brain Connectivity Study Reveals Striking Differences Between Men and Women”. Ragini Verma, PhD at Perelman School of Medicine at the University of Pennsylvania <a target="_blank" href="https://www.pennmedicine.org/news/news-releases/2013/december/brain-connectivity-study-revea" className="link_sin_decoracion" >https://www.pennmedicine.org/news/news-releases/2013/december/brain-connectivity-study-revea</a>
                </p>
                <br /><br />
            </Col>
        </Row>
    );
}