import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Carousel, Divider, Popover } from 'antd';
import { CaretLeftFilled, CaretRightFilled, MailFilled, RightOutlined } from '@ant-design/icons';
import Slider from "react-slick";

import img_integrante_dra_fromow from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/01_Maf_NuestroEquipo_DraFromow.jpg';
import img_integrante_2 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/02_Maf_NuestroEquipo_GralMarioFromow.jpg';
import img_integrante_3 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/03_Maf_NuestroEquipo_JuanNinoTej.jpg';
import img_integrante_4 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/04_Maf_NuestroEquipo_MaDeLosAngelesMolina.jpg';
import img_integrante_5 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/05_Maf_NuestroEquipo_FabioOcampo.jpg';
import img_integrante_6 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/YunuenGarciaHerrera.jpg';
import img_integrante_7 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/07_Maf_NuestroEquipo_LourdesGaribay.jpg';
import img_integrante_8 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/08_Maf_NuestroEquipo_EmmanuelMartinez.jpg';
import img_integrante_9 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/09_Maf_NuestroEquipo_NestrorRuiz.jpg';
import img_integrante_15 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/15_Maf_NuestroEquipo_MarioAvila.jpg';
import img_integrante_18 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/18_Maf_NuestroEquipo_JoseUribe.jpg';
import img_integrante_20 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/20_Maf_NuestroEquipo_EduardoVidal.jpg';
import img_integrante_21 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/21_Maf_NuestroEquipo_CarlosElizarraraz.jpg';
import img_integrante_22 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/ManuelCorreaZavala.jpg';
import img_integrante_23 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Michelle_Denisse_JimenezOsorio.jpg';
import img_integrante_24 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/RosaSamanthaMoralesGonzalez.jpg';

import img_integrante_25 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Ruben_Arcas_Navarro.jpg';
import img_integrante_26 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/LuisAlexisGodinezFlores.jpg';
import img_integrante_27 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Noemi_Rodriguez_Vega.jpg';
import img_integrante_28 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/JonathanDonizdeJesus.jpg';
import img_integrante_29 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/YesicaCynthiaViverosLopez.jpg';
import img_integrante_30 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Jose_Antonio_Cabrera_Roman.jpg';
import img_integrante_31 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Michelle_Adriana_Rosas_Gonzalez.jpg';
import img_integrante_32 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/JonasDavidOcampoVazquez.jpg';
import img_integrante_33 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/MariaRebecaFelixRuiz.jpg';

import img_integrante_34 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Adriana_Maria_Barradas_Bravo_.jpg';
import img_integrante_35 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Diana_Vanermi_Bolanos_Reyes_2.jpg';
import img_integrante_36 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Dr_Enrique_Diaz_Aranda.jpg';
import img_integrante_37 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Estefania_Monterrosas_Sanchez.jpg';
import img_integrante_38 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Jaime_Alberto_Cantu_Gonzalez.jpg';
import img_integrante_39 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Luis_Martin_Chavarria_Cortes.jpg';
import img_integrante_40 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Mario_Alberto_Agraz.jpg';
import img_integrante_41 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Paola_Lizbeth_Angeles_Pacheco_2.jpg';
import img_integrante_42 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/RafaelCastanedaFernandez.jpg';
import img_integrante_43 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/T_Rosalia_Fernandez_San_Agustin.jpg';

import img_integrante_44 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Cecilia_MartInez_Sandoval.jpg';
import img_integrante_45 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Gued_Mauricio_Fuentes_Trujillo.jpg';
import img_integrante_46 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Jorge_Rafael_Coffau_Kayser.jpg';
import img_integrante_47 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Mario_German_Fromow_Rangel.jpg';

import img_integrante_48 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Alexandra_Garduno_Avila.jpg';
import img_integrante_49 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Carlos_Javier_Sanchez_Mancilla.jpg';
import img_integrante_50 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Dariana_Gonzalez_Galicia.jpg';
import img_integrante_51 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Diana_Paola_Espinosa_Fuentes.jpg';
import img_integrante_52 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Jean_Paul_Becerril_Celis.jpg';
import img_integrante_53 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Jeniffer_Garcia_Martinez.jpg';
import img_integrante_54 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Nicolas_Martinez_Garcia.jpg';

import img_integrante_55 from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Gral_Mario_Guillermo_Fromow_Garcia.jpg';


import img_circular_integrante_1 from '../../../assets/images/sitio/nosotros/popup/01_Maf_NuestroEquipo_DraFromow.c-01.png';
import img_circular_integrante_2 from '../../../assets/images/sitio/nosotros/popup/02_Maf_NuestroEquipo_GralMarioFromow.png';
import img_circular_integrante_3 from '../../../assets/images/sitio/nosotros/popup/03_Maf_NuestroEquipo_JuanNinoTej.png';
import img_circular_integrante_4 from '../../../assets/images/sitio/nosotros/popup/04_Maf_NuestroEquipo_MaDeLosAngelesMolina.c-01.png';
import img_circular_integrante_5 from '../../../assets/images/sitio/nosotros/popup/05_Maf_NuestroEquipo_FabioOcampo.93b93a38-01.png';
import img_circular_integrante_6 from '../../../assets/images/sitio/nosotros/popup/YunuenGarciaHerrera.png';
import img_circular_integrante_7 from '../../../assets/images/sitio/nosotros/popup/07_Maf_NuestroEquipo_LourdesGaribay.271a0f05-01.png';
import img_circular_integrante_8 from '../../../assets/images/sitio/nosotros/popup/08_Maf_NuestroEquipo_EmmanuelMartinez.c-01.png';
import img_circular_integrante_9 from '../../../assets/images/sitio/nosotros/popup/09_Maf_NuestroEquipo_NestrorRuiz.d67fa841-01 copia.png';
import img_circular_integrante_15 from '../../../assets/images/sitio/nosotros/popup/15_Maf_NuestroEquipo_MarioAvila.a7b71c00-01.png';
import img_circular_integrante_18 from '../../../assets/images/sitio/nosotros/popup/18_Maf_NuestroEquipo_JoseUribe.c.png';
import img_circular_integrante_20 from '../../../assets/images/sitio/nosotros/popup/20_Maf_NuestroEquipo_EduardoVidal.png';
import img_circular_integrante_21 from '../../../assets/images/sitio/nosotros/popup/21_Maf_NuestroEquipo_CarlosElizarraraz.png';
import img_circular_integrante_22 from '../../../assets/images/sitio/nosotros/popup/ManuelCorreaZavala.png';
import img_circular_integrante_23 from '../../../assets/images/sitio/nosotros/popup/Michelle_Denisse_JimenezOsorio.png';
import img_circular_integrante_24 from '../../../assets/images/sitio/nosotros/popup/RosaSamanthaMoralesGonzalez.png';

import img_circular_integrante_25 from '../../../assets/images/sitio/nosotros/popup/Ruben_Arcas_Navarro.png';
import img_circular_integrante_26 from '../../../assets/images/sitio/nosotros/popup/LuisAlexisGodinezFlores.png';
import img_circular_integrante_27 from '../../../assets/images/sitio/nosotros/popup/Noemi_Rodriguez_Vega.png';
import img_circular_integrante_28 from '../../../assets/images/sitio/nosotros/popup/JonathanDonizdeJesus.png';
import img_circular_integrante_29 from '../../../assets/images/sitio/nosotros/popup/YesicaCynthiaViverosLopez.png';
import img_circular_integrante_30 from '../../../assets/images/sitio/nosotros/popup/Jose_Antonio_Cabrera_Roman.png';
import img_circular_integrante_31 from '../../../assets/images/sitio/nosotros/popup/Michelle_Adriana_Rosas_Gonzalez.png';
import img_circular_integrante_32 from '../../../assets/images/sitio/nosotros/popup/JonasDavidOcampoVazquez.png';
import img_circular_integrante_33 from '../../../assets/images/sitio/nosotros/popup/MariaRebecaFelixRuiz.png';

import img_circular_integrante_34 from '../../../assets/images/sitio/nosotros/popup/Adriana_Maria_Barradas_Bravo_.png';
import img_circular_integrante_35 from '../../../assets/images/sitio/nosotros/popup/Diana_Vanermi_Bolanos_Reyes_2.png';
import img_circular_integrante_36 from '../../../assets/images/sitio/nosotros/popup/Dr_Enrique_Diaz_Aranda.png';
import img_circular_integrante_37 from '../../../assets/images/sitio/nosotros/popup/Estefania_Monterrosas_Sanchez.png';
import img_circular_integrante_38 from '../../../assets/images/sitio/nosotros/popup/Jaime_Alberto_Cantu_Gonzalez.png';
import img_circular_integrante_39 from '../../../assets/images/sitio/nosotros/popup/Luis_Martin_Chavarria_Cortes.png';
import img_circular_integrante_40 from '../../../assets/images/sitio/nosotros/popup/Mario_Alberto_Agraz.png';
import img_circular_integrante_41 from '../../../assets/images/sitio/nosotros/popup/Paola_Lizbeth_Angeles_Pacheco_2.png';
import img_circular_integrante_42 from '../../../assets/images/sitio/nosotros/popup/RafaelCastanedaFernandez.png';
import img_circular_integrante_43 from '../../../assets/images/sitio/nosotros/popup/T_Rosalia_Fernandez_San_Agustin.png';

import img_circular_integrante_44 from '../../../assets/images/sitio/nosotros/popup/Cecilia_MartInez_Sandoval.png';
import img_circular_integrante_45 from '../../../assets/images/sitio/nosotros/popup/Gued_Mauricio_Fuentes_Trujillo.png';
import img_circular_integrante_46 from '../../../assets/images/sitio/nosotros/popup/Jorge_Rafael_Coffau_Kayser.png';
import img_circular_integrante_47 from '../../../assets/images/sitio/nosotros/popup/Mario_German_Fromow_Rangel.png';


import img_circular_integrante_48 from '../../../assets/images/sitio/nosotros/popup/Alexandra_Garduno_Avila.png';
import img_circular_integrante_49 from '../../../assets/images/sitio/nosotros/popup/Carlos_Javier_Sanchez_Mancilla.png';
import img_circular_integrante_50 from '../../../assets/images/sitio/nosotros/popup/Dariana_Gonzalez_Galicia.png';
import img_circular_integrante_51 from '../../../assets/images/sitio/nosotros/popup/Diana_Paola_Espinosa_Fuentes.png';
import img_circular_integrante_52 from '../../../assets/images/sitio/nosotros/popup/Jean_Paul_Becerril_Celis.png';
import img_circular_integrante_53 from '../../../assets/images/sitio/nosotros/popup/Jeniffer_Garcia_Martinez.png';
import img_circular_integrante_54 from '../../../assets/images/sitio/nosotros/popup/Nicolas_Martinez_Garcia.png';

import img_circular_integrante_55 from '../../../assets/images/sitio/nosotros/popup/Gral_Mario_Guillermo_Fromow_Garcia.png';

import img_icono_facebook from '../../../assets/images/iconos/facebook_ANARANJADO.png';
import img_icono_twitter from '../../../assets/images/iconos/twitter_ANARANJADO.png';
import img_icono_instagram from '../../../assets/images/iconos/instagram_ANARANJADO.png';
import Modal from 'antd/lib/modal/Modal';
const integrantes = [
    //{ index: , nombre: '', img_src: img_integrante_2, puesto: '', mail: '', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_2, tipo_modal: 1, texto: [''], },
    {
        index: 100,
        nombre: 'Dra. Ma. de los Ángeles Fromow Rangel',
        img_src: img_integrante_dra_fromow,
        puesto: 'Directora General',
        mail: 'mfromow@mafyasociados.com',
        url_f: 'https://www.facebook.com/angelesfromowrangel',
        url_t: 'https://www.twitter.com/mafromow',
        redes_sociales: [
            {
                nombre: 'Twitter',
                url_icono: img_icono_twitter,
                url: 'https://www.twitter.com/mafromow'
            },
            {
                nombre: 'Facebook',
                url_icono: img_icono_facebook,
                url: 'https://www.facebook.com/angelesfromowrangel'
            }
        ], pop: true, img_circulo: img_circular_integrante_1, tipo_modal: 2, texto: ['Ma. de los Ángeles Fromow  es Directora General en MAF y Asociados. Desde hace más de dos décadas, su trayectoria profesional ha sido notable en distintas áreas del derecho, como el corporativo, administrativo, penal, electoral y fiscal. A lo largo de su trayectoria profesional, se ha distinguido por un liderazgo ideal, tanto en el ámbito gubernamental como en el corporativo, derivado de su capacidad de innovar, planear y comunicar la creación de estrategias integrales y transversales dirigidas a metas y objetivos claros, y con enfoque a resultados.',
            'Actualmente, se desarrolla como experta en el diagnóstico, diseño, cabildeo, implementación y evaluación de estrategias integrales en temas políticos, jurídicos y legislativos en beneficio de su entorno; sea social, público o privado. Ha continuado sumando acciones y propuestas que fortalecen la operación eficiente y coordinada del sistema penal acusatorio. Ha realizado actividades de reestructuración de modelos de atención a víctimas y capacitación para atención del fenómeno de la violencia.',
            'Destaca su experiencia laboral en el sector público federal, fue Titular de la Fiscalía Especializada para la Atención de Delitos Electorales (FEPADE); Titular de la Unidad Coordinadora de Vinculación y Participación Social de la Secretaría de Salud y Titular de la Secretaría Técnica del Consejo de Coordinación para la Implementación del Sistema de Justicia Penal Acusatorio de la Secretaría de Gobernación.',
            'Es licenciada en Derecho por la Universidad Nacional Autónoma de México, cursó la maestría en Salud Pública por el Instituto Nacional de Salud Pública y la maestría en Tributación Fiscal por el Centro de Estudios Financieros de Madrid, y es Doctora en Derecho por la Universidad Complutense de Madrid, España.'],
    },
    //{ index: 28, nombre: 'Dr. Enrique Díaz Aranda', img_src: img_integrante_36, puesto: 'Asociado', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_36, tipo_modal: 1, texto: [''], },
    { index: 31, nombre: 'Rafael Castañeda Fernández', img_src: img_integrante_42, puesto: 'Director de Asuntos Legislativos y Empresariales', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_42, tipo_modal: 1, texto: [''], },
    { index: 5, nombre: 'Lic. Yunuen García Herrera', img_src: img_integrante_6, puesto: 'Coordinadora de oficina', mail: 'ygarcia@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_6, tipo_modal: 2, texto: ['Coordinadora de la Oficina de la Directora General en MAF y Asociados. Es Licenciada en Administración, cuenta con 18 años de experiencia en la Administración Pública Federal; inició profesionalmente su carrera en la Fiscalía Especializada para la Atención de Delitos Electorales, perteneciente a la PGR, resaltando su participación para obtener la certificación de la norma internacional ISO 9001-2000 y el Premio Nacional de Innovación en la Administración Pública Federal (INNOVA).','Posteriormente en la Secretaría de Salud, estableció mecanismos de control y análisis de peticiones ciudadanas asignadas por la Red Federal de Servicio a la Ciudadanía de la oficina de Presidencia de la República.','Más tarde en la Secretaría de Gobernación, coadyuvó en el cumplimiento del término constitucional dentro de la Secretaría Técnica del Consejo de Coordinación para la implementación del Sistema de Justicia Penal vigente.'], },
    {
        index: 1, nombre: 'Gral. Mario Guillermo Fromow García', img_src: img_integrante_55, puesto: 'Socio', mail: 'gfromow@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_55, tipo_modal: 2, texto: ['Gral. D.E.M. Mario Guillermo Fromow es socio de MAF y Asociados. Se desempeñó en el H. Supremo Tribunal Militar, realizando carrera judicial militar como Defensor de Oficio del Juzgado de la 7ª. Zona Militar, Monterrey, Nuevo León; Oficial Mayor del Juzgado segundo Militar; Oficial Mayor de la Secretaría de Acuerdos del H. Supremo Tribunal Militar; Agente del Ministerio Público Militar adscrito a dicha zona y al mismo tiempo con igual cargo en la 35ª. Zona Militar; Jefe de la Asesoría Jurídica del Estado Mayor Presidencial; Magistrado y Presidente de dicho cuerpo Colegiado por Ministerio de Ley del H. Supremo Tribunal Militar y Procurador General de Justicia Militar.',
            'Se desempeñó como abogado consultor del personal militar y sus derechohabientes, pertenecientes al Estado Mayor Presidencial, Cuerpo de Guardias Presidenciales, del Escuadrón Aéreo de Transportes Presidenciales y en General Personal que ordenaba: Presidencia de la República, Secretaría Privada de la Presidencia o Jefatura del Estado Mayor Presidencial, brindando asesoría jurídica, obteniéndose un porcentaje de más del 96% de eficacia en las resoluciones judiciales de los problemas planteados.',
            'En el área civil, fue coordinador del Agentes del Ministerio Público, Auxiliares del Procurador General de Justicia de la Ciudad de México, determinando las propuestas de No Ejercicio de la Acción Penal, dicha área tenía un rezago de más de 7,000 Averiguaciones Previas, concluyendo el encargo sin dejar ningún rezago. También fue Coordinador de Asesores de la Subsecretaría de Prevención y Participación Ciudadana y Coordinador General.',
            'Obtuvo el grado de General de Brigada del Servicio de Justicia Militar y licenciado en el año de 1995, sirviendo al país desde el año de 1950. Es licenciado en Derecho por la Universidad Nacional Autónoma de México, con especialidad en Criminología. Ha cursado diversos cursos como Reacción Penal y Política Criminológica, y actualizaciones en Derecho Civil, Fiscal, Informático, Seguridad Nacional y Estudios Estratégicos.'],
    },
    {
        index: 6, nombre: 'Ma. de Lourdes Garibay Bordon', img_src: img_integrante_7, puesto: 'Directora de Administración y Finanzas', mail: 'mgaribay@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_7, tipo_modal: 2, texto: ['María de Lourdes Garibay es Coordinadora de Administración y Finanzas en MAF y Asociados. Cuenta con más de 17 años de experiencia en la Administración Pública Federal y cuatro años en Iniciativa Privada. Tiene un alta especialidad en temas relativos a presupuesto, servicios generales, adquisiciones, inventarios, auditoría, procesos organizacionales, manejo de KPI y SLA, gestión de servicios de back office, (soporte y logística corporativa); procesos de entrega-recepción y recursos humanos.',
            'Se ha desarrollado en las siguientes dependencias: Secretaría de Gobernación (SEGOB), Consejo Nacional para la Cultura y las Artes (CONACULTA) y el Instituto Nacional de Antropología e Historia (INAH); y en la en iniciativa privada: Grupo Gentera. Es maestra en Administración Pública, egresada del Instituto Nacional de Administración Pública (INAP), y licenciada en Contaduría por la Universidad Latinoamericana. '],
    },
    { index: 17, nombre: 'Manuel Correa Zavala', img_src: img_integrante_22, puesto: 'Director de Regulación y Auditoría', mail: 'mcorrea@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_22, tipo_modal: 2, texto: ['Manuel Correa es Coordinador de Contrataciones Públicas y Auditoría en MAF y Asociados. Cuenta con más de 20 años de experiencia en la Administración Pública Federal y Estatal ocupando diversas posiciones. Fue responsable de los servicios generales en el Tribunal Electoral del Distrito Federal durante el proceso electoral del 2006; Director Ejecutivo de Administración de la PDI de la PGJ de la Ciudad de México; Subdirector de Administración Programas DIF en la Delegación Benito Juárez del Gobierno del Distrito Federal; Titular del Órgano Interno de Control en la Secretaría de Administración y el Sistema Estatal Anticorrupción a nivel entidad federativa; Director General Adjunto de Adquisiciones, Administración Inmobiliaria y Activo Fijo de la SEDESOL; y se ha desempeñado como Director de Contrataciones de Bienes y Servicios, así como de obras públicas y servicios relacionados con las mismas en la Secretaría de Economía.'], },
    { index: 29, nombre: 'Mario German Fromow Rangel', img_src: img_integrante_47, puesto: 'Socio', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_47, tipo_modal: 1, texto: [''], },
    /*{
        index: 27, nombre: 'María Rebeca Félix Ruiz', img_src: img_integrante_33, puesto: 'Directora Legal Corporativo', mail: 'rfelix@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_33, tipo_modal: 2, texto: [<>
            Es responsable de coordinar y supervisar que las estrategias técnico, jurídicas y corporativas sean ejecutadas de una manera eficaz y eficiente en beneficio de nuestros socios estratégicos.<br /><br />
            Licenciada en Derecho, cuenta con un Master en Derecho Corporativo Internacional, Master en Anticorrupción por la Universidad Panamericana, México y la Universidad de Navarra, España, así como una Certificación en Política de Integridad de las empresas y Certificación en el ISO 37001 – Gestión Antisoborno.<br /><br />
            <strong>A Rebeca le encanta</strong>: El senderismo, montañismo, correr, leer y meditar.</>],
    },*/
    { index: 3, nombre: 'María de los Ángeles Molina Amaya', img_src: img_integrante_4, puesto: 'Coordinadora de Asuntos Corporativos', mail: 'mmolina@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_4, tipo_modal: 2, texto: ['María de los Ángeles Molina es Coordinadora de Asuntos Corporativos en MAF y Asociados. Inició en el ámbito profesional en una Notaría de la Ciudad de México y posteriormente, se integró a la administración pública a nivel federal dentro de las áreas jurídicas, proyectos estratégicos y de vinculación social de la Secretaría de Salud. A continuación, participó de manera activa en el proceso de implementación del Sistema de Justicia Penal vigente, dentro de la Secretaría Técnica del Consejo de Coordinación para este fin, perteneciente a la Secretaría de Gobernación, donde conjugó acciones interinstitucionales a nivel federal y local. Es licenciada en Derecho, egresada de la Universidad Nacional Autónoma de México, Facultad de Derecho.'], },
    { index: 30, nombre: 'Jorge Rafael Coffau Kayser', img_src: img_integrante_46, puesto: 'Director de Vinculación y Relaciones Institucionales', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_46, tipo_modal: 1, texto: [''], },
    {
        index: 18, nombre: 'Michelle Denisse Jiménez Osorio', img_src: img_integrante_23, puesto: 'Coordinadora de Transporte', mail: 'mjimenez@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_23, tipo_modal: 2, texto: ['Michelle Jiménez es consultora Junior de Contrataciones Públicas en MAF y Asociados. Es licenciada en Turismo y ha trabajado en el sector público en la Secretaría de Economía de la Administración Pública Federal, dentro de la Dirección de Contrataciones, participando en procedimientos bajo el amparo de la Ley de Adquisiciones, Arrendamientos y Servicios del Sector Público, así como en la operación del Sistema Gubernamental de Contrataciones Públicas denominado CompraNet. ',
            'Participó en el diseño, desarrollo e implementación del Contrato Marco que da origen al módulo denominado “Tienda Digital” del Gobierno Federal, a través del cual se llevan a cabo los procedimientos de contratación mediante el uso de catálogos electrónicos. De 2013 a 2017 trabajo en la Secretaría de Turismo del Estado de Veracruz dentro de los programas de capacitación turística para servidores públicos y prestadores de servicios, además de colaborar en la logística y operación de eventos como los Juegos Centroamericanos y del Caribe en 2014, y la XXIV Cumbre Iberoamericana de Jefes de Estado y de Gobierno.'],
    },
    
    //{ index: 7, nombre: 'Emmanuel Martínez Suazo', img_src: img_integrante_8, puesto: 'Gerente en Temas de Seguridad, Prevención y Capacitación', mail: 'emartinez@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_8, tipo_modal: 2, texto: ['Emmanuel Martínez es Coordinador en temas de Seguridad, Prevención y Capacitación en MAF y Asociados. Es especialista en la implementación de proyectos integrales, en temas de Seguridad Pública en los tres órdenes de gobierno, y en la implementación de proyectos de prevención del delito, participación ciudadana y profesionalización; así como capacitación para instituciones de seguridad. Es licenciado en Derecho con diplomados en Derecho Electoral, Derecho Parlamentario, y Presupuesto Basado en Resultados.'], },
    { index: 32, nombre: 'Diana Vanermi Bolaños Reyes', img_src: img_integrante_35, puesto: 'Especialista en Materia Penal', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_35, tipo_modal: 1, texto: [''], },
    {
        index: 19, nombre: 'Rubén Arcas Navarro', img_src: img_integrante_25, puesto: 'Director Comercial', mail: 'rarcas@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_25, tipo_modal: 2, texto: [<>
            Responsable del marketing y RP de la empresa. Cuenta con más de dos décadas de experiencia en Coaching, Marketing, Ventas y Proyectos. Laboró en empresas como General Electric, Banamex, Tv Azteca, Elektra, Kawasaki y otras. Desarrolló proyectos para compañías como Coca-Cola, Sony, P&G, Mondelez (Kraft), 7eleven, Johnson & Johnson, Diageo y PepsiCo por mencionar algunos. <br /><br />
            Además, tiene a su cargo el TrackSIM el cual es un servicio de capacitación con simuladores de manejo, para incrementar las habilidades de las y los operadores del autotransporte de carga. Cuenta con vasta experiencia en Simuladores de Manejo y ha impartido temas de Seguridad Vial a más de 1,400 integrantes de empresas como Cemex, Almex, Sigma Alimentos, Arca Continental, Estafeta, Grupo Modelo y otras. <br /><br />
            Es licenciado en administración de empresas por la UVM y maestro en dirección de empresas por el IPADE.<br /><br />
            <strong>A Rubén le encanta</strong>: El squash, leer, la música, cocinar y correr.
        </>],
    },
    {
        index: 4, nombre: 'Fabio Alberto Emmanuel Ocampo Vázquez', img_src: img_integrante_5, puesto: 'Gerente de Asuntos Empresariales', mail: 'focampo@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_5, tipo_modal: 2, texto: ['Fabio Ocampo es Coordinador de Asuntos Jurídicos y Legislativos en MAF y Asociados. Inició su carrera profesional en el ámbito notarial, posteriormente se incorporó al sector público en la Procuraduría General de la República, donde desempeñó distintas funciones en la Dirección General de Recursos Humanos y Organización. Asimismo, fungió como Asesor de la Secretaría Técnica del Consejo de Coordinación para la Implementación del Sistema de Justicia Penal. ',
            'Actualmente, es responsable del área jurídica y contenciosa de la consultoría, en las distintas ramas del derecho como son Constitucional, Familiar, Civil, Mercantil, Migratoria, Penal, Inmobiliaria, Compliance Empresarial y Penal, entre otras. Es licenciado en Derecho, egresado de la Universidad Nacional Autónoma de México, Facultad de Derecho. '],
    },
    {
        index: 23, nombre: 'Yesica Cynthia Viveros López', img_src: img_integrante_29, puesto: 'Gerente de Recursos Humanos y Materiales', mail: 'yviveros@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_29, tipo_modal: 2, texto: [<>
            Es responsable de la administración del área de Recursos Humanos, la atracción de talento, y la gestión del personal.  Encargada de recursos materiales, búsqueda de proveedores y administración de inmuebles.<br /><br />
            Estudio Administración de Empresas en la Universidad Panamericana y cuenta con la Maestría en Administración Pública por la UVM.<br /><br />
            <strong>A Yesica le encanta</strong>: El baile, asistir a conciertos, el cine y cocinar.</>],
    },
    /*{
        index: 11, nombre: 'Mario Antonio Ávila Varguez', img_src: img_integrante_15, puesto: 'Especialista en Amparos', mail: 'mavila@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_15, tipo_modal: 2, texto: [<>
            Es responsable de implementar las estrategias jurídicas en la conducción de los procesos en los que intervenimos como abogados patrones representando los intereses de nuestros clientes.<br /><br />
            Estudio Lic. En Derecho.<br /><br />
            <strong>A Mario le encanta</strong>: Leer Ir al cine, conocer lugares nuevos con tendencia hacia las playas yen ocasiones las reuniones con amigos.</>],
    },*/
    {
        index: 13, nombre: 'José Gabriel Uribe Acevedo', img_src: img_integrante_18, puesto: 'Gerente de Vinculación', mail: 'juribe@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_18, tipo_modal: 2, texto: ['Gabriel Uribe es Consultor Senior de Asuntos Sociales y Políticos en MAF y Asociados. Es planeador y diseñador de políticas públicas, en temas urbanos, presupuestales, economía urbana y municipalista, y especialista en temas electorales. En al ámbito profesional ha laborado en la Coordinación de Información, de Ecatepec de Morelos, Estado de México; en la Secretaría de Desarrollo Económico del Gobierno del Estado de Morelos, en la Secretaría General de Gobierno Toluca, del Estado de México, y como consultor externo.',
            'Es maestro en Planeación y Políticas Metropolitanas por la Universidad Autónoma Metropolitana (UAM), Plantel Azcapotzalco y licenciado en Ciencia Política por la UAM, Plantel Iztapalapa. Cuenta con el diplomado “Crecimiento Urbano, retos y soluciones”; un diplomado y certificación en Gerencia Pública Municipal en el Instituto de Administración Pública del Estado de México, A.C., y un diplomado en Regulación por la Comisión Federal de Mejora Regulatoria y Red Latinoamericana de Mejora Regulatoria y Competitividad. '],
    },
    { index: 35, nombre: 'Adriana María Barradas Bravo', img_src: img_integrante_34, puesto: 'Coordinadora de Comunicación Estratégica', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_34, tipo_modal: 1, texto: [''], },
    {
        index: 26, nombre: 'Jonás David Ocampo Vázquez', img_src: img_integrante_32, puesto: 'Coordinador de Asuntos Legislativos', mail: 'jocampo@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_32, tipo_modal: 2, texto: [<>
            Es responsable en el seguimiento de asuntos legislativos, empresariales y asesorías jurídicas en materia administrativa jurisdiccional procesal.<br /><br />
            Estudio Licenciatura en Derecho con especialidad en derecho administrativo, fiscal y civil.<br /><br />
            <strong>A Jonás le encanta:</strong>Leer, jugar video juegos en línea, ver películas y practicar deportes como tenis y frontenis.</>],
    },
    {
        index: 24, nombre: 'José Antonio Cabrera Román', img_src: img_integrante_30, puesto: 'Gerente de Finanzas', mail: 'acabrera@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_30, tipo_modal: 2, texto: [<>
            Es responsable <br /><br />
            Estudio Administración de Empresas en la Universidad Panamericana y cuenta con la Maestría en Administración Pública por la UVM.<br /><br />
            <strong>A Antonio le encanta</strong>: El baile, asistir a conciertos, el cine y cocinar.
        </>],
    },
    {
        index: 19, nombre: 'Rosa Samantha Morales González', img_src: img_integrante_24, puesto: 'Especialista en materia Civil, Familiar y Mercantil', mail: 'smorales@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_24, tipo_modal: 2, texto: [<>
            Es responsable implementar las estrategias jurídicas y dar seguimiento a los juicios en materia civil, familiar y mercantil. Además tiene a su cargo la elaboración de opiniones técnicas jurídicas.<br /><br />
            Estudio la Licenciatura en Derecho en la UNAM con especialidad en derecho civil.<br /><br />
            <strong>A Samantha le encanta</strong>: Hacer ejercicio, andar en motocicleta y la playa. </>],
    },
    { index: 8, nombre: 'Néstor Gustavo Ruíz Hernández', img_src: img_integrante_9, puesto: 'Especialista en Temas de Seguridad, Prevención y Capacitación', mail: 'nruiz@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_9, tipo_modal: 2, texto: ['Nestor Ruíz es Consultor Junior de Capacitación y Prevención en MAF y Asociados. Cuenta con experiencia en el sector público como especialista en evaluaciones de desempeño de programas federales, así como en la implementación y seguimiento de proyectos integrales en materia de prevención social del delito y participación ciudadana. Es licenciado en Economía con especialización en Desarrollo Regional, egresado del Instituto Politécnico Nacional por la Escuela Superior de Economía. '], },
    { index: 34, nombre: 'T. Rosalía Fernández San Agustín', img_src: img_integrante_43, puesto: 'Coordinadora de Asuntos Legislativos', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_43, tipo_modal: 1, texto: [''], },
    {
        index: 16, nombre: 'Carlos Elizarraráz Rodríguez', img_src: img_integrante_21, puesto: 'Consultor Administrativo', mail: 'celizarraraz@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_21, tipo_modal: 2, texto: [<>Es responsable del registro de los gastos y pagos a terceros, apoyo a la dirección general y a las demás áreas, pagos de servicios y gastos fijos.<br />
            Estudio la carrera de contaduría.<br /><br />
            <strong>A Carlos le encanta</strong>: Todos los deportes y visitar pueblos mágicos.</>],
    },
    {
        index: 21, nombre: 'Noemí Rodríguez Vega', img_src: img_integrante_27, puesto: 'Especialista en Vinculación', mail: 'nrodriguez@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_27, tipo_modal: 2, texto: [<>
            Responsable del manejo y monitoreo de información y datos relevantes del área, para el suministro constante de insumos de enfoque cuantitativo y cualitativo para la elaboración y gestión de proyectos en materia de responsabilidad social empresarial y relaciones gubernamentales.<br /><br />
            Es colaboradora en desarrollo de estrategias de política pública y documentos de análisis. También se encarga del área de género en el equipo de políticos y sociales. <br /><br />
            Mapeo de diversos stakeholders del ámbito empresarial, social y/o político. <br /><br />
            Es licenciada en Relaciones Internacionales por el Tecnológico de Monterrey Campus Puebla, cuenta con certificaciones de técnicas cuantitativas y cualitativas para la investigación, respuesta humanitaria a conflictos, catástrofes y derechos humanos.<br /><br />
            <strong>A Noemí le encanta</strong>: Viajar, cantar, convivir en familia e ir a la playa.</>],
    },
    { index: 15, nombre: 'Héctor Eduardo Vidal Díaz', img_src: img_integrante_20, puesto: 'Coordinador de Vinculación', mail: 'hvidal@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_20, tipo_modal: 2, texto: ['Héctor Vidal es Consultor Junior de Asuntos Sociales y Políticos en MAF y Asociados. Cuenta con habilidades en materia de planeación y organización en la iniciativa privada y el sector gubernamental. Desarrolla actividades de análisis para la solución de problemáticas sociales y asuntos públicos de orden nacional y local, así como trato con grupos y organizaciones públicas y privadas de distinta índole. Es licenciado en Ciencias Políticas y Administración por la FES Acatlán, UNAM.'], },
    { index: 35, nombre: 'Mario Alberto Agraz Martínez', img_src: img_integrante_40, puesto: [<>Operador <a href="https://tracksim.mx/" target="_blank">TrackSIM</a></>], mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_40, tipo_modal: 1, texto: [''], },
    //{ index: 36, nombre: 'Estefanía Monterrosas Sánchez', img_src: img_integrante_37, puesto: 'Pasante', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_37, tipo_modal: 1, texto: [''], },
    
    {
        index: 20, nombre: 'Luis Alexis Godínez Flores', img_src: img_integrante_26, puesto: 'Especialista en Vinculación', mail: 'agodinez@mafyasociados.com', redes_sociales: [], pop: true, img_circulo: img_circular_integrante_26, tipo_modal: 2, texto: [<>Responsable del monitoreo de información relevante y manejo de datos del área,  para el suministro constante de insumos de enfoque cuantitativo y cualitativo que permitan la elaboración y gestión de proyectos en materia de responsabilidad social empresarial y relaciones gubernamentales.
            Además efectúa mapeos diversos de stakeholders del ámbito empresarial, social y/o político. Manejo de la vinculación transversal con el equipo de cabildeo legislativo.<br /><br />
            Es licenciado en ciencias políticas por la UNAM, cuenta con diplomados en Derecho y Urbanismo, Análisis Políticos y Campañas Electorales, Evaluación de Políticas y Programas Públicos y un último en Presupuesto basado en resultados.<br /><br />
            <strong>A Alexis le encanta</strong>: La música, la lectura, los museos y la buena comida.</>],
    },
    { index: 37, nombre: 'Paola Lizbeth Ángeles Pacheco', img_src: img_integrante_41, puesto: 'Pasante', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_41, tipo_modal: 1, texto: [''], },
    { index: 38, nombre: 'Luis Martín Chavarría Cortes', img_src: img_integrante_39, puesto: 'Coordinador de Asuntos Legislativos', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_39, tipo_modal: 1, texto: [''], },
    { index: 39, nombre: 'Alexandra Garduño Ávila', img_src: img_integrante_48, puesto: 'Enlace de dirección', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_48, tipo_modal: 1, texto: [''], },
    { index: 40, nombre: 'Gued Mauricio Fuentes Trujillo', img_src: img_integrante_45, puesto: 'Especialista en Fiscal y Procedimientos Administrativos', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_45, tipo_modal: 1, texto: [''], },
    { index: 41, nombre: 'Cecilia Martínez Sandoval', img_src: img_integrante_44, puesto: 'Consultora Administrativo', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_44, tipo_modal: 1, texto: [''], },
    //{ index: 42, nombre: 'Jaime Alberto Cantú González', img_src: img_integrante_38, puesto: '', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_38, tipo_modal: 1, texto: [''], },
    { index: 43, nombre: 'Nicolas Martínez García', img_src: img_integrante_54, puesto: 'Administración', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_54, tipo_modal: 1, texto: [''], },


    //{ index: 44, nombre: 'Alexandra Garduño Avila', img_src: img_integrante_2, puesto: 'Enlace de dirección', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_2, tipo_modal: 1, texto: [''], },
    { index: 45, nombre: 'Diana Paola Espinosa Fuentes', img_src: img_integrante_51, puesto: 'Pasante', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_51, tipo_modal: 1, texto: [''], },
    { index: 46, nombre: 'Carlos Javier Sanchez Mancilla', img_src: img_integrante_49, puesto: 'Especialista en Materia Penal', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_49, tipo_modal: 1, texto: [''], },
    { index: 47, nombre: 'Jean Paul Becerril Celis', img_src: img_integrante_52, puesto: 'Coordinador de Asuntos Empresariales', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_52, tipo_modal: 1, texto: [''], },
    { index: 48, nombre: 'Dariana González Galicia', img_src: img_integrante_50, puesto: 'Pasante', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_50, tipo_modal: 1, texto: [''], },
    //{ index: 49, nombre: 'Jeniffer Garcia Martinez', img_src: img_integrante_53, puesto: 'Especialista en Vinculación', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_53, tipo_modal: 1, texto: [''], },
//{ index: 34, nombre: '', img_src: img_integrante_2, puesto: '', mail: '', redes_sociales: [], pop: false, img_circulo: img_circular_integrante_2, tipo_modal: 1, texto: [''], },
    
    
    
    
    
    
    
    
    
    
    
    
];



export const CarouselEquipo = () => {

    const [ver_popup, setVerPopup] = useState(false);
    const [tipo_popup, setTipoPopup] = useState(0);
    const [ver_persona, setVerPersona] = useState(0);
    const [iniciar_modal, setIniciarModal] = useState(true);

    console.log('tipo pop', tipo_popup);
    const ref_carousel = React.createRef();

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (

            <CaretRightFilled className={className} style={{ ...style, display: "block", color: '#fc5d17', fontSize: '34px' }} onClick={onClick} />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <CaretLeftFilled className={className} style={{ ...style, display: "block", color: '#fc5d17', fontSize: '34px' }} onClick={onClick} />
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        draggable: false,
    };

    console.log(ver_popup);

    useEffect(() => {
        console.log(ref_carousel);
        console.log(ver_popup);

        if (!ver_popup && iniciar_modal) {
            setIniciarModal(false);
        }

        if (ref_carousel.current) {

            ref_carousel.current.slickGoTo(ver_persona);
        }
        setTipoPopup(tipo_popup);
    }, [ref_carousel, tipo_popup])



    let ver_integrantes = integrantes.map((integrante, index) => {

        let links_redes_sociales = integrante.redes_sociales.map(red_social => {
            return (
                <a href={red_social.url} target="_blank">
                    <img src={red_social.url_icono} className="img_integrante_red_social" alt={red_social.nombre} />

                </a>
            );
        });

        return (
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 0 }} lg={{ span: 6, offset: 0 }}>
                <Row>
                    {integrante.pop === true ?
                        <Col span={22} offset={1} className="hover14" style={{ cursor: 'pointer' }} onClick={() => { setVerPopup(integrante.index); setTipoPopup(integrante.tipo_modal); setVerPersona(index) }}>
                            <figure><img src={integrante.img_src} className="img_foto_integrante" alt={integrante.nombre} /></figure>
                        </Col>
                        :
                        <Col span={22} offset={1}>
                            <img src={integrante.img_src} className="img_foto_integrante" alt={integrante.nombre} />
                        </Col>
                    }
                    <Col span={22} offset={1} className="contenedor_integrante">
                        <Row align="bottom">
                            <Col span={24} offset={0}>
                                <p className="nombre_integrante">{integrante.nombre}</p>
                                <p className="puesto_integrante">{integrante.puesto}</p>
                            </Col>
                            <Col span={0} offset={0}>
                                <Divider className="division_texto_integrante" />
                            </Col>
                            <Col span={0} offset={0} style={{ textAlign: 'left', marginTop: '0px', paddingTop: '0px' }}>
                                {integrante.redes_sociales != '' ?
                                    links_redes_sociales
                                    :
                                    <></>}
                            </Col>
                            <Col span={0} style={{ textAlign: 'right' }}>
                                <a href={`mailto:${integrante.mail}`} target="_blank">
                                    <span className="icono_mail_integrante">e-mail <RightOutlined /></span>
                                </a>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    });

    let filtro_modal = integrantes.filter((objeto, index) => {
        if (ver_popup === objeto.index) {
            return true;
        }
        return false;
    });
    let mostrar_info = integrantes.map((objeto) => {
        let links_redes_sociales = objeto.redes_sociales.map(red_social => {
            return (
                <Col span={8} style={{ textAlign: 'center' }}>
                    <a href={red_social.url} target="_blank">
                        <img src={red_social.url_icono} className="img_integrante_red_social" alt={red_social.nombre} />
                    </a>
                </Col>
            );
        });
        return (
            <>
                {objeto.tipo_modal === 2 ?
                    <Row style={{ padding: '3%' }}>
                        <Col xs={24} md={23}>
                            <Row>
                                <Col xs={{ span: 21, offset: 1 }} md={{ span: 10, offset: 1 }}>
                                    <img src={objeto.img_circulo} width="100%" alt={objeto.nombre} />

                                    <p className="nombre_modal_nosotros">{objeto.nombre}</p>
                                    <p className="puesto_modal_nosotros">{objeto.puesto}</p>
                                    <p style={{ textAlign: 'center' }}>
                                        <a href={`mailto:${objeto.mail}`} target="_blank" className="boton_contactanos_nosotros">
                                            <span >Contáctanos</span>
                                        </a>
                                    </p>
                                    <Row justify='center' style={{ textAlign: 'center' }}>
                                        {links_redes_sociales}</Row>
                                </Col>
                                <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 1 }}>
                                    <p className="descripcion_modal_nosotros">
                                        {objeto.texto.map((parrafo, index) => {
                                            if (index === 0) {
                                                return (
                                                    <>{parrafo}</>
                                                );
                                            } else {
                                                return (
                                                    <><br /><br />{parrafo}</>
                                                );
                                            }
                                        })}
                                    </p>
                                </Col>
                                <Col span={0}>
                                    <p className="close_modal_nosotros" onClick={() => setVerPopup(false)}>X</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={0} md={0}>
                            <Row align="bottom">
                                <Col xs={{ span: 20, offset: 2 }} md={{ span: 11, offset: 1 }}>
                                </Col>
                                <Col xs={{ span: 21, offset: 1 }} md={{ span: 6, offset: 4 }} >
                                    <Row justify="center">
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <a href={`mailto:${objeto.mail}`} target="_blank">
                                                <span className="icono_mail_integrante"><MailFilled /></span>
                                            </a>
                                        </Col>
                                        {links_redes_sociales}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    :
                    <Row style={{ padding: '3%' }}>
                        <Col span={24}>
                            <Row justify="center" align="middle">
                                <Col xs={{ span: 21, offset: 1 }} md={{ span: 10, offset: 1 }}>
                                    <img src={objeto.img_circulo} width="100%" alt={objeto.nombre} />
                                </Col>
                                <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 1 }}>
                                    <p className="nombre_modal_nosotros">{objeto.nombre}</p>
                                    <p className="puesto_modal_nosotros">{objeto.puesto}</p>

                                    <Row justify="center">
                                        <Col span={8} style={{ textAlign: 'center' }}>
                                            <a href={`mailto:${objeto.mail}`} target="_blank">
                                                <span className="icono_mail_integrante"><MailFilled /></span>
                                            </a>
                                        </Col>
                                        {links_redes_sociales}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={23}>
                            <Row align="bottom">
                                <Col xs={{ span: 20, offset: 2 }} md={{ span: 11, offset: 1 }}>
                                </Col>

                            </Row>
                        </Col>

                    </Row>}
            </>
        )
    });


    return (
        <Row justify="center">
            <Col xs={{ span: 16, offset: 0 }} md={{ span: 20, offset: 0 }}>
                <Row>
                    {ver_integrantes}
                </Row>
            </Col>
            <Modal
                style={{ backgroundColor: '#ffffff', top: '5%', padding: '0px', zIndex: '3' }}
                title={null}
                footer={null}
                closable={true}
                visible={iniciar_modal || ver_popup}
                //onCancel={() => { onResponse(''); }}
                onCancel={() => { setVerPopup(false); }}
                bodyStyle={{ backgroundColor: '#ffffff', margin: '0px', padding: '0px 40px 0px 30px', width: '100%', zIndex: '3' }}
                width="750px"
                className="pop_semblanza"
                forceRender={true}
            >
                <Slider ref={ref_carousel} {...settings} className="slider_nosotros">
                    {mostrar_info}
                </Slider>
            </Modal>

        </Row>
    );

};