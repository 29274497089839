import axios from 'axios';
const SERVER_URL = "https://API.REGISTRO.MAFYASOCIADOS.COM";
//const SERVER_URL = "http://localhost:8000";

const fallo_token = {
    success: false,
    data:{
        status: 5
    }
}

const omitir_token = {
    success: true,
    data:{
        status: 10
    }
}

export const mail_contacto = async (data) => {
    const ENDPOINT = `${SERVER_URL}/api/mail-contacto`;
    try {
        let response = await axios.post(ENDPOINT, data,{headers: {
            'Content-Type': 'application/json',
        }
});
        if(response.data.success){
            return response.data;
        }else{
            return fallo_token;
        }
        
    } catch(e){
        console.log(e);
        return fallo_token.data;
    }
}
