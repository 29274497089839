import React from 'react';
import { Row, Col } from 'antd';
import Helmet from "react-helmet";

import img_src_header from '../../../assets/images/paginas/resultados_sobre_pobreza/pobrezaygenero-780.jpg';
import img_1 from '../../../assets/images/paginas/resultados_sobre_pobreza/resultados_sobre_pobreza_1.png';
import img_2 from '../../../assets/images/paginas/resultados_sobre_pobreza/resultados_sobre_pobreza_2.png';
import img_3 from '../../../assets/images/paginas/resultados_sobre_pobreza/resultados_sobre_pobreza_3.png';
import img_4 from '../../../assets/images/paginas/resultados_sobre_pobreza/resultados_sobre_pobreza_4.png';
import img_5 from '../../../assets/images/paginas/resultados_sobre_pobreza/resultados_sobre_pobreza_5.png';
import img_6 from '../../../assets/images/paginas/resultados_sobre_pobreza/resultados_sobre_pobreza_6.png';
import img_7 from '../../../assets/images/paginas/resultados_sobre_pobreza/resultados_sobre_pobreza_7.png';

export const ResultadosSobrePobreza = () => {
    return(
        <>
        <Helmet>
        <meta name="description" content=" Recientemente el Consejo Nacional de Evaluación de la Política de Desarrollo Social (CONEVAL)," />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.mafyasociados.com/resultados-sobre-pobreza-y-genero-2021-del-coneval" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Evelyn Esquivel. " />
        <meta name="author" content="Evelyn Esquivel. " />
        <meta property="og:title" content="RESULTADOS SOBRE POBREZA Y GÉNERO 2021, DEL CONEVAL "/>
        <meta property="og:description" content=" Recientemente el Consejo Nacional de Evaluación de la Política de Desarrollo Social (CONEVAL)," />
        <meta property="og:url" content="https://www.mafyasociados.com/resultados-sobre-pobreza-y-genero-2021-del-coneval" />
        <meta property="og:site_name" content="MAF & Asociados" />
        <meta property="og:image" content="https://mafyasociados.com/archivos/vistas/pobrezaygenero-780.png" />
        <meta property="og:image:secure_url" content="https://mafyasociados.com/archivos/vistas/pobrezaygenero-780.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content=" Recientemente el Consejo Nacional de Evaluación de la Política de Desarrollo Social (CONEVAL)," />
        <meta name="twitter:title" content="RESULTADOS SOBRE POBREZA Y GÉNERO 2021, DEL CONEVAL " />
        <meta name="twitter:image" content="https://mafyasociados.com/archivos/vistas/pobrezaygenero-780.png" />
      </Helmet>
        <Row >
            <Col span={24}>
                <img alt="Articulo Resultado sobre pobreza" src={img_src_header} style={{width:'100%'}} />
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                
    <br />
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    Por Evelyn Esquivel
    <br /><br />
    Recientemente el Consejo Nacional de Evaluación de la Política de Desarrollo Social (CONEVAL), publicó los resultados de la actualización bienal del Sistema de indicadores sobre pobreza y género. Desde el año 2012, el CONEVAL construyó un sistema que comprende un total de 30 indicadores, englobando ámbitos de la vida social como los derechos de salud, trabajo remunerado y no remunerado, seguridad social, educación, vivienda, ingresos, características de los hogares, entre otros. A través de ellos, se busca visibilizar las condiciones que experimentan tanto mujeres como hombres. De igual manera, hace hincapié sobre la condición socioeconómica, dividiendo los resultados entre las personas que están inmersas dentro de la pobreza y aquellas que no lo están.
    <br /><br />
    Dicho informe tiene como propósito dar cuenta de las desigualdades existentes entre mujeres y hombres, así como la manera en la que ello afecta directamente al índice de pobreza, aumentando las posibilidades de formar parte de este estrato socioeconómico.
    <br /><br />
    En este sentido, presentamos algunas de las cifras más importantes reflejadas por tema, respecto del informe en comento.
    </p>
    <h4 className="color_gris_claro texto_tamano" ><strong>Trabajo doméstico</strong></h4>
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    El trabajo doméstico, entendido como aquel dedicado al hogar sin remuneración económica y que versa sobre actividades como el cuidado del hogar o las labores de limpieza, es uno de los factores más importantes que propician la desigualdad de oportunidades entre mujeres y hombres. La causa que marca la desigualdad, principalmente, radica en el número de horas que las mujeres disponen para dichas actividades, en contraste con las horas que los hombres ocupan para la misma tarea. En razón de lo anterior se destaca que en 2018 las mujeres destinaron entre 1.2 veces —el doble de horas con respecto de los hombres— solamente hablando de las labores de cuidado de otras personas. Asimismo, las mujeres destinaron entre 2.2 horas —3.2 veces más que los hombres—a los quehaceres domésticos, situación que se agrava en contextos de pobreza.
    <br /><br />
    Durante el periodo de estudio, comprendido entre 2008 y 2018, se observó un retroceso por  cuanto hace al trabajo doméstico, en virtud de que las mujeres presentaron mayores incrementos respecto de los que experimentaron los hombres, situación que se refleja en las siguientes gráficas:
        </p>
        <p style={{textAlign:'center'}}>
        <img alt="Articulo Resultado sobre pobreza" src={img_1} className="img_articulos" />
        <br />
        <img alt="Articulo Resultado sobre pobreza" src={img_2} className="img_articulos" />
        </p>
        </Col>
        <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
        <h4 className="color_gris_claro texto_tamano" ><strong>Trabajo extradoméstico</strong></h4>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Por cuanto hace al trabajo extradoméstico, o mejor conocido como «remunerado», las mujeres continúan enfrentando condiciones de desigualdad, puesto que se toman en cuenta aspectos como la participación económica, el nivel del salario, trabajo sin remuneración, porcentaje de trabajo, seguridad social y la segregación de los empleos referidos a la masculinización o feminización de las actividades laborales. Este apartado no presenta mejores condiciones para las mujeres, dado que las estadísticas muestran que, en todos los aspectos mencionados anteriormente, las mujeres se encuentran en gran desventaja, la cual se evidencia de la siguiente manera:
        </p>
        </Col>
        <Col xs={{span:0, offset:0}} md={{span:10, offset:2}}>
        <p style={{textAlign:'center'}}>
        <img alt="Articulo Resultado sobre pobreza" src={img_3} className="img_articulos_100" />
        </p>
        </Col>
        <Col xs={{span:20, offset:2}} md={{span:10, offset:0}}>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        La participación económica hace referencia al porcentaje de la población que participa en la producción de bienes o servicios y el crecimiento de esta, la cual depende directamente de diversos factores como el aumento en la escolarización de las mujeres, la disminución de la fecundidad, los cambios de roles públicos de las mujeres e incluso, en la estrategia de sobrevivencia como respuesta a eventos económicos adversos como lo sería una crisis económica.
        </p>
        </Col>
        <Col xs={{span:20, offset:2}} md={{span:0, offset:0}}>
        <p style={{textAlign:'center'}}>
        <img alt="Articulo Resultado sobre pobreza" src={img_3} className="img_articulos_100" />
        </p>
        </Col>

        <Col xs={{span:20, offset:2}} md={{span:10, offset:2}}>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        En esta grafica se aprecia el reflejo de una de las brechas de género más remarcables en la sociedad, la brecha salarial, demostrando que las mujeres perciben menos ingresos por una hora de trabajo en comparación con los hombres. Por cuanto hace al trabajo sin remuneración, que ocurre sobre todo en entornos familiares y se considera como una extensión del trabajo doméstico, se denota que en ocasiones las mujeres que trabajan sin remuneración producen para el autoconsumo, contribuyendo altamente en el bienestar de sus familias, tal como se muestra a continuación.
        </p>
        </Col>
        <Col xs={{span:20, offset:2}} md={{span:10, offset:0}}>
        <p style={{textAlign:'center'}}>
        <img alt="Articulo Resultado sobre pobreza" src={img_4} className="img_articulos_100" />
        </p>
        </Col>

        <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
        <p style={{textAlign:'center'}}>
        <img alt="Articulo Resultado sobre pobreza" src={img_5} className="img_articulos" />
        </p>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Con respecto a las jornadas laborales remuneradas, las cuales son de tiempo parcial, se evidencia que éstas son más frecuentes entre las mujeres que entre los hombres, en virtud de que a menudo se ven forzadas a emplearse en una jornada que les permita combinar la seguridad económica con la atención a la familia, es decir, con la carga de trabajo sin remuneración.
        <br /><br />
        La segregación laboral atiende a la masculinización o feminización de los empleos, es decir, la mayoría de los hombres se emplean en ocupaciones con salarios mayores, mientras que las mujeres se concentran en empleos con menores remuneraciones. De esta manera, un mayor porcentaje de hombres se emplea en trabajos donde prevalece la masculinización a comparación de las mujeres que participan en empleos feminizados, como se muestra a continuación.
        <br /><br />
        De acuerdo a las gráficas anteriores, se puede visualizar que dentro del campo del trabajo remunerado, cuestiones como el empleo sin firma de contrato, sin percepción económica, segregación laboral o falta de acceso a seguridad social, son algunas de las mayores diferencias entre los factores que causan desigualdad entre mujeres y hombres, logrando que derechos básicos de una relación laboral remunerada, como lo sería la seguridad social, imposibiliten la futura obtención de una pensión contributiva.
          </p>
          <p style={{textAlign:'center'}}>
        <img alt="Articulo Resultado sobre pobreza" src={img_6} className="img_articulos" />
        </p>
        <h4 className="color_gris_claro texto_tamano" ><strong>Ingreso</strong></h4>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        El ingreso, es decir, la percepción económica obtenida por una actividad laboral, tiene una amplia relación con los aspectos de desigualdad que hemos analizado previamente, pues observamos que las mujeres experimentan mayores cargas de trabajo doméstico que reduce el tiempo de jornada de un trabajo con remuneración económica, aunado a que enfrentan desigualdades como la brecha salarial y la feminización de los empleos que ocasiona que las mujeres accedan mayormente a campos laborales donde predomina el sexo femenino y que, como regla general, perciben salarios menores a diferencia de aquellos empleos donde predominan los hombres con mayores salarios.
        </p>
        <h4 className="color_gris_claro texto_tamano" ><strong>Salud</strong></h4>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        El informe realizado por CONEVAL, sorpresivamente refleja que las mujeres tienen mayor acceso a servicios médicos  en comparación a los hombres, sin embargo, también es puntual en señalar que esto se debe a que las mujeres acceden a servicios de salud en razón de la dependencia que reflejan con sus redes de parentesco para ser afiliadas a un servicio médico, es entonces evidente que, quienes tienen mayor acceso a servicios de salud son los varones.
        <br /><br />
        Por su parte, atendiendo al nivel socioeconómico, se estima que, en un nivel de ausencia de pobreza, las mujeres poseen el 30% de la titularidad en cuanto a la afiliación a servicios de salud, mientras que los hombres tienen una titularidad del 50%. 
        <br /><br />
        Por cuanto hace a la condición de pobreza, se estimó que las mujeres tuvieron acceso directo como titulares de servicios médicos en un 5% en contraposición del 15% de sus homólogos varones, tal y como se observa en las gráficas siguientes:
        </p>
        <p style={{textAlign:'center'}}>
        <img alt="Articulo Resultado sobre pobreza" src={img_7} className="img_articulos" />
        </p>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Como se observa, el informe desarrollado por el CONEVAL muestra la necesidad y la urgencia de seguir creando políticas públicas que dentro de todos los ámbitos de la sociedad abonen a eliminar las brechas de desigualdad, poniendo especial atención en el ámbito laboral. Es necesario contar con un compromiso real de parte de las instituciones públicas y privadas para otorgar mejores condiciones a las mujeres con la finalidad de disminuir, y en su momento eliminar, la desigualdad de oportunidades entre mujeres y hombres, evitando que esas brechas de oportunidades, conlleven a entrar a una condición de pobreza.
        <br /><br />
        Te invito a que conozcas el informe completo en la siguiente liga: <br />
        <a href="https://www.coneval.org.mx/InformesPublicaciones/InformesPublicaciones/Documents/Pobreza_genero_08-18.pdf" target="_blank" className="link_sin_decoracion">https://www.coneval.org.mx/InformesPublicaciones/InformesPublicaciones/Documents/Pobreza_genero_08-18.pdf</a>
        </p>
        <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify", display:'none' }}
          >
              <hr style={{ marginLeft: '20px', marginRight: '20px' }} />
              <br /><br />
              <small>
                  <sup><strong>1</strong></sup><a href="https://centralelectoral.ine.mx/2020/12/31/recibe-ine-lista-de-entidades-donde-los-partidos-politicos-nacionales-pretenden-postular-a-candidatas-a-la-gubernatura/" target="_blank" className="link_sin_decoracion"> Lista de Entidades donde los Partidos Políticos Nacionales pretenden postular a candidatas a la gubernatura. Instituto Nacional Electoral. </a>
                  <br />
                  <sup><strong>2</strong></sup><a href="https://dof.gob.mx/nota_detalle.php?codigo=5591565&fecha=13/04/2020" target="_blank" className="link_sin_decoracion"> Ley General de Acceso de las Mujeres a Una Vida Libre de Violencia. Diario Oficial de la Federación, Abril de 2020.</a>
                  <br />
                  <sup><strong>3</strong></sup> Protocolo para la atención de la violencia política contra las mujeres en razón de género. Secretaría de Gobernación, et. al., pp. 56.<a href="" target="_blank" className="link_sin_decoracion"></a>
                  <br />
                  <sup><strong>4</strong></sup><a href="https://www.cndh.org.mx/sites/all/doc/OtrosDocumentos/Doc_2018_056.pdf" target="_blank" className="link_sin_decoracion"> Violencia política contra las mujeres en razón de género. Comisión Nacional de los Derechos Humanos. </a>
                  <br />
              </small>
          </p>
            </Col>
           
        </Row>
        </>
    );
}