import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography } from 'antd';
import Slider from "react-slick";

import src_img_conoce_mas from '../../../assets/images/sitio/home/00Boton_CONOCEMAS.png';
import src_img_logo_sol from '../../../assets/images/sitio/home/Logo-EL-SOL-DE-MEXICO.png';
import src_pdf_recomendaciones_transportistas from '../../../assets/files/recomendaciones_transportistas/maf_recomendaciones_transportistas.pdf';

import img_banner_1 from '../../../assets/images/sitio/home/banners/Maf_banners-transporte.jpg';
import img_banner_2 from '../../../assets/images/sitio/home/banners/Maf_banners-compliance.jpg';
import img_banner_3 from '../../../assets/images/sitio/home/banners/Maf_banners-relacionamiento.jpg';
import img_banner_4 from '../../../assets/images/sitio/home/banners/Maf_banners-civilpenal.jpg';
import img_banner_5 from '../../../assets/images/sitio/home/banners/Maf_banners-familiar-.jpg';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';

const { Paragraph } = Typography;


export const CarouselBanners = () => {

    const ref_carousel = React.createRef();
    
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (

            <CaretRightFilled className={className} style={{ ...style, display: "block", color: '#fc5d17', fontSize: '34px', zIndex:'10', right:'15px' }} onClick={onClick} />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <CaretLeftFilled className={className} style={{ ...style, display: "block", color: '#fc5d17', fontSize: '34px', zIndex:'10', left:'10px'  }} onClick={onClick} />
        );
    }


    var settings = {
        pauseOnHover: true,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: 'caorusel_home'
    };
    

    return (
        <div>
            <Slider {...settings}>
                {/*<div>
                    <Row align="middle" className="banner contenedor_banner_articulo">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 2 }}
                            style={{ textAlign: 'left', paddingTop:'280px' }}>
                            
                            <a className='boton_contactanos_banners' href="mailto:contacto@mafyasociados.com"  target='_blank'>
                                CONTÁCTANOS
                            </a>
                        </Col>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}
                            style={{ textAlign: 'center', paddingTop:'280px' }} className="banner">
                                
                                <p><a className='boton_contactanos_banners' href="mailto:contacto@mafyasociados.com"  target='_blank'>
                                CONTÁCTANOS
                            </a>
                            </p>
                            
                        </Col>
                    </Row>
    </div>*/}
                <div>
                    <a className='' href="mailto:contacto@mafyasociados.com" target='_blank'>
                        <Row align="middle" className="banner ">
                            <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}
                                style={{ textAlign: 'left' }}>
                                    <img src={img_banner_1} style={{width:'100%'}} />
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 0, offset: 0 }}
                                style={{ textAlign: 'center', paddingTop: '280px' }} className="banner contenedor_banner_articulo">

                                <p><a className='boton_contactanos_banners' href="mailto:contacto@mafyasociados.com" target='_blank'>
                                    CONTÁCTANOS
                                </a>
                                </p>

                            </Col>
                        </Row>
                    </a>
                </div>
                <div>
                    <a className='' href="mailto:contacto@mafyasociados.com" target='_blank'>
                        <Row align="middle" className="banner ">
                            <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}
                                style={{ textAlign: 'left'}}>
                                    <img src={img_banner_2} style={{width:'100%'}} />
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 0, offset: 0 }}
                                style={{ textAlign: 'center', paddingTop: '280px' }} className="banner contenedor_banner_articulo2">

                                <p><a className='boton_contactanos_banners' href="mailto:contacto@mafyasociados.com" target='_blank'>
                                    CONTÁCTANOS
                                </a>
                                </p>

                            </Col>
                        </Row>
                    </a>
                </div>
                <div>
                    <a className='' href="mailto:contacto@mafyasociados.com" target='_blank'>
                        <Row align="middle" className="banner ">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}
                                style={{ textAlign: 'left' }}>
                                    <img src={img_banner_3} style={{width:'100%'}} />
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 0, offset: 0 }}
                                style={{ textAlign: 'center', paddingTop: '280px' }} className="banner contenedor_banner_articulo3">

                                <p><a className='boton_contactanos_banners' href="mailto:contacto@mafyasociados.com" target='_blank'>
                                    CONTÁCTANOS
                                </a>
                                </p>

                            </Col>
                        </Row>
                    </a>
                </div>
                <div>
                    <a className='' href="mailto:contacto@mafyasociados.com" target='_blank'>
                        <Row align="middle" className="banner">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}
                                style={{ textAlign: 'left' }}>
                                    <img src={img_banner_4} style={{width:'100%'}} />
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 0, offset: 0 }}
                                style={{ textAlign: 'center', paddingTop: '280px' }} className="banner contenedor_banner_articulo4">

                                <p><a className='boton_contactanos_banners' href="mailto:contacto@mafyasociados.com" target='_blank'>
                                    CONTÁCTANOS
                                </a>
                                </p>

                            </Col>
                        </Row>
                    </a>
                </div>
                <div>
                    <a className='' href="mailto:contacto@mafyasociados.com" target='_blank'>
                        <Row align="middle" className="banner">
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}
                                style={{ textAlign: 'left' }}>
                                    <img src={img_banner_5} style={{width:'100%'}} />
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 0, offset: 0 }}
                                style={{ textAlign: 'center', paddingTop: '280px' }} className="banner contenedor_banner_articulo5">

                                <p><a className='boton_contactanos_banners' href="mailto:contacto@mafyasociados.com" target='_blank'>
                                    CONTÁCTANOS
                                </a>
                                </p>

                            </Col>
                        </Row>
                    </a>
                </div>

            </Slider>
        </div>
    );

}