import React from 'react';
import { Row, Col } from 'antd';

export const PoliticasLeyesAplicables = () => {
    return(
        <Row >
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                <br />
                <p className="color_naranja titulo_tamano" style={{textAlign:'center'}}>
                POLÍTICAS PARA ASEGURAR EL CUMPLIMIENTO DE LEYES APLICABLES
                </p>
                <p className="color_gris_claro texto_tamano"  style={{textAlign:'justify'}}>
                <strong>1. INTRODUCCIÓN</strong>
                <br />

MAFR Consultoría Estratégica S.C., desde su creación, existe una estricta política de legalidad, misma que queda de manifiesto en el presente documento y que reitera nuestro compromiso de conducir la totalidad de las operaciones económicas, administrativas, fiscales, laborales y en general todos los asuntos, en cumplimiento con todas las leyes, reglamentos, decretos y demás disposiciones legales aplicables de México y de aquellos países donde se realicen nuestras actividades.

Las presentes Políticas buscan orientar la actuación de nuestros empleados, asociados, directivos, terceros y demás personal, en la mayoría de las actividades laborales que desarrollen diariamente; sin embargo, ante situaciones no especificadas en el presente documento, Código de Conducta, Lineamientos para asegurar el cumplimiento de Leyes Anticorrupción o cualquier otro documento institucional, el personal podrá solicitar apoyo a sus superiores jerárquicos por cualquier medio disponible.

Las Políticas para asegurar el cumplimiento de Leyes Anticorrupción de MAFR Consultoría Estratégica S.C., resultan aplicables para todos los empleados, asociados, directivos, terceros y demás personal de la Consultora a nivel nacional e internacional, mismas que fueron previamente adoptadas por la Junta de Socios, por lo que la contravención a lo anterior, será objeto de acciones disciplinarias, las cuales incluyen la terminación del empleo, así como las sanciones legales correspondientes.

<br /><br />
<strong>2. POLÍTICA SOBRE CUMPLIMIENTO</strong>
<br />
MAFR Consultoría Estratégica S.C., reitera el compromiso de desarrollar la totalidad de sus actividades, sin importar el ámbito al que pertenezcan, en cumplimiento con lo establecido en la Constitución Política de los Estados Unidos Mexicanos, los Tratados Internacionales, las leyes federales y locales, y demás disposiciones legales, así como lo estipulado en las presentes Políticas, Código de Conducta, los Lineamientos para asegurar el cumplimiento de Leyes Anticorrupción y las demás disposiciones de carácter interno.

Los Socios, asume la responsabilidad y reconoce como un deber el que todos los empleados, socios, directivos, terceros y demás personal, conozcan y se mantengan enterados de todas las leyes, reglamentos y demás disposiciones jurídicas aplicables a sus actividades laborales; en consecuencia, deberán desarrollar sus actividades de conformidad con lo establecido en las disposiciones jurídicas mencionadas.

El objeto de esta Política de Cumplimiento, es que la Consultora pueda alcanzar el cumplimiento de sus actividades con un balance entre costo-beneficio que prevenga la contravención a las leyes, reglamentos, decretos y demás disposiciones jurídicas aplicables.

Asimismo, la presente Política de Cumplimiento está diseñada para coadyuvar a la Junta de Socios en la prevención de conflictos legales y asegurar la prestación de servicios de excelencia.

Para el cumplimiento de las obligaciones anteriormente señaladas, la Junta de Socios se compromete a administrar un Acervo de las Leyes, reglamentos, ordenamientos y demás disposiciones jurídicas, que resulten aplicables en el desarrollo de sus actividades, dicho compilado deberá ser actualizado de manera periódica para su eventual consulta por los sujetos obligados; ello con independencia de que la Junta de Socios, deba supervisar el cumplimiento de la normatividad de manera objetiva e independiente.
<br /><br />
<strong>3. POLÍTICA SOBRE COMPETENCIA ECONÓMICA</strong>
<br />
En MAFR Consultoría Estratégica S.C., nos comprometemos a promover el respeto y diligencia, así como el beneficio reciproco con nuestros clientes, sin buscar una ventaja indebida o un beneficio desproporcionado, todo enmarcado en un clima de justicia.

Por lo anterior, nos comprometemos a cumplir las leyes relativas a la competencia económica, así como las leyes antimonopolios de nuestro país y de cada país en que se realicen actividades comerciales; para ello es necesario, que todos nuestros empleados, asociados, directivos, terceros y demás personal, conozcan dichas leyes, mismas que se encontraran disponibles en el Acervo Legislativo de la Consultora, en caso de no encontrarse algún instrumento normativo, se hará del conocimiento de la Junta de Socios o de la Coordinación Jurídica.

De conformidad con lo establecido en la Ley Federal de Competencia Económica, queda estrictamente prohibida la celebración de contratos, convenios, arreglos o combinaciones con otros competidores, para manipular los precios, entorpecer la producción, dividir el mercado y establecer condiciones de licitación que afecten la prestación de servicios.

La contravención de lo anterior, puede ocasionar la imposición de sanciones disciplinarias y las consecuencias legales, tanto para quien comete la falta, como para la Consultora, en caso de que se compruebe que ésta última no actuó con la debida diligencia.
<br /><br />
<strong>4. POLÍTICA PARA LA REALIZACIÓN DE NEGOCIOS CON EL GOBIERNO</strong>
<br />
Debido a la naturaleza de las relaciones comerciales con los gobiernos municipales, estatales, federal, así como de otros países, MAFR Consultoría Estratégica y sus empleados, asociados, directivos, terceros y demás personal, en el desarrollo de sus funciones, deberán abstenerse de realizar cualquier tipo de conducta que pueda ser considerada como acto de corrupción.

Para evitar la comisión de actos de corrupción, el personal de MAFR Consultoría Estratégica deberá seguir lo establecido en la Ley General de Responsabilidades Administrativas, así como en los Lineamientos para asegurar el cumplimiento de Leyes Anticorrupción.

Asimismo, todos los empleados, asociados, directivos, terceros y demás personal de la Consultora están obligados a rendir los informes necesarios para la comprobación de sus funciones, siempre que les sean solicitados, mismos que deberán ser entregados, con total precisión y exactitud sobre la materia en que sean solicitados, esto en consonancia con lo establecido en los Lineamientos para asegurar el cumplimiento de Leyes Anticorrupción.

La contravención a lo anterior, puede ocasionar la imposición de sanciones disciplinarias, así como las consecuencias legales tanto para quien comete la falta como para la Consultora, en caso de que se compruebe que ésta última no actuó con la debida diligencia.
<br /><br />
<strong>5. POLÍTICA LABORAL</strong>
<br />
MAFR Consultoría Estratégica S.C., se compromete a respetar lo establecido en la Ley Federal del Trabajo en lo relativo a las condiciones laborales de nuestros empleados, asociados, directivos, terceros y demás personal, así como lo establecido por los organismos internacionales especializados en la materia.

Por lo anterior, nos comprometemos a reclutar, contratar, compensar y promover a nuestro personal, tomando en cuenta únicamente sus capacidades profesionales, desempeño y calificaciones para sus trabajos, manteniendo un ambiente de trabajo profesional, en el que prive el respeto y el trato digno; evitando en todo momento considerar, para cualquier efecto, condiciones como la edad, raza, religión, género, origen, discapacidad, embarazo u orientación sexual.

Asimismo, MAFR Consultoría Estratégica S.C., se compromete a respetar la legislación laboral de los países, en que nuestros empleados se encuentren realizando sus actividades en caso de haberlos.
<br /><br />
<strong>6. POLÍTICA SOBRE MANEJO DE LA INFORMACIÓN</strong>
<br />
En MAFR Consultoría Estratégica S.C., estamos conscientes de que en el desempeño de nuestras actividades comerciales, tendremos a nuestra disposición información que puede afectar los derechos de particulares o de instituciones públicas, es por esto, que nos comprometemos a otorgar un tratamiento a la información de conformidad con las leyes, en especial lo contenido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares y a la Ley Federal de Acceso a la Información Pública Gubernamental, en lo que nos resulten aplicables, así como a acatar las disposiciones en materia de transparencia y acceso a la información a nivel mundial.

De ser necesario, los empleados, asociados, directivos, terceros y demás personal de la Consultora, que se encuentren a cargo de la información proporcionada, deberán firmar un aviso de confidencialidad o un documento semejante en los países que así lo requieran, haciéndose responsables por la utilización de dicha información.

Asimismo, la información de carácter sensible de la Consultora no podrá ser divulgada por ningún empleado, asociado, directivo, tercero o demás personal, ocasionando, en consecuencia, el deber de quien tiene acceso a la información de resguardarla de cualquier otra persona que carezca de autorización o de necesidad para consultarla. Esta obligación se extiende incluso si llegará a concluir su relación laboral con MAFR Consultoría Estratégica S.C.

La contravención a lo anterior, puede ocasionar la imposición de sanciones disciplinarias, así como las consecuencias legales tanto para quien comete la falta como para la Consultora, en caso de que se compruebe que ésta última no actuó con la debida diligencia.
<br /><br />
<strong>7. POLÍTICA DE SEGURIDAD LABORAL</strong>
<br />
MAFR Consultoría Estratégica S.C., estamos comprometidos a mantener un lugar seguro de trabajo que sea ambientalmente responsable, en cumplimiento de las disposiciones legales correspondientes, tanto a nivel nacional como a nivel mundial.

Por lo anterior, cada empleado, asociado, directivo, terceros y demás personal de la Consultora, tiene la responsabilidad de salvaguardar los activos físicos, económicos, de información y cualesquiera otros, que pudieran incidir en las condiciones laborales.

Los activos de MAFR Consultoría Estratégica S.C., pueden ser utilizados únicamente para el beneficio de los asuntos de la Consultora, evitando en todo momento los beneficios personales, esta obligación se extenderá en caso de ser necesario, para las empresas que mantengan relaciones comerciales con la Consultora.
<br /><br />
<strong>8. POLÍTICA SOBRE ACTIVIDADES POLÍTICAS</strong>
<br />
De conformidad con las leyes electorales aplicables, MAFR Consultoría Estratégica, S.C., tiene que verificar la procedibilidad de prestación de servicios a precandidatos, candidatos, partidos políticos o funcionarios públicos, siempre y cuando sea únicamente para los fines constitutivos de la Consultora.

Los empleados, asociados, directivos, terceros y demás personal podrán realizar actividades políticas fuera de su horario de labores, siempre que con ello no se genere un riesgo para la Consultora, debiendo infórmalo a la Junta de Socios.
<br /><br />
<strong>9. DISPOSICIONES FINALES</strong>
<br />
Con el propósito de asegurar el cumplimiento de las presentes Políticas MAFR Consultoría Estratégica S.C., se compromete a que todos los empleados, asociados, directivos, terceros y demás personal conozcan y comprendan el contenido del presente documento; para ello, se deberá dar una amplia divulgación de su contenido y, adicional a ello, se podrán realizar auditorías y solicitar informes sobre la actuación de todos los obligados a cumplir con las leyes que presten sus servicios en la Consultora.

Las presentes políticas entraran en vigor al momento de su aprobación por la Junta de Socios, de las cuales se deberá mantener un ejemplar en el Acervo Legislativo de la Consultora, para efectos de consulta.

Todos los empleados, asociados, directivos, terceros y demás personal deberán contar con un ejemplar de las presentes Políticas.

En caso de cualquier duda, sugerencia, aclaración o corrección a las presentes Políticas, así como para denunciar su contravención o la de cualquier otro documento institucional, se podrá realizar al correo electrónico <a href="mailto:contacto@mafyasociados.com" className="link_sin_decoracion">contacto@mafyasociados.com</a>
                </p>
                <br />
                <br />
            </Col>
        </Row>
    );
}