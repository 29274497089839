import React from 'react';
import MetaTags from 'react-meta-tags';

const MetaPrivilegios = () => {
  
    return (
        <div class="wrapper">
          <MetaTags>
            <title>Privilegios masculinos, ¿Cómo puedo aportar a mi entorno?</title>
            <meta id="meta-description" property="og:description" content="Muchas veces hemos escuchado que como hombres tenemos muchísimos privilegios" />
            <meta id="og-title" property="og:title" content="Privilegios maculinos, ¿Cómo puedo aportar a mi entorno?" />
            <meta id="og-image" property="og:image" content="https://www.mafyasociados.com/static/media/Maf_PrivilegiosMaculinos.1a912907.jpg" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="https://www.mafyasociados.com/privilegios-masculinos" />
          </MetaTags>
        </div>
      );
  }

  export default MetaPrivilegios;