import React from "react";
import { Row, Col } from "antd";
import MetaTags from "react-meta-tags";
import Helmet from "react-helmet";
import DocumentMeta from "react-document-meta";

import img_src_header from "../../../assets/images/paginas/violencia_genero_era_digital/Maf_ViolenciaDeGeneroDigital_not.jpg";

export const ViolenciaEraDigital = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="La violencia de género es un fenómeno que sucede todos los días y en ámbitos muy diversos." />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.mafyasociados.com/violencia-de-genero-en-una-era-digital" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Evelyn Esquivel" />
        <meta name="author" content="Evelyn Esquivel" />
        <meta property="og:title" content="LA VIOLENCIA DE GÉNERO EN UNA ERA DIGITAL" />
        <meta property="og:description" content="La violencia de género es un fenómeno que sucede todos los días y en ámbitos muy diversos." />
        <meta property="og:url" content="https://www.mafyasociados.com/violencia-de-genero-en-una-era-digital" />
        <meta property="og:site_name" content="MAF & Asociados" />
        <meta property="og:image" content="https://mafyasociados.com/archivos/vistas/Maf_ViolenciaDeGeneroDigital_not.png" />
        <meta property="og:image:secure_url" content="https://mafyasociados.com/archivos/vistas/Maf_ViolenciaDeGeneroDigital_not.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="La violencia de género es un fenómeno que sucede todos los días y en ámbitos muy diversos." />
        <meta name="twitter:title" content="LA VIOLENCIA DE GÉNERO EN UNA ERA DIGITAL" />
        <meta name="twitter:image" content="https://mafyasociados.com/archivos/vistas/Maf_ViolenciaDeGeneroDigital_not.png" />
      </Helmet>
      <Row>
        <Col span={24}>
          <img alt="Articulo violencia era digital" src={img_src_header} style={{ width: "100%" }} />
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
          <br />
            <br />
        <p
            className="color_naranja titulo_tamano "
            style={{ textAlign: "center"}}
          >
            <strong>
            LA VIOLENCIA DE GÉNERO EN UNA ERA DIGITAL.
            </strong>
            
          </p>
          <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify" }}
          >
            Por Evelyn Esquivel
            <br />
            <br />
            La violencia de género es un fenómeno que sucede todos los días y en ámbitos muy diversos. La globalización en la que actualmente nos encontramos, hace que ciertos problemas o acontecimientos sociales, encuentren esparcimiento de manera casi inmediata y, la violencia contra la mujer, no es la excepción, pues la tecnología ha ocasionado que este lamentable fenómeno social se manifieste en un canal de difusión con alcances de magnitudes inimaginables.
            <br /><br />
            La llegada de las tecnologías de la información a nuestro país, el auge alcanzado y el acceso a ellas, es reciente, pero avanza a pasos agigantados. En México hay <strong>80.6 millones de personas usuarias de internet que representan el 70.1% de la población de seis años o más.<sup>1</sup>  De los 80.6 millones, el 51.6% son mujeres.<sup>2</sup></strong> Si bien, el alcance que tienen las tecnologías de la información en nuestro país tiene grandes beneficios para quienes las consumen, y su acceso se ha propagado como un servicio general abierto al público, también lo es que representa ahora, una nueva forma en que los derechos de las personas y en específico de las mujeres pueden verse vulnerados y con ello provocar una violación a su privacidad. Esta situación que ahora se reconoce, se enmarca dentro de lo que actualmente se denomina violencia digital, pero y ¿qué es la violencia digital?
            <br /><br />
            <strong>La Ley de Acceso de las Mujeres a una Vida Libre de Violencia de la Ciudad de México,</strong> define en una de sus modalidades a la <strong>violencia digital</strong> como cualquier acto realizado mediante el uso de materiales <strong>impresos, correo electrónico, mensajes telefónicos, <em>redes sociales, plataformas de internet, correo electrónico, o cualquier medio tecnológico,</em> por el que se obtenga, exponga, distribuya, difunda, exhiba, reproduzca, transmita, comercialice, oferte, intercambie y comparta imágenes, audios o videos reales o simulados de contenido sexual íntimo de una persona, <u>sin su consentimiento;</u></strong> que atente contra la integridad, la dignidad, la intimidad, la libertad, la vida privada de las mujeres o cause daño psicológico, económico o sexual tanto en el ámbito privado como en el público, además de daño moral, tanto a ellas como a sus familias.<sup>3</sup> 
            <br /><br />
            Como se observa, la violencia digital tiene distintas manifestaciones, enfocándose la mayoría a lacerar derechos de las mujeres, tales como la libertad, la privacidad y su dignidad humana, soslayando con ello la imagen de quien lo sufre. Algunas de las más comunes son: <strong>difundir videograbaciones, imágenes y/o audios de contenido sexual</strong> sin el consentimiento de la víctima; <strong>acecho</strong>, el cual consiste en la constante vigilancia de una persona para lograr algún fin establecido; acoso, que va enfocado a insinuaciones sexuales o comentarios sexistas. De todas las manifestaciones anteriores, la más recurrente es aquella que tiene que ver con la difusión <strong>de contenido íntimo de una mujer a través de redes sociales o plataformas de internet. </strong>
            <br /><br />
            Este tipo de conductas, provoca en las mujeres principalmente, un sinfín de emociones que se originan en principio por la <strong>estigmatización que se la ha dado a su sexualidad y en segunda por la apertura que aún existe en expresar comentarios misóginos a través de redes sociales,</strong> dando como consecuencia que sean ellas quienes más sufren de este tipo de violencia, por ejemplo, en 2019, el <strong>24.2% de las usuarias fueron objeto de ciberacoso</strong> a diferencia de un 17.7% de los hombres.<sup>4</sup>
            <br /><br />
            Esta problemática, ha generado que recientemente (finales de 2019), se hayan creado mecanismos de protección en contra de la violencia digital, como la “Ley Olimpia”, la cual es <strong>un conjunto de reformas legislativas encaminadas a reconocer la violencia digital y sus diferentes modalidades como delito,</strong> y sancionar a los responsables. Al día de hoy <strong>21 estados de la república</strong> se han unido a la Ley Olimpia y han reconocido la violencia digital en sus instrumentos normativos respectivos. Lo que demuestra, que existe un avance en el tema, pero que aún falta mucho por hacer.
            <br /><br />
            <strong>¿Qué hacer si una foto o video privado se filtra en redes sociales o alguien me está amenazando con subir mi contenido? </strong>
            <br /><br />
            Generalmente las redes sociales más populares, como Facebook e Instagram, han implementado mecanismos de protección a través del reporte de chantaje, amenazas o filtración de fotos íntimas, donde la prioridad es ayudarte a quitar el contenido no deseado de la plataforma. Esto lo consigues llenando <a target="_blank" href="https://m.facebook.com/help/contact/567360146613371">este formulario para Facebook</a> y para <a target="_blank" href="https://help.instagram.com/contact/1681792605481224">Instagram este otro,</a> para que reportes lo sucedido y así puedan ayudarte.
            <br /><br />
            De igual manera, en Ciudad de México, las instituciones que pueden brindar orientación y apoyo, son las siguientes:
            <ul>
              <li><a target="_blank" href="https://www.ssc.cdmx.gob.mx/organizacion-policial/subsecretaria-de-inteligencia-e-investigacion-policial/policia-cibernetica">Policía de ciberdelincuencia preventiva CDMX</a></li>
              <li><a target="_blank" href="https://mpvirtual.fgjcdmx.gob.mx/CiberDenuncia/terminosCondiciones.aspx">Fiscalía General de Justicia CDMX</a></li>
              <li><a target="_blank" href="https://www.consejociudadanomx.org/index.php/es/">Consejo Ciudadano de Seguridad Pública CDMX</a></li>
              </ul>
              
              Recuerda, que las tecnologías de la información pueden ser extremadamente peligrosas si son usadas para los fines incorrectos, sin embargo, hay muchas acciones que podemos realizar desde nuestras trincheras para evitarlo. Si tienes amigas o amigos que comparten imágenes íntimas de mujeres u hombres; hacen comentarios sexistas o con insinuaciones sexuales; si te encuentras un grupo o página en redes sociales que comparte ilegalmente este contenido, ¡ALZA LA VOZ! Ayudemos a combatir la violencia digital. Si eres mujer y alguna vez pasaste o estás pasando por esto, NO ESTÁS SOLA Y NUNCA SERÁ TU CULPA. 
            <br />
          </p>
          <p
            className="color_naranja texto_tamano"
            style={{ textAlign: "center" }}
          >
            <strong>
            ¡DENUNCIA!
            </strong>
            <br />
            <br />
          </p>
          <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify" }}
          >
              <hr style={{ marginLeft: '20px', marginRight: '20px' }} />
              <br /><br />
              <small>
                  <sup><strong>1</strong></sup><a href="https://www.inegi.org.mx/contenidos/saladeprensa/boletines/2020/OtrTemEcon/ENDUTIH_2019.pdf" target="_blank" className="link_sin_decoracion"> Encuesta Nacional sobre Disponibilidad y Uso de Tecnologías de la Información en los Hogares. INEGI, 2019.</a>
                  <br />
                  <sup><strong>2</strong></sup> Idem. <a href="" target="_blank" className="link_sin_decoracion"></a>
                  <br />
                  <sup><strong>3</strong></sup><a href="http://www.data.consejeria.cdmx.gob.mx/images/leyes/leyes/Ley_Acceso_Mujeres_a_una_Vida_Libre_de_Violencia_2.pdf" target="_blank" className="link_sin_decoracion"> Ley de Acceso de las Mujeres a una Vida Libre de Violencia de la Ciudad de México. Asamblea Legislativa. </a>
                  <br />
                  <sup><strong>4</strong></sup><a href="https://www.inegi.org.mx/contenidos/programas/mociba/2019/doc/mociba2019_resultados.pdf" target="_blank" className="link_sin_decoracion"> Módulo de Ciberacoso, INEGI, 2019.</a>
                  <br />
              </small>
          </p>
        </Col>

        <Col span={20} offset={2}></Col>
      </Row>
    </>
  );
};
