import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Button } from 'antd';
import { Element } from 'react-scroll';


import '../../../assets/css/sitio/servicios.css';
import img_maria from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/Jorge_Rafael_Coffau_Kayser.jpg';
import img_sectores from '../../../assets/images/sitio/servicios/sectores.png';
import img_sectores_1 from '../../../assets/images/sitio/servicios/Maf_banners_servicios_sectori industrias.jpg';
import img_sectores_2 from '../../../assets/images/sitio/servicios/Maf_banners-_industrias_1.jpg';

const { Paragraph } = Typography;

let areas = [
    {
        nombre: 'Jurídico', puntos: [<><ul className='juridico text_li_servicio'>
            <li>Derecho corporativo y societario</li>
            <li>Derecho mercantil</li>
            <li>Derecho civil</li>
            <li>Derecho administrativo y constitucional</li>
            <li>Derecho penal</li>
            <li>Derecho familiar</li>
            <li>Derecho inmobiliario</li>
        </ul></>],
        color:'juridico_p titulo_servicio'
    },
    {
        nombre: 'Estrategia Político social', puntos: [<>
            <ul className='estrategia text_li_servicio'>
                <li>Vinculación estratégica, política y empresarial.</li>
                <li>Licenciamiento social</li>
                <li>Responsabilidad social empresarial</li>
            </ul></>],
        color:'estrategia_p titulo_servicio'
    },
    {
        nombre: 'Asuntos Corporativos', puntos: [<>
        <ul className='asuntos text_li_servicio'>
            <li>Monitoreo y diagnóstico de iniciativas en los ámbitos federal y local.</li>
            <li>Identificación y vinculación de autoridades y legisladores estratégicos.</li>
            <li>Asesoría a cámaras y asociaciones empresariales.</li>
        </ul></>],
        color:'asuntos_p titulo_servicio'
    },
    {
        nombre: 'Cumplimiento Regulatorio y Auditoría', puntos: [<>
            <ul className='cumplimiento text_li_servicio'>
                <li>Estructurar soluciones para cumplir con la regulación.</li>
                <li>Estrategias preventivas y correctivas que limitan las desviaciones en el desarrollo de los procedimientos que están regulados por la ley.</li>
                <li>Asesoría especializada para la navegación dentro de los procesos regulatorios.<Element name="#servicios" ></Element></li>
                <li>Diseño y aplicación de evaluaciones para verificar que en las actividades de la empresa se da adecuado cumplimiento a las normas.</li>
            </ul></>],
        color:'cumplimiento_p titulo_servicio'
    },
]

let servicios = [
    {
        nombre: 'Jurídico', puntos: [<><ul className='juridico_2 text_li_servicio'>
            <li>Seguimiento de procesos penales.</li>
            <li>Defensa contra delitos penales con perspectiva de género. </li>
            <li>Procesos familiares relacionados con divorcios, pensión alimenticia, juicios sucesorios.</li>
            <li>Recuperación de cartera vencida (adeudos).</li>
            <li>Juicios de nulidad (Municipal, Estatal, Federal) contra actos emitidos por autoridades administrativas.</li>
            <li>Juicio de amparo indirecto contra leyes generales (Autoaplicativos y heteroaplicativos).</li>
            <li>Juicio de amparo directo contra resoluciones emitidas por autoridades administrativas.</li>
            <li>Asesoría integral en materia inmobiliaria, compra-venta de un bien inmueble y procesos relacionados con proyectos de construcción.</li>
        </ul></>],
        color:'juridico_2_p titulo_servicio'
    },
    {
        nombre: 'Estrategia Político Social', puntos: [<>
        <ul  className='estrategia_poli text_li_servicio'>
            <li>Esquemas para el licenciamiento social y creación de valor compartido</li>
            <li>Estudios comunitarios para empresas</li>
            <li>Fortalecimiento de responsabilidad social empresarial</li>
            <li>Inteligencia política: Mapeo y engagement con stakeholders, mapeos institucionales.</li>
            <li>Manejo de crisis, particularmente en torno a movilizaciones sociales contra empresas y reputación corporativa</li>
            <li>Asuntos públicos: Regulación empresarial relacionada con los tres niveles de gobierno</li>
            <li>Estrategias de comunicación corporativa</li>
            <li>Análisis coyunturales en materia de agua, prospectiva política, electoral y medio ambiental entre otros.</li>
        </ul></>],
        color:'estrategia_poli_p titulo_servicio'
    },
    {
        nombre: 'Estrategia Regulatoria', puntos: [<>
        <ul className='estrategia_regular text_li_servicio'>
            <li>Pre-auditorias para verificación del cumplimiento normativo vinculado con la operación del transporte, previo a visitas de la SICT y/o mejoramiento del control interno de la empresa.</li>
            <li>Profesionalización/Capacitación de Operadores (as) del autotransporte de carga.</li>
            <li>Asesoría legal para la recuperación de unidades y carga, que sufrieron siniestros o fueron retenidas por la autoridad.</li>
            <li>Asesoría, navegación y/o acompañamiento para:
                <ol type='1'>
                    <li>Implementación de CFDI con Complemento Carta Porte</li>
                    <li>Trámites en materia de autotransporte, regulación sanitaria y/o ambiental en instalaciones y/o productos.</li>
                    <li>Gestiones ante autoridades reguladoras en materia de energía.</li>
                </ol>
            </li>
            <li>Consultoría para la evaluación de cumplimiento a fin de brindar certeza sobre el correcto desarrollo de los procesos y la adecuada conformación de expedientes, cuando se tienen contrataciones con organismos del sector público.</li>
            <li>Asesoría en materia de regulación en telecomunicaciones.</li>
            <li>Consultoría para el acceso a la información de organismos públicos vinculados a las telecomunicaciones y radiodifusión.</li>
        </ul></>],
        color:'estrategia_regular_p titulo_servicio'
    },
    {
        nombre: 'Asuntos Corporativos', puntos: [<>
            <ul className='asuntos_corporativos text_li_servicio'>
                <li>Monitoreo y diagnóstico de iniciativas en los ámbitos Federal y local.</li>
                <li>Identificación y vinculación con autoridades y legisladores estratégicos.</li>
                <li>Elaboración de estrategias integrales y transversales para nuestros clientes.</li>
                <li>Asesoría a Cámaras y Asociaciones</li>
                <li>Empresariales.<Element name="#sectoresindustrias"></Element></li>
                <li>Regularización legal-corporativa de empresas.</li>
            </ul>
        </>],
        color:'asuntos_corporativos_p titulo_servicio'
    },
]

const ver_areas = areas.map(item =>{
    return(
        <>
        <Row justify='center'>
            
            <Col span={20}>
                <p className={item.color}>{item.nombre}</p>
                {item.nombre === 'Cumplimiento Regulatorio y Auditoría' ?
            <></>:<></>}
                <br />
                {item.puntos}<br /><br />
                
            </Col>
           
        </Row>
        </>
    )
})
const ver_servicios = servicios.map(item =>{
    return(
        <>
        <Row justify='center'>
            <Col span={20}>
                <p className={item.color}>{item.nombre}</p><br />
                {item.puntos}<br /><br />
            </Col>
        </Row>
        </>
    )
})
export const NuevosServicios = (props) => {

    return (
        <>
            <Row className="">
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} className="banner_servicios">
                <Row>
                        <Col span={20} offset={2}>

                            <Paragraph className="titulo_nosotros">
                                <br /><br />
                            </Paragraph>
                        </Col>

                        <Col span={24}>
                            <Row style={{ display: 'non' }} className="contenedor_frase_servicios">
                                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                                    <Row align="middle">
                                        <Col xs={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} >
                                            <img src={img_maria} className="img_frase_doctora" />
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 0 }}>
                                            <Paragraph className="texto_frase_doctora">
                                                <strong>¡Nuestros servicios son el medio,<br />
                                                    lograr relaciones de largo plazo, el mejor resultado!</strong>
                                            </Paragraph>
                                            <Element name="#areas"></Element>
                                            <Paragraph className="texto_frase_doctora_2">
                                                <i>Jorge Rafael Coffau Kayser</i>
                                            </Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row className="seccion_servicios">
            
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <p className="titulo_seccion_servicios" >ÁREAS</p>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <p className="titulo_seccion_servicios_movil" >ÁREAS</p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {ver_areas}
                    
                </Col>
            </Row>
            <Row className="seccion_servicios">
            
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <p className="titulo_seccion_servicios" >SERVICIOS</p>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <p className="titulo_seccion_servicios_movil" >SERVICIOS</p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {ver_servicios}
                    <br />
                </Col>
            </Row>
            <Row justify='center' className='seccion_servicios'>
            
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 0 }}>
                    <p className="titulo_seccion_servicios" >SECTORES, INDUSTRIAS Y ORGANISMOS EMPRESARIALES</p>
                </Col>
                <Col xs={{ span: 20, offset: 0 }} md={{ span: 0, offset: 0 }}>
                    <p className="titulo_seccion_servicios_movil" >SECTORES, INDUSTRIAS Y ORGANISMOS EMPRESARIALES</p><br />
                </Col>
                <Col xs={24} md={0}>
                <img src={img_sectores_1} style={{width:'100%'}} /><br />
                <img src={img_sectores_2} style={{width:'100%'}} />
                </Col>
                <Col xs={0} md={12}>
                <img src={img_sectores_1} style={{width:'100%'}} />
                </Col>
                <Col xs={0} md={12}>
                <img src={img_sectores_2} style={{width:'100%'}} />
                
                </Col>
                <Col span={20}>

                <br /><br />
                    <p className='titulo_servicio_sector'>
                    Sector Privado
                    </p><br />
                    <ul className='text_li_servicio'>
                        <li> Comercio Exterior</li>
                        <li> Comunicación Social</li>
                        <li> Telecomunicaciones</li>
                        <li> Transporte</li>
                        <li> Alimentos y bebidas</li>
                        <li> Energía</li>
                        <li> Medio ambiente</li>
                        <li> Publicidad Exterior</li>
                        <li> Industria de la transformación</li>
                        <li> Seguridad Privada</li>
                        <li> Salud</li>
                    </ul><br /><br />
                    <p className='titulo_servicio_sector'>
                    Sector Público
                    </p>
                    <br /><br />
                    <p className='titulo_servicio_sector'>
                    Organismos Empresariales
                    </p><br /><br />

                    
                    <br /><br />
                </Col>
            </Row>
        </>
    );
}