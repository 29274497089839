import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Button } from 'antd';

import { MostrarServicios } from './MostrarServicios';

import '../../../assets/css/sitio/servicios.css';
import { CarouselServicios } from '../home/CarouselServicios';
import { CarouselBanners } from '../home/CarouselBanners';
import img_maria from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/08_Maf_NuestroEquipo_EmmanuelMartinez.jpg';

const { Paragraph } = Typography;


const secciones = [
    {seccion:'privado', nombre:'ASESORÍA JURÍDICA', clase:'privado_asesoria',link:'',texto:['En las siguientes áreas:'], descrimpcion:['Mercantil','Corporativo','Penal','Civil']},
    {seccion:'privado', nombre:'ASUNTOS CORPORATIVOS', clase:'privado_corporativos',link:'',texto:[''], descrimpcion:['Asesoría y representación ante cámaras y asociaciones empresariales','Desarrollo de estrategias de posicionamiento ante el Sector Público','Diseño e implementación de acciones de responsabilidad social con impacto comunitario y gubernamental','Cabildeo','Realización de trámites especializados en el sector gubernamental a nivel nacional e internacional','Asesoría fiscal y contable','Búsqueda de financiamientos públicos o internacionales para realización de proyectos en beneficio de la sociedad']},
    {seccion:'privado', nombre:'CONSULTORÍA EMPRESARIAL', clase:'privado_consultoria',link:'',texto:[''], descrimpcion:['Elaboración de plan estratégico','Elaboración de proyectos de inversión','Elaboración de estrategia comercial','Servicio de administración financiera','Coaching empresarial e individual']},
    {seccion:'privado', nombre:'MERCADOTECNIA', clase:'privado_mercadotecnia',link:'',texto:[''], descrimpcion:['Investigación de mercados','Desarrollo de imagen y comunicación','Manual de identidad corporativa','Auditorías de punto de venta']},
    {seccion:'privado', nombre:'SERVICIOS DIGITALES', clase:'provado_servicios',link:'',texto:[''], descrimpcion:['Investigación de mercados','Desarrollo de imagen y comunicación','Manual de identidad corporativa','Auditorías punto de venta']},

    {seccion:'publico', nombre:'ASESORÍA POLÍTICA Y DESARROLLO SOCIAL', clase:'publico_asesoria',link:'',texto:[''], descrimpcion:['Estrategias de posicionamiento político','Solución de conflictos comunitarios','Creación y desarrollo de alternativas de desarrollo social','Cabildeo político','Asesoría para financiamiento y presupuesto gubernamental','Consultoría electoral','Asesoría en violencia política','Desarrollo de planes y programas gubernamentales','Capacitación en liderazgo político y capacidades técnicas para funcionarios','Diseño de comunicación estratégica especializada en sectores','Políticas y desarrollo de servicios con perspectiva de género','Diseño de políticas y estrategias para servicios con perspectiva de género y diversidad sexual']},
    {seccion:'publico', nombre:'AUDITORÍAS Y CALIDAD EN EL SERVICIO', clase:'publico_auditorias',link:'',texto:[''], descrimpcion:['Auditorías operativas con propuestas enfocadas a eficiencia y resultados','Certificación de normas ISO y otras','Generación de estándares de calidad en el servicio','Desarrollo de indicadores operativos','Creación de sistemas informáticos para el de seguimiento de proyectos y operación a nivel directivo']},
    {seccion:'publico', nombre:'ESTRATEGIAS DE SEGURIDAD Y JUSTICIA​', clase:'publico_estrategias',link:'',texto:[''], descrimpcion:['Evaluación, diagnóstico y reingeniería de instituciones o procesos clave','Reorganizaciones operativas','Diseño e implementación de mejores prácticas para la operación del Sistema Penal Acusatorio','Profesionalización y capacitación especializada para los operadores del Sistema Penal Acusatorio','Estrategias para prevenir y atender la violencia de género','Diseño e implementación de acciones para la prevención del delito a nivel municipal','Diseño de estrategias para el Compliance Penal','Desarrollo de planes y acciones para atender los delitos en materia de corrupción','Desarrollo de indicadores para el Sistema de Justicia Penal']},
    {seccion:'publico', nombre:'MERCADOTECNIA', clase:'publico_mercadotecnia',link:'',texto:[''], descrimpcion:['Investigación de mejores prácticas operativas y de organización','Mercadotecnia política','Diseño de estrategias para difundir posicionamiento y resultados de las instituciones ante la comunidad','Desarrollo de imagen y comunicación']},
    {seccion:'publico', nombre:'PLANEACIÓN Y REINGENIERÍA DE PROCESOS', clase:'publico_planeacion',link:'',texto:[''], descrimpcion:['Elaboración de planes de desarrollo','Reingeniería de procesos','Planes de mejora continua','Certificaciones','Capacitaciones','Modelos de negocio','Modelos de gestión','Manuales de organización y procesos','Administración de proyectos']},
    {seccion:'publico', nombre:'SERVICIOS DIGITALES', clase:'publico_servicios',link:'',texto:[''], descrimpcion:['Diseño y creación de páginas web','Community manager','Marketing digital','Desarrollo de aplicaciones de servicios municipales o estatales']},
    {seccion:'publico', nombre:'OTROS SERVICIOS', clase:'publico_otros',link:'',texto:[''], descrimpcion:['Estrategias fiscales y contables','Seguimiento de proyectos para la elaboración de libros blancos','Libros blancos y memorias de proyectos gubernamentales']},

    {seccion:'profesionalizacion', nombre:'CALIDAD EN EL SERVICIO', clase:'profesionalizacion_calidad',link:'',texto:[''], descrimpcion:['']},
    {seccion:'profesionalizacion', nombre:'SISTEMA DE JUSTICIA SOCIAL', clase:'profesionalizacion_sistema',link:'',texto:[''], descrimpcion:['']},
    {seccion:'profesionalizacion', nombre:'LIDERAZGO', clase:'profesionalizacion_liderazgo',link:'',texto:[''], descrimpcion:['']},
    {seccion:'profesionalizacion', nombre:'PLANEACIÓN', clase:'profesionalizacion_planeacion',link:'',texto:[''], descrimpcion:['']},

    {seccion:'', nombre:'', clase:'',link:'',texto:[''], descrimpcion:['']},
]

export const Servicios = (props) => {
    const { tipo  } = useParams();
    const [tipo_servicio, setTipoServicio] = useState(tipo === 'publico' ? 'publico' : tipo === 'profesionalizacion' ? 'profesionalizacion' : 'privado');
    const [tipo_servicio_banner, setTipoServicioBanner] = useState('privado');
    const [clase_servicio, setClaseServicio] = useState('privado_asesoria');
    
   
    const ver_imagen = secciones.filter((objeto) =>{
        if(objeto.seccion === tipo_servicio_banner){
            return true;
        }else{
            return false;
        }
    }).map((seccion,index) => {

        return(
            <>
            <Col span={seccion.seccion === 'privado' ? 4 : seccion.seccion === 'publico' ? 3 : 6} id={seccion.index} className="contenedor_secciones_servicios">
                <p className={tipo_servicio_banner === 'publico' ? 'nombre_seccion_servicios_2' : 'nombre_seccion_servicios'} onMouseEnter={() => { setClaseServicio(seccion.clase)}} >
                    {seccion.nombre}
                    </p>
            </Col>
            </>
        )
    });

    const ver_textos = secciones.filter((objeto) =>{
        if(objeto.clase === clase_servicio){
            return true;
        }else{
            return false;
        }
    }).map((objeto,index) => {
        return(
            <>
            <p className="titulo_desc_servicios">{objeto.nombre}</p>
            {objeto.texto != '' ?
            <p className="desc_desc_servicios">
            {objeto.texto.map((parrafo,index) => {
              if(index === 0){
                return(
                <>{parrafo}</>
                );
              }else{
                return(
                <>{parrafo}</>
                );
              }
              })}
              </p>
              :
              <></>}
            {objeto.descrimpcion != '' ?
            <ol className="puntos_desc_servicios" type="1">
            {objeto.descrimpcion.map((parrafo,index) => {
              if(index === 0){
                return(
                <li>{parrafo}</li>
                );
              }else{
                return(
                <li>{parrafo}</li>
                );
              }
              })}
            </ol>
            :
            <></>}
            </>
        )
    });
    return (
        <>
        <Row style={{display:'non'}}>
                <Col span={24}>
                    <CarouselBanners />
                    <br /><br /><br />
                </Col>
            </Row>
            <Row className="contenedor_seccion_inicio">
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} className="banner_servicios">
                    <Row>
                        <Col span={20} offset={2}>

                            <Paragraph className="titulo_nosotros">
                                <br /><br /><br />
                            </Paragraph>
                        </Col>
                        
                        <Col span={24}>
                            <Row style={{ display: 'non' }} className="contenedor_frase_servicios">
                                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                                    <Row align="middle">
                                        <Col xs={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} >
                                            <img src={img_maria} className="img_frase_doctora" />
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 0 }}>
                                            <Paragraph className="texto_frase_doctora">
                                                <strong>¡Nuestros servicios son el medio,<br />
                                                lograr relaciones de largo plazo, el mejor resultado!</strong>
                                            </Paragraph>
                                            <Paragraph className="texto_frase_doctora_2">
                                                <i>Emmanuel Martínez Suazo</i>
                                            </Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row className="seccion_servicios">
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <Paragraph className="titulo_seccion_servicios" >NUESTROS SERVICIOS</Paragraph>
                </Col>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }}>
                    <Paragraph className="titulo_seccion_servicios_movil" >NUESTROS SERVICIOS</Paragraph>
                </Col>
                <Col xs={24}>
                    <CarouselServicios />
                </Col>
            </Row>
        </>
    );
}