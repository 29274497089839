import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import curso_src_img_taller_ministerio from '../../../assets/images/sitio/cursos/Maf_Curso_01TallerMinisterioPublico.jpg';
import curso_src_img_protocolo_estabul from '../../../assets/images/sitio/cursos/Maf_Curso_02ProtocoloEstambul.jpg';
import curso_src_img_tecnicas_ligitacion from '../../../assets/images/sitio/cursos/Maf_Curso_03TecnicasDeLitigacion.jpg';
import curso_src_img_participacion_oral from '../../../assets/images/sitio/cursos/Maf_Curso_04ParticipacionOral.jpg';
import curso_src_img_atencion_victimas from '../../../assets/images/sitio/cursos/Maf_Curso_05AtencionAVictimas.jpg';
import curso_src_img_actualizacion_peritos from '../../../assets/images/sitio/cursos/Maf_Curso_06ActualizacionPeritos.jpg';
import curso_src_img_derechos_ninos from '../../../assets/images/sitio/cursos/Maf_Curso_07DerechosNinosyAdolecentes.jpg';

const cursos = [{
    img_src: curso_src_img_taller_ministerio,
    nombre:'Taller para ministerio público, procedimientos de la Ley Nacional de Ejecución Penal',
    info: 'Aplicar conocimientos técnico-jurídicos para en el ámbito de su competencia el cumplimiento de las cuestiones de orden público e interés social en los procedimientos previstos en la Ley Nacional de Ejecución Penal, así como, adquirir las habilidades, aptitudes y actitudes necesarias, para hacerlo con estricta observancia a los derechos humanos.',
    url_info: '#',
    fecha: ''
},
{
    img_src: curso_src_img_protocolo_estabul,
    nombre:'Protocolo de Estambul​',
    info: 'Aplicar los conocimientos técnico-jurídicos en la investigación de los delitos en materia de derechos humanos y violaciones a los mismos, especializándolos en la atención inmediata y debida impartición de justicia para víctimas de tortura u otros tratos o penas crueles, inhumanas o degradantes; profundizando en la aplicación de mecanismos internacionales que sustenten las investigaciones que en la materia se realicen.',
    url_info: '#',
    fecha: ''
},
{
    img_src: curso_src_img_tecnicas_ligitacion,
    nombre:'Técnicas de litigación para ministerios públicos (teórico-práctico)​',
    info: 'Aplicar conocimientos y habilidades en la implementación de técnicas argumentativas en audiencias previas a la audiencia de juicio oral y técnicas de litigación en el juicio oral, así como adquirir sensibilidad y actitudes necesarias, para brindar un trato digno y respetuoso a las víctimas e imputados, con estricta observancia a los derechos humanos.',
    url_info: '#',
    fecha: ''
},
{
    img_src: curso_src_img_participacion_oral,
    nombre:'La participación oral y escrita del perito en el proceso penal acusatorio. (elaboración del dictamen y reproducción en audiencia)',
    info: 'Dotar al personal pericial de los conocimientos jurídicos y habilidades técnicas para llevar a cabo una adecuada argumentación jurídica tanto oral como escrita, en el desempeño de sus funciones en las diversas audiencias y en el juicio oral, para coadyuvar al esclarecimiento de los hechos materia de las investigaciones realizadas por los Agentes del Ministerio Público y policías de investigación.',
    url_info: '#',
    fecha: ''
},
{
    img_src: curso_src_img_atencion_victimas,
    nombre:'Atención a Víctimas del Secuestro',
    info: 'Aplicar conocimientos técnico-jurídicos en la investigación del delito de secuestro, así como adquirir las habilidades, aptitudes y actitudes necesarias, para brindar un trato digno y respetuoso a las víctimas u ofendidos, atendiendo sus necesidades con base en los protocolos y procedimientos específicos, con estricta observancia a los derechos humanos.',
    url_info: '#',
    fecha: ''
},
{
    img_src: curso_src_img_actualizacion_peritos,
    nombre:'Taller de actualización para Peritos en el Sistema de Justicia Penal​',
    info: 'Aplicar conocimientos y habilidades en el cumplimiento de los principios rectores, desarrollo y operación de los procedimientos afines a su función en las etapas del Sistema de Justicia Penal, en estricto apego al Código Nacional de Procedimientos Penales, para aportar elementos de valor probatorio en la investigación ministerial, de manera coordinada con la policía de investigación y en estricta observancia de los derechos humanos.',
    url_info: '#',
    fecha: ''
},
{
    img_src: curso_src_img_derechos_ninos,
    nombre:'Derechos de los Niños, Niñas y Adolescentes (PDI, AMP y Peritos)',
    info: 'Aplicar conocimientos técnico-jurídicos en la investigación de los delitos en el marco del Sistema Integral de Justicia Penal para Adolescentes, así como adquirir las habilidades, aptitudes y actitudes necesarias, para brindar un trato digno y respetuoso a las niñas, niños y adolescentes, atendiendo sus necesidades con base en los protocolos y procedimientos específicos, con estricta observancia a los derechos humanos.',
    url_info: '#',
    fecha: ''
}];

export const InfoCursos = (props) => {

    const { render_xs } = props;

    let col_cursos = cursos.map(curso => {
        if(render_xs){
            return(
                <Col className="contenedor_curso_movil" xs={{span:20, offset:2}} sm={{span:18, offset:3}} md={{span:12, offset:0}} lg={{span:8, offset:0}}>
                <img alt={curso.nombre} src={curso.img_src} className="img_curso" />
                <br />
        <p className="titulo_curso_movil">{curso.nombre}</p>
        <p className="info_curso_movil">{curso.info}</p>
        <p className="contenedor_mas_info_movil">
            { curso.url_info !== '#' &&
            <Link className="mas_info_curso">MÁS INFORMACIÓN</Link>
            }
        
        <br />
        <span className="fecha_curso">{curso.fecha}</span>
        </p>
                <br />
            </Col>
            );
        }else{
            return(
                <Col className="contenedor_curso" xs={{span:20, offset:2}} sm={{span:18, offset:3}} md={{span:24, offset:0}} lg={{span:24, offset:0}}>
                    <Row>
                        <Col md={10} lg={8}>
                        <img alt={curso.nombre} src={curso.img_src} className="img_curso" />
                        </Col>
                        <Col  md={14} lg={16} className="contenedor_texto_curso">
                            <p className="titulo_curso">{curso.nombre}</p>
                            <p style={{textAlign:'left'}}><hr className="linea_curso" /></p>
                            <span className="fecha_curso">{curso.fecha}</span>
                            <p className="info_curso">{curso.info}</p>
                            { curso.url_info !== '#' &&
                                <Link className="mas_info_curso">MÁS INFO...</Link>
                            }
                        </Col>
                    </Row>
            </Col>
            );
        }
        
    });

    return(
        <Row>
            { col_cursos }
        </Row>
    );
}
