import React from "react";
import { Row, Col } from "antd";
import MetaTags from "react-meta-tags";
import Helmet from "react-helmet";
import DocumentMeta from "react-document-meta";
import MetaPrivilegios from "./MetaPrivilegios";

import img_src_header from "../../../assets/images/paginas/privilegios_masculinos/Maf_PrivilegiosMaculinos.jpg";

export const PrivilegiosMasculinos = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Muchas veces hemos escuchado que como hombres tenemos muchísimos privilegios" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.mafyasociados.com/privilegios-masculinos" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Emilio Lira" />
        <meta name="author" content="Emilio Lira" />
        <meta property="og:title" content="Privilegios masculinos" />
        <meta property="og:description" content="Muchas veces hemos escuchado que como hombres tenemos muchísimos privilegios" />
        <meta property="og:url" content="https://www.mafyasociados.com/privilegios-masculinos" />
        <meta property="og:site_name" content="MAF & Asociados" />
        <meta property="og:image" content="https://mafyasociados.com/archivos/vistas/Maf_PrivilegiosMaculinos.jpg" />
        <meta property="og:image:secure_url" content="https://mafyasociados.com/archivos/vistas/Maf_PrivilegiosMaculinos.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Muchas veces hemos escuchado que como hombres tenemos muchísimos privilegios" />
        <meta name="twitter:title" content="Privilegios masculinos" />
        <meta name="twitter:image" content="https://mafyasociados.com/archivos/vistas/Maf_PrivilegiosMaculinos.jpg" />
      </Helmet>
      <Row>
        <Col span={24}>
          <img alt="Articulo privilegios masculinos" src={img_src_header} style={{ width: "100%" }} />
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
          <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify" }}
          >
            <br />
            <br />
            Por Emilio Lira
            <br />
            <br />
            Muchas veces hemos escuchado que como hombres tenemos muchísimos
            privilegios, y eso es verdad. Algunos autores mencionan que los
            privilegios se obtienen por rasgos y características que no
            controlamos, teniendo en la pirámide del control al hombre blanco
            heterosexual cisgénero sin discapacidad, a partir de ahí, si te
            empiezan a faltar elementos, se comienzan a ver una desigualdad de
            trato, condiciones e incluso derechos. Sin embargo, aún con estas
            pequeñas desventajas que se presentan en el sexo masculino, la
            mayoría de los hombres están exentos de los horrores a los que día
            con día las mujeres están expuestas. Sé lo que estás pensando, que
            también los hombres están expuestos a los problemas de inseguridad
            que azotan al país al mismo tiempo, y si bien eso es cierto, también
            lo es que no son objeto de dichos crímenes debido a su sexo o
            género, o por una desigualdad y discriminación estructural que ha
            articulado nuestra sociedad, además de que sus agresores, en su
            inmensa mayoría al igual que las mujeres, son hombres.
            <br />
            <br />
            Imagina a una mujer de un sector vulnerable que sufre violencia de
            género y además es susceptible de los azotes criminales, esto se
            convierte en una doble propensión a sufrir ataques o hechos
            criminales. El punto crucial en ser un agente de cambio, como
            hombres, en nuestra sociedad, es justamente darse cuenta de los
            privilegios de los que gozamos. Si has estado en el proceso de
            querer contribuir al movimiento feminista no hace falta que te
            consideres como integrante del mismo, tampoco que vayas a las
            marchas o que tomes una postura sobre el aborto. Lo que se nos pide
            como hombres es que estemos informados y que no juzguemos aquello
            que, por mucho o poco, no comulgue con los principios y valores que
            profesamos; que veamos dentro de nosotros mismos cuáles son esos
            privilegios que tenemos y cómo podemos utilizarlos para aportar al
            cambio del pensamiento masculino que se considera nocivo.
            <br />
            <br />
            Unos de tantos privilegios masculinos son:
            <br />
            <br />
            <ol type="1">
              <li>
                Salir de casa sin pensar que puedes ser objeto de una agresión
                sexual.
              </li>
              <li>
                Que no menoscaben tus argumentos con un “seguro está en sus
                días”.
              </li>
              <li>
                Si haces una denuncia contra la persona que te agredió
                sexualmente no te cuestionarán si fue verdad.
              </li>
              <li>
                Mayor accesibilidad a una oportunidad laboral pues ser padre no
                es tan demandante como ser madre.
              </li>
              <li>
                No eres objeto de prejuicios por tener múltiples parejas
                sexuales, incluso de serle infiel a tu pareja.
              </li>
              <li>
                Mayor acceso a sueldos altos y puestos jerárquicos de mayor
                nivel a diferencia de las mujeres.
              </li>
            </ol>
            Estos privilegios son unos de los tantos de los que no hemos caído
            en cuenta y que perpetúan las conductas machistas por el discurso
            endeble de “yo no veo desigualdades entre mujeres y hombres”. Los
            hombres tienen que dejar de pensar que esto es una competencia de a
            quiénes matan más o menos, o quiénes son más vulnerables que otros;
            la problemática tiene que observarse desde cómo puedo yo, hombre
            privilegiado, aportar al cambio sin necesidad de ser protagonista de
            la lucha. <br />
            <br />
            Una de las cosas que más me impactó durante el movimiento de
            #BlackLiveMatter, en numerosos estados de nuestro vecino del norte,
            fue cuando la población afroamericana y de color, llamaba a las
            personas blancas a sumarse a la lucha a través de sus privilegios,
            literalmente diciendo “su voz suena más fuerte que la nuestra” y eso
            es escalofriante porque reconoce una desigualdad sistémica, latente
            y actual. <br />
            <br />
            Ahora bien, si exportamos esa manera de cooperación entre los
            hombres y la lucha feminista, los hombres tenemos que actuar de la
            misma manera, pero únicamente en donde nos toca. Trabajarlo de
            hombre a hombre; si ya eres consciente de tus privilegios, puedes
            comenzar con acciones sencillas pero que empiecen a marcar la
            diferencia, como no participar ni reírte de chistes machistas, o
            señalar, e incluso confrontar, actitudes del mismo tipo. Salte del
            grupo de WhatsApp en donde se comparten imágenes de mujeres desnudas
            a las que seguramente no se les preguntó si sus fotografías íntimas
            podían ser difundidas y denuncia la situación. Si estás siendo
            testigo de un acoso callejero en contra de una mujer, evítalo sin
            buscar confrontaciones. <br />
            <br />
          </p>
          <p
            className="color_naranja texto_tamano"
            style={{ textAlign: "center" }}
          >
            <strong>
              ¡Menos “amiga date cuenta” y más “amigo no te comportes así”!
            </strong>
            <br />
            <br />
          </p>
        </Col>

        <Col span={20} offset={2}></Col>
      </Row>
    </>
  );
};
