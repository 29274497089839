import React, { useEffect } from 'react';
import { Switch, useLocation, Route, Router } from 'react-router-dom';
import {Element, scroller} from 'react-scroll';
import { Header } from './Header';
import { Footer } from './Footer';
import { Home } from '../home/Home';
import { Nosotros } from '../nosotros/Nosotros';
import { Servicios } from '../servicios/Servicios';
import { Cursos } from '../cursos/Cursos';
import { Noticias } from '../noticias/Noticias';
import { Publicaciones } from '../noticias/Publicaciones';
import { Contacto } from '../contacto/Contacto';
import { RouterPaginas } from '../../paginas/RouterPaginas';
import { CodigoConducta } from '../paginas/CodigoConducta';
import { Col, Row } from 'antd';
import { Clientes } from '../clientes/Clientes';
import { NuevosServicios } from '../servicios/NuevosServicios';

export const Master = () => {
    const location = useLocation();

    

    useEffect(() => {
        //if(location.hash === ''){
          //  scroller.scrollTo('header');
        //}else{
            console.log('ir');
            console.log(location.hash);
            scroller.scrollTo(location.hash);
        //}
        
    },[location]);

    return(
        <>
            <Header />
            <Row>
                <Col xs={0} md={24}>
                <br /><br /><br /><br /><br />
                </Col>
                <Col xs={24} md={0}>
                <br /><br />
                </Col>
            </Row>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/nosotros" component={Nosotros} />
                <Route exact path="/servicios" component={NuevosServicios} />
                {/*<Route path="/servicios/:tipo?/:nombre_servicio?" component={Servicios} />*/}
                <Route exact path="/cursos" component={Cursos} />
                {/*<Route exact path="/eventos" component={Noticias} />*/}
                <Route exact path="/blog" component={Publicaciones} />
                <Route exact path="/sobre-maf" component={Clientes} />
                <Route exact path="/contacto" component={Contacto} />
                <Route exact path="/codigodeconducta" component={CodigoConducta} />
                <Route exact path="*" component={RouterPaginas} />
                </Switch>
            <Footer />
            </>
    );
}
