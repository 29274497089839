import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from "react-router-dom";
import { Col, Row } from 'antd';
import { Focampo } from '../../firmas/api/Focampo';
import { Error404 } from '../../error/Error404';

export const MasterFirmas = () => {

    return(
        <>
            <Switch>
                <Route exact path="/firmas-api/focampo" component={Focampo} />
                <Route path="*" component={Error404} />
                </Switch>
            </>
    );
}
