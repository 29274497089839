import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { Element } from 'react-scroll';

import '../../../assets/css/sitio/home.css';

import img_transversal from '../../../assets/images/sitio/home/Transversalidad.png';
import img_transversal_movil from '../../../assets/images/sitio/home/Transversalidad_movil.png';
const { Title, Paragraph } = Typography;

export const Transversalidad = () => {


    return (
        <>
        <Row justify='center'>
            
            <Col id="transversalidad" span={20}>
                <Row>
                <Col xs={{span:0, offset:0}} sm={{span:24, offset:0}}><br /><br />
                        <p className="titulo_quienes_somos">GENERACIÓN DE ESTRATEGIAS Y SOLUCIONES 360º</p>
                </Col>
                <Col xs={{span:24, offset:0}} sm={{span:0, offset:0}}><br /><br />
                        <p className="titulo_quienes_somos_movil">GENERACIÓN DE ESTRATEGIAS Y SOLUCIONES 360º</p>
                </Col>
                
                </Row>
            </Col>
            <Col xs={0} md={20}>
                <img src={img_transversal} style={{width:'100%'}} />
            </Col>
            <Col xs={22} md={0}>
                <img src={img_transversal_movil} style={{width:'100%'}} />
            </Col>
        </Row>
        </>
    );
}
