import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Carousel, Typography, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const eventos = [
    {texto_naranja: '21 DE MAYO', texto_gris_oscuro:"Descubriendo cómo vivo mi masculinidad",texto_gris_claro:"Jueves - 18:00 h",url:"#"},
    {texto_naranja: '21 DE MAYO', texto_gris_oscuro:"Descubriendo cómo vivo mi masculinidad",texto_gris_claro:"Jueves - 18:00 h",url:"#"}
];



export const CarouselEventos = () => {

    const cambiarCarousel = React.createRef();

    let div_carosuel = eventos.map(evento => {
        return (
            <Col span={24} className="contenedor_servicio">
                <Paragraph className="evento_texto_naranja">
                    { evento.texto_naranja }
                </Paragraph>    
                <Paragraph className="evento_titulo_gris_oscuro">
                    { evento.texto_gris_oscuro }
                </Paragraph>
                <Paragraph className="evento_texto_gris_claro">
                    { evento.texto_gris_claro }
                </Paragraph>
                <Paragraph className="evento_boton_registro">
                    <Link to={evento.url} ><Button className="boton_registro_movil" >Registro</Button></Link>
                </Paragraph>
            </Col>
        );
    });



    return (
        <Row justify="space-around" align="middle">
            <Col span={3} style={{textAlign:'center'}}>
                <LeftOutlined className="flechas_cambiar_carousel_eventos" onClick={() => { cambiarCarousel.current.prev() }} />
            </Col>
            <Col span={18}>
                <Carousel autoplay dots={false} ref={cambiarCarousel}>
                    { div_carosuel }
                </Carousel>
            </Col>
            <Col span={3} style={{textAlign:'center'}}>
                <RightOutlined className="flechas_cambiar_carousel_eventos" onClick={() => { cambiarCarousel.current.next() }} />
            </Col>
        </Row>
    );

};