import React from 'react';
import { Link } from "react-router-dom";   
import { Row, Col } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_cliente_atlas from '../../../assets/images/sitio/home/clientes/Maf_ATLAS.jpg';
import img_cliente_antp from '../../../assets/images/sitio/home/clientes/Maf_ANTP.jpg';
import img_cliente_cdi from '../../../assets/images/sitio/home/clientes/Maf_CDI.jpg';
import img_cliente_cemex from '../../../assets/images/sitio/home/clientes/Maf_Cemex.jpg';
import img_cliente_cndh from '../../../assets/images/sitio/home/clientes/Maf_CNDH.jpg';
import img_cliente_pgr from '../../../assets/images/sitio/home/clientes/Maf_FGR.jpg';
import img_cliente_segob from '../../../assets/images/sitio/home/clientes/Maf_SEGOB.jpg';
import img_cliente_the_three from '../../../assets/images/sitio/home/clientes/Maf_TheThree.jpg';

import maf_01 from '../../../assets/images/sitio/clientes/maf-01.png';
import maf_02 from '../../../assets/images/sitio/clientes/maf-02.png';
import maf_03 from '../../../assets/images/sitio/clientes/maf-03.png';
import maf_04 from '../../../assets/images/sitio/clientes/maf-04.png';
import maf_05 from '../../../assets/images/sitio/clientes/maf-05.png';
import maf_06 from '../../../assets/images/sitio/clientes/maf-06.png';
import maf_07 from '../../../assets/images/sitio/clientes/maf-07.png';
import maf_08 from '../../../assets/images/sitio/clientes/maf-08.png';
import maf_09 from '../../../assets/images/sitio/clientes/maf-09.png';
import maf_10 from '../../../assets/images/sitio/clientes/maf-10.png';
import maf_11 from '../../../assets/images/sitio/clientes/maf-11.png';
import maf_12 from '../../../assets/images/sitio/clientes/maf-12.png';
import maf_13 from '../../../assets/images/sitio/clientes/maf-13.png';
import maf_14 from '../../../assets/images/sitio/clientes/maf-14.png';
import maf_15 from '../../../assets/images/sitio/clientes/maf-15.png';
import maf_16 from '../../../assets/images/sitio/clientes/maf-16.png';
import maf_17 from '../../../assets/images/sitio/clientes/maf-17.png';
import maf_18 from '../../../assets/images/sitio/clientes/maf-18.png';
import maf_19 from '../../../assets/images/sitio/clientes/maf-19.png';
import maf_20 from '../../../assets/images/sitio/clientes/maf-20.png';
import maf_21 from '../../../assets/images/sitio/clientes/maf-21.png';
import maf_22 from '../../../assets/images/sitio/clientes/maf-22.png';
import maf_23 from '../../../assets/images/sitio/clientes/maf-23.png';
import maf_24 from '../../../assets/images/sitio/clientes/maf-24.png';
import maf_25 from '../../../assets/images/sitio/clientes/maf-25.png';
import maf_26 from '../../../assets/images/sitio/clientes/maf-26.png';
import maf_27 from '../../../assets/images/sitio/clientes/maf-27.png';
import maf_28 from '../../../assets/images/sitio/clientes/maf-28.png';
import maf_29 from '../../../assets/images/sitio/clientes/maf-29.png';
import maf_30 from '../../../assets/images/sitio/clientes/maf-30.png';
import maf_31 from '../../../assets/images/sitio/clientes/maf-31.png';
import maf_32 from '../../../assets/images/sitio/clientes/maf-32.png';
import maf_33 from '../../../assets/images/sitio/clientes/maf-33.png';
import maf_34 from '../../../assets/images/sitio/clientes/maf-34.png';
import maf_35 from '../../../assets/images/sitio/clientes/maf-35.png';
import maf_36 from '../../../assets/images/sitio/clientes/maf-36.png';
import maf_37 from '../../../assets/images/sitio/clientes/maf-37.png';
import maf_38 from '../../../assets/images/sitio/clientes/maf-38.png';
import maf_39 from '../../../assets/images/sitio/clientes/maf-39.png';
import maf_40 from '../../../assets/images/sitio/clientes/maf-40.png';
import maf_41 from '../../../assets/images/sitio/clientes/maf-41.png';
import maf_42 from '../../../assets/images/sitio/clientes/maf-42.png';
import maf_43 from '../../../assets/images/sitio/clientes/maf-43.png';
import maf_44 from '../../../assets/images/sitio/clientes/maf-44.png';
import maf_45 from '../../../assets/images/sitio/clientes/maf-45.png';
import maf_46 from '../../../assets/images/sitio/clientes/maf-46.png';
import maf_47 from '../../../assets/images/sitio/clientes/maf-47.png';
import maf_48 from '../../../assets/images/sitio/clientes/maf-48.png';
import maf_49 from '../../../assets/images/sitio/clientes/maf-49.png';
import maf_50 from '../../../assets/images/sitio/clientes/maf-50.png';
import maf_51 from '../../../assets/images/sitio/clientes/maf-51.png';
import maf_52 from '../../../assets/images/sitio/clientes/maf-52.png';
import maf_53 from '../../../assets/images/sitio/clientes/maf-53.png';
import maf_54 from '../../../assets/images/sitio/clientes/maf-54.png';
import maf_55 from '../../../assets/images/sitio/clientes/maf-55.png';


let clientes = [
    { img_src: maf_01, nombre:''},
{ img_src: maf_02, nombre:''},
{ img_src: maf_03, nombre:''},
{ img_src: maf_04, nombre:''},
{ img_src: maf_05, nombre:''},
{ img_src: maf_06, nombre:''},
{ img_src: maf_07, nombre:''},
{ img_src: maf_08, nombre:''},
{ img_src: maf_09, nombre:''},
{ img_src: maf_10, nombre:''},
{ img_src: maf_11, nombre:''},
{ img_src: maf_12, nombre:''},
{ img_src: maf_13, nombre:''},
{ img_src: maf_14, nombre:''},
{ img_src: maf_15, nombre:''},
{ img_src: maf_16, nombre:''},
{ img_src: maf_17, nombre:''},
{ img_src: maf_18, nombre:''},
{ img_src: maf_19, nombre:''},
{ img_src: maf_20, nombre:''},
{ img_src: maf_21, nombre:''},
{ img_src: maf_22, nombre:''},
{ img_src: maf_23, nombre:''},
{ img_src: maf_24, nombre:''},
{ img_src: maf_25, nombre:''},
{ img_src: maf_26, nombre:''},
{ img_src: maf_27, nombre:''},
{ img_src: maf_28, nombre:''},
{ img_src: maf_29, nombre:''},
{ img_src: maf_30, nombre:''},
{ img_src: maf_31, nombre:''},
{ img_src: maf_32, nombre:''},
{ img_src: maf_33, nombre:''},
{ img_src: maf_34, nombre:''},
{ img_src: maf_35, nombre:''},
{ img_src: maf_36, nombre:''},
{ img_src: maf_37, nombre:''},
{ img_src: maf_38, nombre:''},
{ img_src: maf_39, nombre:''},
{ img_src: maf_40, nombre:''},
{ img_src: maf_41, nombre:''},
{ img_src: maf_42, nombre:''},
{ img_src: maf_43, nombre:''},
{ img_src: maf_44, nombre:''},
{ img_src: maf_45, nombre:''},
{ img_src: maf_46, nombre:''},
{ img_src: maf_47, nombre:''},
{ img_src: maf_48, nombre:''},
{ img_src: maf_49, nombre:''},
{ img_src: maf_50, nombre:''},
{ img_src: maf_51, nombre:''},
{ img_src: maf_52, nombre:''},
{ img_src: maf_53, nombre:''},
{ img_src: maf_54, nombre:''},
{ img_src: maf_55, nombre:''},
    
]

function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

export const CarouselClientes = (params) => {

    const { num_items } = params;


    {/*const settingsClientes = {
        className: "center",
            centerMode: true,
            infinite: true,
            slidesToShow: num_items,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 200,
            speed:2000
    };

clientes = shuffle(clientes);*/}

    const div_clientes = clientes.map((cliente,index) => {
        
        if(index % 5==0){
            return(
            <Col xs={8} md={{span:4,offset:2}}>
                <img src={cliente.img_src} alt={cliente.nombre} className="img_cliente" />
            </Col>
        )}else{
            return(
                <Col xs={8} md={{span:4,offset:0}}>
                    <img src={cliente.img_src} alt={cliente.nombre} className="img_cliente" />
                </Col>
            )
        }
    
    });

    return (
        <Row id="clientes">
            {div_clientes}
            <Col span={24}>
            <br /><br /><br /><br />
            </Col>
        </Row>
    );

};