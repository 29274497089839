import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Divider } from 'antd';

import '../../../assets/css/sitio/footer.css';

import icon_facebook from '../../../assets/images/iconos/facebook_BLANCO.png';
import icon_twitter from '../../../assets/images/iconos/twitter_BLANCO.png';
import icon_instagram from '../../../assets/images/iconos/instagra,_BLANCO.png';
import icon_youtube from '../../../assets/images/iconos/youtube_BLANCO.png';
import icon_linkedin from '../../../assets/images/iconos/linkedin_BLANCO.png';
import icon_mail from '../../../assets/images/iconos/email_BLANCO.png';

import logo_maf_color from '../../../assets/images/sitio/header/Maf_Logo_COLOR.png';
import logo_maf_blanco from '../../../assets/images/sitio/header/Maf_Logo_BLANCO.png';


const { Title, Paragraph } = Typography;

export const Footer = () => {

    return(
            <Row>
                <Col xs={24} sm={24} md={24}>
                    <Row align="top" className="contenedor_footer">
                        <Col xs={{ span:20, offset:2}} md={5} offset={2}>
                            <img src={logo_maf_color} className="logo_maf_color" alt="logo_maf" />
                        </Col>
                        <Col xs={0} sm={0} md={5}>
                            <Title className="titulo_seccion_footer" level={4}>Síguenos</Title>
                            <Paragraph className="texto_footer">
                                <a rel="noreferrer" href="https://www.facebook.com/mafyasociados" className="link_footer_red_social" target="_blank"><img src={icon_facebook} className="icono_red_social_footer" alt="facebook" /> /mafyasociados</a><br />
                                <a rel="noreferrer" href="https://twitter.com/MAFyAsociados" className="link_footer_red_social" target="_blank"><img src={icon_twitter} className="icono_red_social_footer" alt="twitter" /> /MAFyAsociados</a><br />
                                <a rel="noreferrer" href="https://www.instagram.com/mafyasociadosmx/" className="link_footer_red_social" target="_blank"><img src={icon_instagram} className="icono_red_social_footer" alt="instagram" /> /mafyasociadosmx</a><br />
                                <a rel="noreferrer" href="mailto:contacto@mafyasociados.com" className="link_footer_red_social" target="_blank"><img src={icon_mail} className="icono_red_social_footer" alt="mail" /> contacto@mafyasociados.com</a><br />
                                <a rel="noreferrer" href="https://www.youtube.com/channel/UC9DSPCkpfpgAQJWbuzLLMAQ/videos" className="link_footer_red_social" target="_blank"><img src={icon_youtube} className="icono_red_social_footer" alt="youtube" /> MAF &amp; Asociados</a><br />
                                <a rel="noreferrer" href="https://www.linkedin.com/company/mafyasociados/" className="link_footer_red_social" target="_blank"><img src={icon_linkedin} className="icono_red_social_footer" alt="mail" /> /mafyasociados</a><br />
                            </Paragraph>
                            
                        </Col>
                        <Col xs={{ span:20, offset:2}} md={5}>
                        <Title className="titulo_seccion_footer" level={4}>Contacto</Title>
                        <Paragraph className="texto_footer">
                        Av. Isaac Newton #232, Polanco V Sección, <br />Miguel Hidalgo, 11560, CDMX, México
                        </Paragraph>
                        </Col>
                        <Col xs={0} md={0}>
                            <Title className="titulo_seccion_footer" level={4}>Últimas noticias</Title>
                            <Row style={{display:'none'}}>
                                <Col spa={10} style={{backgroundColor:'white'}}>
                                    tetxt
                                </Col>
                                <Col spa={13} offset={1}>
                                <Paragraph>
                                    <Link to="#" className="texto_footer">
                                        lorem
                                    </Link>
                                </Paragraph>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span:20, offset:2}} md={20} offset={2}>
                            <Divider className="division_footer" />
                            <Paragraph className="texto_derechos_reservados">
                            © { new Date().getFullYear() } MAFR Consultoría Estratégica S.C. Todos los derechos reservados | <Link className="texto_derechos_reservados_link" to="/aviso-de-privacidad">Aviso de privacidad</Link>
                            </Paragraph>
                        </Col>
                    </Row>

                </Col>
            </Row>
    );
}
