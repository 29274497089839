import React from 'react';
import { Row, Col } from 'antd';
import Helmet from "react-helmet";
import '../../../assets/css/sitio/paginas.css';

export const CodigoConducta = () => {
    return(
        <>
        
        <Row >
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                
    <br />
    <h1 className="color_gris_claro titulo_24_c" ><strong>CÓDIGO DE CONDUCTA</strong></h1>
    <h2 className="color_gris_claro titulo_18_l" ><strong>Introducción</strong></h2>
    <p className="color_gris_claro texto_14_j">
      MAFR Consultoría Estratégica, Sociedad Civil, es una empresa especializada que se enfoca al diagnóstico, diseño, implementación y evaluación de estrategias integrales en materia empresarial, institucional, política, jurídica y legislativa, electoral, de comunicación y relaciones públicas para organismos gubernamentales, no gubernamentales e iniciativa privada, en su ámbito de competencia. 
      <br /><br />
      Nuestra empresa trabaja en beneficio de sus clientes, empleados y de la sociedad, realizando sus servicios con altos estándares de integridad y ética.
      <br /><br />
      La prestación de servicios con integridad, confidencialidad y ética, es un activo del cual el personal de MAFR Consultoría Estratégica está orgulloso. En consecuencia, existe un alto compromiso por velar que se lleva a cabalidad en la ejecución de cada uno de sus proyectos.
      <br /><br />
      En este sentido, el presente Código de Conducta es el instrumento que guía la actuación y conducta del personal de la empresa, otorgando plena certeza sobre el comportamiento profesional, responsable y ético al que deben sujetarse en el quehacer cotidiano.
      <br /><br />
      Establece las directrices de cómo tratar a nuestros clientes, proveedores, compañeros de trabajo, competidores y el público en general, de manera apropiada, con respeto e integridad. En él se prevé un marco de normas éticas, bajo las cuales todos los empleados deben conducirse y desempeñar su labor.
      <br /><br />
      Todos los que formamos parte de MAFR Consultoría Estratégica debemos adoptar lo establecido en el presente documento, con el propósito de que impere una conducta que fortalezca la misión y visión de este empresa y responda de manera eficaz y eficiente a las necesidades de nuestros clientes, socios, asociados y sociedad en general.
      <br /><br />
      Es importante conocer, entender y asimilar el presente Código de Conducta, ya que es la guía ética para dirigir y realizar las acciones diarias bajo los principios de calidad, servicio e integridad, así como para establecer un ambiente laboral digno y de cordialidad.
      <br /><br />
      Es importante señalar que en la redacción del documento no se hacen referencias o alusiones hacia un género, ya que se pretende sean acciones genéricas que deben atender hombres y mujeres por igual. 
      <br /><br />
      </p>
      <h2 className="color_gris_claro titulo_18_l" ><strong>Valores</strong></h2>
      <ul className="color_gris_claro texto_14_j">
        <li>Excelencia</li>
        <li>Legalidad</li>
        <li>Objetividad</li>
        <li>Profesionalismo</li>
        <li>Eficacia</li>
        <li>Eficiencia</li>
        <li>Confidencialidad</li>
        <li>Innovación</li>
        <li>Transparencia	</li>
        <li>Ética</li>
        <li>Respeto</li>
        <li>Honestidad</li>
        <li>Integridad</li>
        <li>Responsabilidad</li>
        <li>Compromiso</li>
        <li>Cooperación</li>
        <li>Igualdad y no discriminación</li>
      </ul>
      <h2 className="color_gris_claro titulo_18_l" ><strong>Principios básicos</strong></h2>
      <p className="color_gris_claro texto_14_j">
        Cualquier colaborador o integrante de MAFR CONSULTORÍA ESTRATÉGICA S.C., deben guiarse por los siguientes principios básicos:
        <ol type="I">
          <li>Alinearse siempre a la Política de Responsabilidad, al Código de Ética, así como a las demás políticas establecidas en MAFR CONSULTORÍA ESTRATÉGICA S.C.</li>
          <li>Evitar cualquier conducta que pueda dañar la reputación de nuestra firma o sus integrantes.</li>
          <li>Actuar de manera profesional, ética y legalmente ante cualquier circunstancia.</li>
          <li>Priorizar los derechos humanos en cualquiera de nuestra actividades.</li>
        </ol>
      </p>
      <h2 className="color_gris_claro titulo_18_l" ><strong>Cumplimiento de las Leyes, Normas y Regulaciones</strong></h2>
      <p className="color_gris_claro texto_14_j">
        MAFR CONSULTORÍA ESTRATÉGICA S.C está comprometida al cumplimiento de todas las leyes y regulaciones, así como la aplicación de las normas y regulaciones internas, según resulten aplicables a cada situación determinada.
      </p>
      <h2 className="color_gris_claro titulo_18_l" ><strong>Responsabilidad con la sociedad</strong></h2>
      <ul className="color_gris_claro texto_14_j">
        <li>Brindar un trato digno, cortés, cordial y sin discriminación a nuestros clientes.</li>
        <li>Proceder con honestidad, atendiendo siempre a la verdad. </li>
        <li>Fomentar una cultura de confianza con nuestros clientes.</li>
        <li>Cumplir con las leyes, reglamentos y normas sin distinción alguna</li>
        <li>Promover el respeto de los derechos humanos, y un trato sin discriminación.</li>
      </ul>
      <h2 className="color_gris_claro titulo_18_l" ><strong>Competencia</strong></h2>
      <ul className="color_gris_claro texto_14_j">
        <li>No utilizar información, documentos técnicos, modelos o material intelectual desarrollado por otro consultor, empresa o sociedad, sin su autorización expresa o reconocimiento. En caso de utilizarlos, siempre se deberá indicar la fuente de origen.</li>
        <li>En caso de que se solicite la revisión del trabajo realizado por otro consultor, empresa o sociedad, actuar de conformidad con estándares de integridad y objetividad.</li>
        <li>No tener actitudes, ni realizar actos que puedan dañar la reputación o el negocio de un tercero.</li>
        <li>No ofrecer dinero, beneficios, arreglos compensatorios o contribuciones, a cambio de asegurar la asignación de un trabajo.</li>
        </ul>
        <h2 className="color_gris_claro titulo_18_l" ><strong>Calidad de la Consultora</strong></h2>
          <ul className="color_gris_claro texto_14_j">
            <li>Desarrollo y evaluación de alternativas de solución a problemáticas específicas de los clientes.</li>
            <li>Aceptar únicamente aquellos estudios y proyectos, en las áreas de especialidad en las cuales este calificada la consultora.</li>
            <li>Proporcionar servicios de excelencia con pleno respeto a la ley.</li>
            <li>Actuar con estándares de eficiencia y eficacia. </li>
            <li>Desarrollar y dirigir todas las actividades con honestidad, integridad, claridad y una sólida actitud de servicio en beneficio de nuestros clientes.</li>
          </ul>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Objetividad en el Servicio</strong></h2>
          <ul className="color_gris_claro texto_14_j">
            <li>Elaborar juicios u opiniones con imparcialidad, tomando en cuenta sólo la realidad de los hechos y sin permitir que intervengan intereses personales.</li>
            <li>Mantener una estricta confidencialidad sobre la información recibida de nuestros clientes.</li>
            <li>Informar a nuestros clientes con antelación, de cualquier posible conflicto de interés que pueda surgir durante la prestación de los servicios.</li>
            <li>Brindar servicios con creatividad e innovación, encontrando siempre las mejores ideas y conceptos, ejecutándolas dentro de los cauces legales.</li>
          </ul>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Integridad del Consultor</strong></h2>
          <ul className="color_gris_claro texto_14_j">
            <li>Fomentar la innovación y las nuevas ideas, para mejorar el valor y desempeño de nuestros servicios.</li>
            <li>Llevar a cabo los servicios con integridad profesional.</li>
            <li>Considerar los aspectos técnicos, económicos y sociales de los proyectos, para hacer recomendaciones informadas e integrales a nuestros clientes.</li>
            <li>Contribuir al beneficio de la sociedad, mediante la investigación y desarrollo de conceptos, factibilidad, diseño y operación de procesos de gestión y operación de organizaciones institucionales, sociales y gubernamentales.</li>
            <li>Cumplir con dedicación empeño y profesionalismo las responsabilidades que han sido aceptadas.</li>
            <li>Evitar recibir remuneraciones o compensaciones que no estén establecidas dentro de los contratos de prestación de servicios de la Consultoría.</li>
            <li>Conducirse con respeto hacia los compañeros de trabajo, nuestros clientes y toda persona, tanto al interior como al exterior de nuestras instalaciones.</li>
            <li>En el ejercicio de sus servicios, dar un trato digno y si discriminación a clientes, compañeros y sociedad en general.</li>
          </ul>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Prevención de Fraudes</strong></h2>
          <ul className="color_gris_claro texto_14_j">
            <li>Evitar tomar sin autorización dinero en efectivo, artículos de inventario u otros activos.</li>
            <li>Evitar recibir dinero o regalos de proveedores a cambio de otorgarles un negocio.</li>
            <li>Cancelar activos o deudas recuperables.</li>
            <li>Usar los activos de la Consultora para beneficio personal.</li>
            <li>Efectuar entradas no válidas en el sistema contable.</li>
            <li>Autorizar o recibir pagos por artículos no recibidos o servicios no prestados. </li>
            <li>Falsificar documentos o usar documentos con conocimiento de su falsedad.</li>
            <li>Mentir o emitir información o datos falsos.</li>
          </ul>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Conflicto de intereses</strong></h2>
          <p className="color_gris_claro texto_14_j">
            Se produce cuando algún miembro de la Consultora, de manera independiente, no esté de acuerdo con las normas y políticas de la firma o cliente, en este caso; el colaborador deberá ser transparente y darlo a conocer por escrito a la brevedad a su superior inmediato, lo anterior con el fin de transparentar la situación, y aplicar las acciones contenedoras para no afectar ningún interés involucrado entre colaborador, firma, clientes y/o instituciones implicadas.
          </p>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Equidad de Género y Discriminación</strong></h2>
          <p className="color_gris_claro texto_14_j">
            MAFR CONSULTORÍA ESTRATÉGICA S.C., tiene el compromiso de promover la igualdad de oportunidades entre hombres y mujeres, permitir el acceso al empleo sin discriminación, así como establecer acciones a fin de prevenir el hostigamiento, acoso y/o la violencia laboral o cualquier otra conducta inapropiada, fomentando un espacio sano en condiciones de trabajo armoniosas. En este contexto, se promueven y desarrollan acciones permanentes y sustentables, orientadas a disminuir las brechas de género para alcanzar la igualdad sustantiva entre mujeres y hombres al igual que la no discriminación, la no violencia, que para nosotros son principios, compromisos y mandatos de ley, que hemos adoptado como ejes transversales del trato en nuestra firma. No toleramos ninguna forma de discriminación ni acoso en el lugar de trabajo.
          </p>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Herramientas de trabajo y su uso</strong></h2>
          <p className="color_gris_claro texto_14_j">
            MAFR CONSULTORÍA ESTRATÉGICA S.C., fomentamos el buen uso de instalaciones y herramientas de trabajo para garantizar su preservación, por lo que:
            <ul>
              <li>El personal deberá hacerse responsable de su área de trabajo; la cual se compromete a mostrar siempre limpia y ordenada.</li>
              <li>Sólo está permitido la ingesta de agua, café y algún refrigerio en el lugar de trabajo, siempre y cuando este último no ponga en riesgo su área laboral y la información relacionada con sus actividades.</li>
              <li>No se permitirá ingerir bebidas alcohólicas dentro de las áreas de trabajo, a excepción o con motivo de alguna celebración o evento conmemorativo siempre y cuando no se haga en horario de labores y cuidando en todo momento la mesura y responsabilidad requeridas, previa autorización de la Directora General de la Firma.</li>
              <li>El personal deberá siempre hacerse responsable de la iluminación y fuente de energía de su lugar de trabajo, las cuales deberán desconectarse al no usarla.</li>
              <li>Antes de retirarse de las áreas comunes el personal deberá verificar el apagado de luz, equipo de cómputo, impresión y aire acondicionado.</li>
              <li>En el área de impresión y copias, el personal deberá hacerse responsable de retirar su documentación al igual que la basura que se genere. Está prohibido imprimir documentos innecesarios, así como documentos que por su extensión aumentan el desgaste de los equipos de impresión.</li>
              <li>Cuando se deje de utilizar la sala de juntas, el personal deberá asegurarse que las sillas están en su lugar y los interruptores eléctricos se encuentran apagados, así como limpiar este espacio.</li>
              <li>Restituir los materiales no utilizados y conservar en buen estado los materiales e instrumentos proporcionados, no siendo responsable por el deterioro por el tiempo y uso, pero sí por el mal uso y descuido.</li>
              <li>Abstenerse de utilizar materiales, equipo y herramientas de trabajo para uso distinto de aquel que estén destinados.</li>
              <li>Los contenedores de alimentos deberán ser retirados después de su hora de comida y no podrán almacenarse más de 24 horas en el refrigerador.</li>
              <li>El horno de microondas deberá ser utilizado únicamente para contendedores plásticos.</li>
              <li>En general, el mobiliario deberá cuidarse en su totalidad.</li>
            </ul>
          </p>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Herramientas de comunicación electrónicas</strong></h2>
          <p className="color_gris_claro texto_14_j">
            Las herramientas de comunicación electrónicas abarcarán los teléfonos propios de la oficina, sus computadoras, teléfonos móviles o celulares propiedad de la Consultora, dispositivos de almacenamiento de información, incluidos los accesorios, sistemas de correo electrónico y sistemas de intranet e internet.
            <ul>
              <li>Las herramientas de comunicación electrónica son propiedad de MAFR CONSULTORÍA ESTRATÉGICA S.C., incluyendo la información contenida en ellas.</li>
              <li>Las herramientas de comunicación electrónica deben utilizarse para fines relacionados con los servicios y actividades que realiza MAFR CONSULTORÍA ESTRATÉGICA S.C.</li>
              <li>El personal con acceso a herramientas de comunicación electrónica debe utilizarlas con el debido cuidado y asegurarse de que no sufren daños o se extravíen.</li>
              <li>El personal con acceso a herramientas de comunicación electrónica no está autorizado a instalar ni utilizar ningún sistema o herramienta de MAFR CONSULTORÍA ESTRATÉGICA S.C., hardware o software no aprobado expresamente por la Administración.</li>
            </ul>
            </p>
            <h2 className="color_gris_claro titulo_18_l" ><strong>Vestimenta</strong></h2>
            <p className="color_gris_claro texto_14_j">
            En MAFR CONSULTORÍA ESTRATÉGICA S.C., cuidamos nuestro aspecto por lo que, otra parte importante de la imagen, es la presencia de nuestros colaboradores hacia el exterior, por ello adoptamos los siguientes lineamientos:
            <br /><br />
            Todo el personal se deberá presentar a laborar en horario de oficina con vestimenta ejecutiva EVITANDO VESTIR:
            <ol type="1">
              <li>Pantalón de mezclilla, jeans o pantalones con ese corte.</li>
              <li>Tenis y zapatos tipo tenis, lo anterior salvo alguna prescripción médica.</li>
              <li>Pantalón deportivo tipo pants.</li>
              <li>Pantaloncillos cortos (shorts/bermudas).</li>
              <li>Licras, leggings o similares.</li>
              <li>Camisas o blusas ombligueras y strapless o con tirantes.</li>
              <li>Camisas deportivas y camisas de manga corta, salvo autorización expresa de la Administración.</li>
              <li>Camisas de publicidad política.</li>
              <li>Sandalias.</li>
              <li>Gorras o sombreros.</li>
              <li>Todo el personal deberá cuidar su aseo personal como: baño diario, uñas arregladas, zapatos limpios.</li>
              <li>Evitar siempre prendas sucias, manchadas o arrugadas.</li>
              <li>El personal masculino deberá siempre mostrar su bigote o barba arreglada.</li>
              <li>El personal masculino de lunes a jueves deberá acudir con traje completo en colores serios y los días viernes con vestimenta formal (no pantalón de mezclilla).</li>
              <li>El personal femenino deberá presentarse con vestimenta ejecutiva, salvo los días viernes en los que se permite una vestimenta formal (no pantalón de mezclilla).</li>
              <li>Todo el personal deberá evitar tatuajes visibles y aretes tipo percing en las partes visibles del cuerpo.</li>
            </ol>
          </p>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Vigencia</strong></h2>
          <ul className="color_gris_claro texto_14_j">
            <li>La consultora deberá cumplir con los principios éticos descritos en el presente Código, con la finalidad de que su actuación se desarrolle en un marco de valores.</li>
            <li>El presente Código, está vigente a partir del primero de enero de 2020, y se puede encontrar en el portal de internet de MAFR CONSULTORÍA ESTRATÉGICA, SOCIEDAD CIVIL.</li>
          </ul>
          <h2 className="color_gris_claro titulo_18_l" ><strong>Denuncias y Quejas</strong></h2>
          <ul className="color_gris_claro texto_14_j">
            <li>Está a disposición de todo el personal de MAFR CONSULTORÍA ESTRATÉGICA, SOCIEDAD CIVIL un buzón para dar a conocer cualquier queja, inquietud o bien cualquier actividad, que de buena fe, considera se vulnera el Código de Conducta o cualquier otra Política.</li>
            <li>El personal que notifica de buena fe una posible infracción actúa correctamente y en línea con los valores de MAFR CONSULTORÍA ESTRATÉGICA, SOCIEDAD CIVIL y nuestros principios básicos.</li>
            <li>MAFR CONSULTORÍA ESTRATÉGICA, SOCIEDAD CIVIL no tolerará ninguna represalia contra empleados que hayan notificado de buena fe una posible infracción.</li>
          </ul>
          <p>
            <br /><br /><br />
          </p>
            </Col>
        </Row>
        </>
    );
}