import React from 'react';
import { Row, Col } from 'antd';
import Helmet from "react-helmet";

import img_src_header from '../../../assets/images/paginas/protege_tus_datos/protegedatos_2.jpg';
import img_1 from '../../../assets/images/paginas/protege_tus_datos/protege_tus_datos_1.png';
import img_2 from '../../../assets/images/paginas/protege_tus_datos/protege_tus_datos_2.png';
import img_3 from '../../../assets/images/paginas/protege_tus_datos/protege_tus_datos_3.png';

import archivo from '../../../assets/archivos/Propuesta_de_Amparo_Datos_Personales_MAF_y_Asociados.pdf';
import { Link } from 'react-router-dom';

export const Amparo = () => {
    return(
        <>
        <Helmet>
        <meta name="description" content=" Frente a la violación de derechos fundamentales de la ciudadanía ante las disposiciones disposiciones de la Ley Federal de Telecomunicaciones en materia de datos" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.mafyasociados.com/protege-tus-datos-personales" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="" />
        <meta name="author" content="" />
        <meta name="keywords" content="Ley Federal de Telecomunicaciones, datos biométricos, biométricos, datos personales, asesoría jurídica, demanda de amparo, juicio en línea, MAF y Asociados, " />
        <meta property="og:title" content="PROTEGE TUS DATOS PERSONALES "/>
        <meta property="og:description" content=" Frente a la violación de derechos fundamentales de la ciudadanía ante las disposiciones disposiciones de la Ley Federal de Telecomunicaciones en materia de datos" />
        <meta property="og:url" content="https://www.mafyasociados.com/protege-tus-datos-personales" />
        <meta property="og:site_name" content="MAF & Asociados" />
        <meta property="og:image" content="https://mafyasociados.com/archivos/vistas/protegetusdatos.png" />
        <meta property="og:image:secure_url" content="https://mafyasociados.com/archivos/vistas/protegetusdatos.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content=" Frente a la violación de derechos fundamentales de la ciudadanía ante las disposiciones disposiciones de la Ley Federal de Telecomunicaciones en materia de datos" />
        <meta name="twitter:title" content="PROTEGE TUS DATOS PERSONALES. " />
        <meta name="twitter:image" content="https://mafyasociados.com/archivos/vistas/protegetusdatos.png" />
      </Helmet>
        <Row >
            <Col span={24}>
                <img alt="articulo amparo" src={img_src_header} style={{width:'100%'}} />
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                
    <br />
    <h4 className="color_gris_claro titulo_tamano_2" ><strong>Reforma a la Ley Federal de Telecomunicaciones, en materia de datos biométricos</strong></h4>

    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    Como es de su conocimiento el pasado 16 de abril, se publicó el Decreto mediante el cual se reforman y adicionan diversas disposiciones de la <strong>Ley Federal de Telecomunicaciones, en materia de datos biométricos</strong>. Lo anterior ha sido considerado un acto violatorio de derechos fundamentales de la ciudadanía. 
    <br />
    <br />
    Frente a ello, ponemos a su disposición una propuesta de <strong>demanda de amparo</strong>. Es importante reiterar que <strong>el plazo para su presentación es este 31 de mayo de 2021.</strong>

    </p>
    <h4 className="color_gris_claro titulo_tamano" ><strong>¿Cómo vulnera sus derechos la reforma a la Ley Federal de Telecomunicaciones?</strong></h4>
    
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    El decreto <strong>obliga a registrar los datos biométricos</strong> a los titulares de las líneas telefónicas celulares, so pena de cancelar la línea y dejar de acceder a los beneficios de la misma. Además, <strong>otorga facultades a las compañías telefónicas privadas y al Instituto Federal de Telecomunicaciones, para recabar, obtener e ingresar los datos personales (biométricos)</strong> al Padrón Nacional de Usuarios de Telefonía Móvil (PNUTM).
      </p>
        
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Así mismo, determina que los concesionarios de telecomunicaciones y, en su caso, los autorizados, deberán recabar e ingresar la información sobre la identidad, datos biométricos y domicilio del usuario, así como proporcionar la información con la cual se integrará el PNUTM.
        </p>
        
        <h4 className="color_gris_claro titulo_tamano" ><strong>¿Cómo realizar la demanda de amparo?</strong></h4>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Descargue el <Link target="_blank" to={archivo} download>formato de amparo</Link> para la protección de sus datos personales que le ofrecemos donde deben incluirse los siguientes datos: 
        <ul>
          <li>nombre completo,</li>
          <li>domicilio,</li>
          <li>número telefónico,</li>
          <li>nombre de la compañía telefónica en la que se encuentre registrado,</li>
          <li>domicilio de la compañía telefónica,</li>
          <li>un estado de cuenta de teléfono o en su caso el contrato.</li>
        </ul>
        </p>
        <h4 className="color_gris_claro titulo_tamano" ><strong>¿Dónde se presenta?</strong></h4>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        La demanda de amparo se puede presentar de manera presencial en los Juzgados de Distrito, que se ubican en Anillo Perif. 1950, Tlacopac, Álvaro Obregón, en la Ciudad de México, CDMX o puede hacerse el <a href="https://www.serviciosenlinea.pjf.gob.mx/juicioenlinea" target="_blank">trámite en línea</a> en <a style={{wordBreak:'break-word'}} href="https://www.serviciosenlinea.pjf.gob.mx/juicioenlinea" target="_blank">https://www.serviciosenlinea.pjf.gob.mx/juicioenlinea</a> acompañado de su firma electrónica.
        </p>
        <p style={{textAlign:'center'}}>
          <br />
          <Link to={archivo}target="_blank"  className="btn_descargar_articulo" download>DESCARGA EL DOCUMENTO</Link>
          <br /><br />
        </p>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Para cualquier duda, aclaración o apoyo necesario nos encontramos a sus órdenes en los números telefónicos: (55) 7825 1014 al 17 y correo electrónico: <a href="mailto:contacto@mafyasociados.com" target='_blank'>contacto@mafyasociados.com</a>
        </p>
        <p>
        <br /><br /><br />
        </p>
                <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify", display:'none' }}
          >
              <hr style={{ marginLeft: '20px', marginRight: '20px' }} />
              <br /><br />
              <small>
                  <sup><strong>1</strong></sup><a href="https://centralelectoral.ine.mx/2020/12/31/recibe-ine-lista-de-entidades-donde-los-partidos-politicos-nacionales-pretenden-postular-a-candidatas-a-la-gubernatura/" target="_blank" className="link_sin_decoracion"> Lista de Entidades donde los Partidos Políticos Nacionales pretenden postular a candidatas a la gubernatura. Instituto Nacional Electoral. </a>
                  <br />
                  <sup><strong>2</strong></sup><a href="https://dof.gob.mx/nota_detalle.php?codigo=5591565&fecha=13/04/2020" target="_blank" className="link_sin_decoracion"> Ley General de Acceso de las Mujeres a Una Vida Libre de Violencia. Diario Oficial de la Federación, Abril de 2020.</a>
                  <br />
                  <sup><strong>3</strong></sup> Protocolo para la atención de la violencia política contra las mujeres en razón de género. Secretaría de Gobernación, et. al., pp. 56.<a href="" target="_blank" className="link_sin_decoracion"></a>
                  <br />
                  <sup><strong>4</strong></sup><a href="https://www.cndh.org.mx/sites/all/doc/OtrosDocumentos/Doc_2018_056.pdf" target="_blank" className="link_sin_decoracion"> Violencia política contra las mujeres en razón de género. Comisión Nacional de los Derechos Humanos. </a>
                  <br />
              </small>
          </p>
            </Col>
           
        </Row>
        </>
    );
}