import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Notas } from './Notas';
import { CardsWebinars } from './CardsWebinars';

import img_src_principal from '../../../assets/images/sitio/noticias/Maf_13Webinars.jpg'

export const Noticias = () => {

    const [categoria, setCategoria] = useState('webinars');
    const [texto_categoria, setTextoCategoria] = useState('Webinars');

      const handleMenuClick = (key) =>{
        setCategoria(key.key);
        setTextoCategoria(key.item.props.texto);
      }

      const menu_categorias = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="responsabilidad_social" texto="Responsabilidad Social" >Responsabilidad Social</Menu.Item>
          <Menu.Item key="tips_empresariales" texto="Tips Empresariales" >Tips Empresariales</Menu.Item>
          <Menu.Item key="violencia_de_genero" texto="Violencia de Género" >Violencia de Género</Menu.Item>
          <Menu.Item key="webinars" texto="Webinars" >Webinars</Menu.Item>
        </Menu>
      );

    return (
        <Row>
            
            <Col span={24}>
                <Link to="/la-ciberseguridad-como-elemento-esencial-para-el-exito-de-una-empresa"><img alt="Noticia principal" src={img_src_principal} className="img_principal_noticias" /></Link>
            </Col>
            <Col xs={{ span: 18, offset: 3 }} sm={{ span: 16, offset: 4 }} md={{ span: 0, offset: 0 }}>
                <Row justify="space-around" align="middle">
                    <Col span={8} style={{textAlign:'left'}}>
                    <Dropdown overlay={menu_categorias}>
      <Button>
        Categorias <DownOutlined />
      </Button>
    </Dropdown>
                    </Col>
                    <Col span={16}>
    <p class="titulo_opcion_categoria">{texto_categoria}</p>
                    </Col>
                </Row>
            
            </Col>
            <Col xs={{span:0, offset:0}} md={{span:24, offset:0}} className="contenedor_seccion_categorias"></Col>
            <Col span={24}>
            <Row>
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 14, offset: 2 }} >
                <CardsWebinars categoria={categoria} />
            </Col>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 5, offset: 1 }}>
                <Row>
                    <Col className="contenedor_categorias" span={24}>
                        <p className="titulo_categorias">Categorías</p>
                        <p className="opciones_categoria">
                            
                            <span className={categoria === 'webinars' ? 'opcion_categoria_activa' : 'opcion_categoria'} onClick={() => setCategoria('webinars')}>Webinars</span><br />
                        </p>
                    </Col>
                   
                </Row>

            </Col>
            </Row>
            </Col>
            
            <Col span={24}>
              <br /><br />
            </Col>
        </Row>
    );

}