import React from 'react';
import { Row, Col } from 'antd';

export const RacismoEnMexico = () => {
    return(
        <Row >
            <Col xs={{span:0, offset:0}} md={{span:20, offset:2}}>
                <br />
                <p className="color_naranja" style={{fontSize:'30px', textAlign:'center'}}>
                "EL RACISMO EN MÉXICO; TAN ARRAIGADO COMO SU HISTORIA MISMA"
                </p>
                <p className="color_gris_claro" style={{fontSize:'20px', textAlign:'justify'}}>
                Por Evelyn Esquivel

Los movimientos sociales en torno al racismo que actualmente están aconteciendo en el mundo, surgen como un reclamo de los actos de superioridad falaces y sin sustento que no tienen otro fin más que limitar la libertad de las personas, su desarrollo, derechos e incluso su propia vida. Tales situaciones han hecho reflexionar sobre la importancia del señalamiento sobre el racismo que existe en nuestro país y que, desafortunadamente, se ha interiorizado en gran parte de la población desde hace ya mucho tiempo, resaltando las diferencias raciales existentes, que si bien no son biológicas, son parte de estereotipos históricamente culturales que tienden a menoscabar a ciertos integrantes de la sociedad.
<br /><br />
Racismo es un término muy usado pero poco conocido. Entender el trasfondo de lo que conlleva la palabra en sí es regresar a la historia antigua y a la de nuestro país, considerando que la discriminación que vive gran parte de nuestra sociedad se debe, principalmente, a la desigualdad social ya existente, lo que provoca la exclusión de ciertos grupos para acceder a las mismas oportunidades.
<br /><br />
En este sentido, el Consejo para Prevenir y Eliminar la Discriminación de la Ciudad de México, define al racismo como: el odio, rechazo o exclusión de una persona por su raza, color de piel, origen étnico o su lengua, que le impide el goce de sus derechos humanos. Es originado por un sentimiento irracional de superioridad de una persona sobre otra. Evidenciando así, un cúmulo de acciones que, en la actualidad seguimos encontrando reflejadas en nuestra sociedad, enfrentándonos con ello, a lo que comúnmente se conoce como implicit bias, que se refiere a la preferencia o aversión que cada individuo lleva a cabo de manera inadvertida, hacia una persona o un grupo de personas debido a las características o condiciones personales que les caracterizan; lo que provoca que sean incluidas o excluidas de los distintos sectores de la sociedad presentes en nuestros país, provocando la falta de oportunidades y la estigmatización de grupos vulnerables principalmente por razón de su etnicidad, arreglo personal o características físicas como su tono de piel o rasgos fisiológicos.
<br /><br />
Si observamos a nuestro alrededor, podemos darnos cuenta de que gran parte de la sociedad mexicana sigue manejándose por construcciones ideológicas de corte histórico y social que han generado prejuicios hacia aquellas personas que no cumplen con un determinado estándar físico, social o económico; dando como resultado la vulneración de sus derechos humanos, a través de actitudes discriminatorias, burlas e insultos, con la intención de menospreciar o causar una afectación personal.
<br /><br />
Uno de los grupos sociales que se ha visto mayormente vulnerado ante dichos estándares impuestos, es la población indígena. La diversidad de la que goza nuestro país, lo convierte en una de las naciones pluriculturales más grandes del mundo. Dicha pluriculturalidad se sustenta en nuestros pueblos indígenas, pues en total México cuenta con 70 grupos étnicos distribuidos a lo largo de todo el territorio nacional y con más de 85 lenguas indígenas activas actualmente.[1]
<br /><br />
Lo anterior refleja que nuestra cultura, históricamente, se ha conformado y sigue conformándose por distintos grupos étnicos; dicho sea de paso, las cifras reflejan la realidad de una sociedad que discrimina a sus integrantes. La Encuesta Nacional Sobre Discriminación 2017[2] del INEGI, sostiene que el 40.3% de la población indígena, que representa el 21.5% de la población total mexicana[3], ha sido discriminada en servicios médicos, en la calle, el transporte público y la familia. La discriminación que sufren de manera sistemática no solo responde a la creación de determinados estándares, sino también a la desigualdad de oportunidades que se remarca desde ámbitos básicos como el acceso a servicios esenciales como agua potable, vivienda, alimentación y educación, lo que conlleva una reducción de sus expectativas laborales, económicas, personales e incluso de su propia vida.
<br /><br />
Si bien el racismo está presente en otros países, en México su forma de expresión es diferente, pues como se puede observar, en nuestro caso, éste deviene de una enseñanza y formación cultural que se vincula a la procedencia de cada persona marcada por su contexto inmediato social, familiar y educativo lo que va caracterizando el trato hacia otras personas, generando prejuicios, respecto de las personas a las que se refieren, sobre determinadas características como pobreza, delincuencia, falta de educación, entre otros que no tienen otro fin más que denostar o derruir su dignidad humana; estas acciones, entonces, visibilizan que cuando una persona no encaja en los falaces estándares erróneamente determinados para cada ámbito, surgen los señalamientos y la asignación de términos peyorativos que atacan su integridad.
<br /><br />
Por tanto, en estos tiempos donde el racismo está siendo, una vez más, expuesto en todos los ámbitos en los que la sociedad se desenvuelve, es momento de comenzar a hablar y destacar los problemas que predominan en nuestro contexto social, pues éstos han permanecido a lo largo de la historia como un estigma que se trata de invisibilizar o del que simplemente no se habla lo suficiente para poder lograr un cambio significativo.
<br /><br />
Recordar que, como seres humanos habitantes de esta nación, la historia que nos precede es y seguirá siendo la misma, es precisamente aceptar que nuestra procedencia, el color de nuestra piel, la lengua que hablamos, el origen étnico del que provenimos o cualquier otra característica que nos identifica como mexicanas y mexicanos, no nos hace ni más ni menos de la persona que tenemos al lado, y mucho menos nos permite incluir o excluir a las personas de las diversas oportunidades sociales o económicas a las que pudiesen acceder. Hagamos conciencia, como integrantes de la sociedad, que una de nuestras tareas principales es construir un futuro en donde ninguna característica inherente a una persona, sea motivo para señalarla, limitar sus derechos o recibir un trato diferenciado, solo así, construiremos una sociedad más responsable, equitativa, con mayores oportunidades para todas y todos y mucha más empatía.
<br /><br />
<small>
[1] Atlas de los pueblos indígenas de México. Comisión Nacional para el Desarrollo de los Pueblos indígenas. <a target="_blank" href="http://atlas.cdi.gob.mx/?page_id=67" className="link_sin_decoracion">http://atlas.cdi.gob.mx/?page_id=67</a><br />
[2] Encuesta Nacional Sobre Discriminación. Instituto Nacional Sobre Estadística y Geografía, 2017. <a href="https://www.inegi.org.mx/contenidos/programas/enadis/2017/doc/enadis2017_resultados.pdf" className="link_sin_decoracion" target="_blank">https://www.inegi.org.mx/contenidos/programas/enadis/2017/doc/enadis2017_resultados.pdf</a><br />
[3] Boletín CONAMED, ops. <a href="http://www.conamed.gob.mx/gobmx/boletin/pdf/boletin13/poblacion_indigena.pdf" className="link_sin_decoracion" target="_blank">http://www.conamed.gob.mx/gobmx/boletin/pdf/boletin13/poblacion_indigena.pdf</a>
</small>
<br /><br />
                </p>
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:0, offset:0}}>
            <br />
                <p className="color_naranja" style={{fontSize:'20px', textAlign:'center'}}>
                "EL RACISMO EN MÉXICO; TAN ARRAIGADO COMO SU HISTORIA MISMA"
                </p>
                <p className="color_gris_claro" style={{fontSize:'16px', textAlign:'justify'}}>
                Por Evelyn Esquivel

Los movimientos sociales en torno al racismo que actualmente están aconteciendo en el mundo, surgen como un reclamo de los actos de superioridad falaces y sin sustento que no tienen otro fin más que limitar la libertad de las personas, su desarrollo, derechos e incluso su propia vida. Tales situaciones han hecho reflexionar sobre la importancia del señalamiento sobre el racismo que existe en nuestro país y que, desafortunadamente, se ha interiorizado en gran parte de la población desde hace ya mucho tiempo, resaltando las diferencias raciales existentes, que si bien no son biológicas, son parte de estereotipos históricamente culturales que tienden a menoscabar a ciertos integrantes de la sociedad.
<br /><br />
Racismo es un término muy usado pero poco conocido. Entender el trasfondo de lo que conlleva la palabra en sí es regresar a la historia antigua y a la de nuestro país, considerando que la discriminación que vive gran parte de nuestra sociedad se debe, principalmente, a la desigualdad social ya existente, lo que provoca la exclusión de ciertos grupos para acceder a las mismas oportunidades.
<br /><br />
En este sentido, el Consejo para Prevenir y Eliminar la Discriminación de la Ciudad de México, define al racismo como: el odio, rechazo o exclusión de una persona por su raza, color de piel, origen étnico o su lengua, que le impide el goce de sus derechos humanos. Es originado por un sentimiento irracional de superioridad de una persona sobre otra. Evidenciando así, un cúmulo de acciones que, en la actualidad seguimos encontrando reflejadas en nuestra sociedad, enfrentándonos con ello, a lo que comúnmente se conoce como implicit bias, que se refiere a la preferencia o aversión que cada individuo lleva a cabo de manera inadvertida, hacia una persona o un grupo de personas debido a las características o condiciones personales que les caracterizan; lo que provoca que sean incluidas o excluidas de los distintos sectores de la sociedad presentes en nuestros país, provocando la falta de oportunidades y la estigmatización de grupos vulnerables principalmente por razón de su etnicidad, arreglo personal o características físicas como su tono de piel o rasgos fisiológicos.
<br /><br />
Si observamos a nuestro alrededor, podemos darnos cuenta de que gran parte de la sociedad mexicana sigue manejándose por construcciones ideológicas de corte histórico y social que han generado prejuicios hacia aquellas personas que no cumplen con un determinado estándar físico, social o económico; dando como resultado la vulneración de sus derechos humanos, a través de actitudes discriminatorias, burlas e insultos, con la intención de menospreciar o causar una afectación personal.
<br /><br />
Uno de los grupos sociales que se ha visto mayormente vulnerado ante dichos estándares impuestos, es la población indígena. La diversidad de la que goza nuestro país, lo convierte en una de las naciones pluriculturales más grandes del mundo. Dicha pluriculturalidad se sustenta en nuestros pueblos indígenas, pues en total México cuenta con 70 grupos étnicos distribuidos a lo largo de todo el territorio nacional y con más de 85 lenguas indígenas activas actualmente.[1]
<br /><br />
Lo anterior refleja que nuestra cultura, históricamente, se ha conformado y sigue conformándose por distintos grupos étnicos; dicho sea de paso, las cifras reflejan la realidad de una sociedad que discrimina a sus integrantes. La Encuesta Nacional Sobre Discriminación 2017[2] del INEGI, sostiene que el 40.3% de la población indígena, que representa el 21.5% de la población total mexicana[3], ha sido discriminada en servicios médicos, en la calle, el transporte público y la familia. La discriminación que sufren de manera sistemática no solo responde a la creación de determinados estándares, sino también a la desigualdad de oportunidades que se remarca desde ámbitos básicos como el acceso a servicios esenciales como agua potable, vivienda, alimentación y educación, lo que conlleva una reducción de sus expectativas laborales, económicas, personales e incluso de su propia vida.
<br /><br />
Si bien el racismo está presente en otros países, en México su forma de expresión es diferente, pues como se puede observar, en nuestro caso, éste deviene de una enseñanza y formación cultural que se vincula a la procedencia de cada persona marcada por su contexto inmediato social, familiar y educativo lo que va caracterizando el trato hacia otras personas, generando prejuicios, respecto de las personas a las que se refieren, sobre determinadas características como pobreza, delincuencia, falta de educación, entre otros que no tienen otro fin más que denostar o derruir su dignidad humana; estas acciones, entonces, visibilizan que cuando una persona no encaja en los falaces estándares erróneamente determinados para cada ámbito, surgen los señalamientos y la asignación de términos peyorativos que atacan su integridad.
<br /><br />
Por tanto, en estos tiempos donde el racismo está siendo, una vez más, expuesto en todos los ámbitos en los que la sociedad se desenvuelve, es momento de comenzar a hablar y destacar los problemas que predominan en nuestro contexto social, pues éstos han permanecido a lo largo de la historia como un estigma que se trata de invisibilizar o del que simplemente no se habla lo suficiente para poder lograr un cambio significativo.
<br /><br />
Recordar que, como seres humanos habitantes de esta nación, la historia que nos precede es y seguirá siendo la misma, es precisamente aceptar que nuestra procedencia, el color de nuestra piel, la lengua que hablamos, el origen étnico del que provenimos o cualquier otra característica que nos identifica como mexicanas y mexicanos, no nos hace ni más ni menos de la persona que tenemos al lado, y mucho menos nos permite incluir o excluir a las personas de las diversas oportunidades sociales o económicas a las que pudiesen acceder. Hagamos conciencia, como integrantes de la sociedad, que una de nuestras tareas principales es construir un futuro en donde ninguna característica inherente a una persona, sea motivo para señalarla, limitar sus derechos o recibir un trato diferenciado, solo así, construiremos una sociedad más responsable, equitativa, con mayores oportunidades para todas y todos y mucha más empatía.
<br /><br />
<small>
[1] Atlas de los pueblos indígenas de México. Comisión Nacional para el Desarrollo de los Pueblos indígenas. <a target="_blank" href="http://atlas.cdi.gob.mx/?page_id=67" className="link_sin_decoracion">http://atlas.cdi.gob.mx/?page_id=67</a><br />
[2] Encuesta Nacional Sobre Discriminación. Instituto Nacional Sobre Estadística y Geografía, 2017. <a href="https://www.inegi.org.mx/contenidos/programas/enadis/2017/doc/enadis2017_resultados.pdf" className="link_sin_decoracion" target="_blank">https://www.inegi.org.mx/contenidos/programas/enadis/2017/doc/enadis2017_resultados.pdf</a><br />
[3] Boletín CONAMED, ops. <a href="http://www.conamed.gob.mx/gobmx/boletin/pdf/boletin13/poblacion_indigena.pdf" className="link_sin_decoracion" target="_blank">http://www.conamed.gob.mx/gobmx/boletin/pdf/boletin13/poblacion_indigena.pdf</a>
</small>
<br /><br />
                </p>
            </Col>
        </Row>
    );
}