import React from 'react';
import { Row, Col } from 'antd';

import img_src_header from '../../../assets/images/paginas/contenidos_con_proposito/Maf_ContenidoConProposito.jpg';
import img_src_roles from '../../../assets/images/paginas/roles_genero/ejemplo_roles_genero.jpg';

export const ContenidosConProposito = () => {
    return(
        <Row >
            <Col span={24}>
                <img alt="Articulo Contenidos con proposito" src={img_src_header} style={{width:'100%'}} />
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                
    <br />
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    Por Evelyn Esquivel
    <br /><br />
    En Sesión Ordinaria de la Cámara de Diputados del pasado 3 de febrero de 2021, se aprobó por mayoría de 444 votos a favor, la adición de diversas disposiciones a la Ley General de Acceso de las Mujeres a una Vida Libre de Violencia (LGAMVLV), con la finalidad de incluir los conceptos de violencia simbólica y violencia mediática.
    <br /><br />
    </p>
    <h4 className="color_gris_claro texto_tamano" ><strong>La violencia simbólica</strong></h4>
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    La noción de violencia simbólica nace como consecuencia del análisis realizado por Pierre Bordieu, destacado sociólogo francés en la época contemporánea. Él decía que el poder masculino se manifestaba en maneras no siempre expresivas sino simbólicas, indicando que <em>“la violencia simbólica es esa violencia que arranca sumisiones que ni siquiera se perciben como tales y se apoyan en expectativas colectivas”</em><sup>1</sup> , es decir, que la dominación masculina y la forma en la que se ha ido construyendo dentro de la sociedad, ejerce violencia sobre sus víctimas, pasando inadvertida para las mismas a consecuencia de haberlo normalizado con el tiempo.
    <br /><br />
    En razón de lo anterior, esta adición a la LGAMVLV, define la violencia simbólica como la <em>expresión, emisión o difusión por cualquier medio, ya sea en el ámbito público o privado, de discursos, mensajes, patrones estereotipados, signos, valores icónicos e ideas que transmiten, reproducen, justifican o naturalizan la subordinación, desigualdad, discriminación y violencia contra las mujeres en la sociedad</em>.<sup>2</sup>
    <br /><br />
    Probablemente nos esté costando trabajo entender el significado de lo que es la violencia simbólica y es comprensible, en tanto que, como decía Bordieu, las víctimas no se percatan de que ciertas palabras, eventos o imágenes son violentas porque, a lo largo del tiempo, las identificamos como cotidianas. Algunos ejemplos de la violencia simbólica son, anuncios de películas o novelas en donde quien está siendo atacada por una figura masculina es la mujer; las palabras altisonantes que hacen referencia a la mujer y no al hombre; el lenguaje o palabras que tienen un contenido machista e incluso racista; limitar la vestimenta de las mujeres o la propia condición de ser mujer; los certámenes de belleza en donde el cuerpo, la inteligencia y el físico de una mujer son merecedoras de una calificación como si estas características se excluyeran unas de otras.
        </p>
        <h4 className="color_gris_claro texto_tamano" ><strong>La violencia mediática</strong></h4>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Por otro lado, tenemos la violencia mediática, la cual se adiciona a la LGAMVLV como <em>“la expresión a través de cualquier medio de comunicación de contenidos que, de manera directa o indirecta, promuevan estereotipos de género, así como la humillación, explotación, degradación, desigualdad, discriminación o cualquier forma de violencia contra las mujeres”</em>.<sup>3</sup>
        <br /><br />
Mientras que la violencia simbólica está implícita y pasa inadvertida, la violencia mediática atiende a aquella difusión de contenidos que reproducen estereotipos de género en donde encontramos una sobrerrepresentación de la mujer como un personaje secundario, siempre opacado por el enaltecimiento del sexo masculino y el poder que se le ha otorgado, llevando a las mujeres a una cosificación para el consumo de los hombres. 
<br /><br />
Un ejemplo de este tipo de violencia son los comerciales de detergentes. Detente un poco y piensa en los anuncios de estos y muchos más productos, ¿quién es la protagonista? ¿hay alguno que tenga un hombre en ellos? ¿difícil verdad? En todos ellos, la protagonista es una mujer y esta imagen perpetua estereotipos de género donde a la mujer le corresponde las labores del hogar.
<br /><br />
Si observamos con atención, nos percataremos de que se nos bombardea de contenidos en donde prolifera la violencia contra las mujeres y que, tristemente, hemos normalizado, sin embargo, es tarea de todas y todos comenzar a exigir contenidos con perspectiva de género, cuyo mensaje aporte algo relevante a la sociedad pues el alcance que tienen los medios de comunicación es gigantesco.
        </p>
        <h4 className="color_gris_claro texto_tamano" ><strong>La Ley Ingrid</strong></h4>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        El 14 de febrero de 2020, la Fiscal de la Ciudad de México, Ernestina Godoy, presentó ante el congreso capitalino una propuesta que sugiere modificar lo establecido en el artículo 293 del Código Penal para la Ciudad de México, con el fin de sancionar a los servidores públicos que difundan de manera indebida contenidos sensibles en imágenes o videograbaciones que se relacionen directamente con un hecho delictivo.
        <br /><br />
En Sesión Ordinaria del citado congreso, el pasado 23 de febrero del 2021, se  aprobó la adición del artículo 293 QUÁTER al citado código, con el que se pretende sancionar <em>“… al que difunda, entregue, revele, publique, transmita, exponga, remita, distribuya, videograbe, audiograbe, fotografíe, filme, reproduzca, comercialice, oferte, intercambie o comparta imágenes, audios, videos o documentos del lugar de los hechos o del hallazgo, indicios, evidencias, objetos o instrumentos relacionados con el procedimiento penal o producto de un hecho delictivo…”</em>.<sup>4</sup>
<br /><br />
Lo anterior se reflejará en los periódicos que publican contenidos altamente sensibles con titulares irónicos que llegan a ser ofensivos. La implicación de estas publicaciones va más allá de lo que vemos impreso en una hoja de papel o difundido en otro medio de comunicación, pues lo que en realidad representa es un atentado contra la dignidad de las víctimas directas y/o indirectas del hecho que se está publicando.
<br /><br />
El conjunto de todo lo anteriormente descrito, nos lleva a una sola conclusión, necesitamos exigir contenidos audiovisuales que estén dotados de perspectiva de género, que derruyan todo lo previamente construido con base en estereotipos y que tengan el compromiso de crear contenidos con propósito que verdaderamente aporten a construir una sociedad igualitaria y libre de estereotipos.
        </p>
        <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify" }}
          >
              <hr style={{ marginLeft: '20px', marginRight: '20px' }} />
              <br /><br />
              <small>
                  <sup><strong>1</strong></sup><a href="http://www.enlinea.cij.gob.mx/Cursos/Hospitalizacion/pdf/PierreBourdieu.pdf" target="_blank" className="link_sin_decoracion"> La noción de la violencia simbólica en la obra de Pierre Bordieu: una aproximación crítica. J. Manuel Fernández. Universidad Complutense de Madrid, 2005. </a>
                  <br />
                  <sup><strong>2</strong></sup><a href="https://comunicacionnoticias.diputados.gob.mx/comunicacion/index.php/boletines/aprueba-camara-de-diputados-reformas-a-la-ley-general-de-acceso-de-las-mujeres-a-una-vida-libre-de-violencia#gsc.tab=0" target="_blank" className="link_sin_decoracion"> Aprueba Cámara de Diputados reformas a la Ley General de Acceso de las Mujeres a una Vida Libre de Violencia. Cámara de Diputados. Boletín No. 5802. Ciudad de México, 03 de febrero 2021. </a>
                  <br />
                  <sup><strong>3</strong></sup> Idem.<a href="" target="_blank" className="link_sin_decoracion"></a>
                  <br />
                  <sup><strong>4</strong></sup><a href="https://www.congresocdmx.gob.mx/media/documentos/84e579bc4dd108c43dee8215c73263f868433fbd.pdf" target="_blank" className="link_sin_decoracion"> Gaceta parlamentaria. Congreso de la Ciudad de México, I legislatura. Ciudad de México, No. 517, p.p. 783. </a>
                  <br />
              </small>
          </p>
            </Col>
           
        </Row>
    );
}