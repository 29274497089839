import React from 'react';
import { Row, Col } from 'antd';
import Helmet from "react-helmet";

import img_src_header from '../../../assets/images/paginas/representacion_femenina/representacionfemenina_780.jpg';

export const RepresentacionFemenina = () => {
    return(
        <>
        <Helmet>
        <meta name="description" content=" Ya nos encontramos en vísperas de tiempos electorales, puesto que el próximo 6 de junio del año en curso," />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.mafyasociados.com/la-representacion-femenina-en-materia-electoral-y-la-violencia-politica" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Evelyn Esquivel. " />
        <meta name="author" content="Evelyn Esquivel. " />
        <meta property="og:title" content="LA REPRESENTACIÓN FEMENINA EN MATERIA ELECTORAL Y LA VIOLENCIA POLÍTICA" />
        <meta property="og:description" content=" Ya nos encontramos en vísperas de tiempos electorales," />
        <meta property="og:url" content="https://www.mafyasociados.com/la-representacion-femenina-en-materia-electoral-y-la-violencia-politica" />
        <meta property="og:site_name" content="MAF & Asociados" />
        <meta property="og:image" content="https://mafyasociados.com/archivos/vistas/representacionfemenina_780.png" />
        <meta property="og:image:secure_url" content="https://mafyasociados.com/archivos/vistas/representacionfemenina_780.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content=" Ya nos encontramos en vísperas de tiempos electorales," />
        <meta name="twitter:title" content="LA REPRESENTACIÓN FEMENINA EN MATERIA ELECTORAL Y LA VIOLENCIA POLÍTICA" />
        <meta name="twitter:image" content="https://mafyasociados.com/archivos/vistas/representacionfemenina_780.png" />
      </Helmet>
        <Row >
            <Col span={24}>
                <img alt="Articulo Representación femenina" src={img_src_header} style={{width:'100%'}} />
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                
    <br />
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    Por Evelyn Esquivel
    <br /><br />
    Ya nos encontramos en vísperas de tiempos electorales, puesto que el próximo 6 de junio del año en curso, se llevarán a cabo los comicios más grandes en toda la historia del país, en los que se elegirán más de 20 mil cargos de elección popular y participarán, aproximadamente, 94.9 millones de personas que ejercerán su derecho a votar.
    <br /><br />
    El pasado 6 de noviembre del 2020, el Instituto Nacional Electoral (INE) aprobó los criterios sobre paridad de género que serán aplicables en estas elecciones, que establecen que los partidos políticos deben de postular 50% de candidatas mujeres que competirán por la gubernatura de los 15 estados en los que se encuentra en juego dicho cargo de elección popular.
    <br /><br />
    Según datos del propio INE, y de acuerdo a la lista de entidades federativas en las que postularán mujeres para candidatas a las gubernaturas, suman 13 estados de la República donde las mujeres formarán parte de las boletas electorales. Dichos estados son: Baja California, Baja California Sur, Campeche, Chihuahua, Colima, Guerrero, Nayarit, Nuevo León, Querétaro, San Luis Potosí, Sinaloa, Tlaxcala y Zacatecas.<sup>1</sup>
    <br /><br />
    No obstante lo anterior y, pese a los avances que se han logrado en materia de paridad de género, el ámbito político-electoral es una de las áreas en donde la violencia contra las mujeres también tiene una expresión claramente definida y de recién inclusión a la Ley General de Acceso de las Mujeres a una Vida Libre de Violencia (Ley General de Acceso). A todo esto, ¿sabes qué es la violencia política?

    <br /><br />
    </p>
    <h4 className="color_gris_claro texto_tamano" ><strong>Violencia política en razón de género</strong></h4>
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    La violencia política en razón de género es una de las varias modalidades en las que se expresa la violencia contra las mujeres y se encuentra definida en la Ley General de Acceso como: <em>“toda acción u omisión, incluida la tolerancia, basada en elementos de género y ejercida dentro de la esfera pública o privada, que tenga por objeto o resultado limitar, anular o menoscabar el ejercicio efectivo de los derechos políticos y electorales de una o varias mujeres, el acceso al pleno ejercicio de las atribuciones inherentes a su cargo, labor o actividad, el libre desarrollo de la función pública, la toma de decisiones, la libertad de organización, así como el acceso y ejercicio a las prerrogativas, tratándose de precandidaturas, candidaturas, funciones o cargos públicos del mismo tipo.”</em><sup>2</sup>  Asimismo, este tipo de violencia tiene diversas manifestaciones de la conducta que se pretende sancionar, sin embargo, algunos de los ejemplos más representativos de la violencia política son los siguientes<sup>3</sup>:

    <ul>
        <li>Que causen la muerte de la mujer por participar en la política (feminicidio).</li>
        <li>Que restrinjan o anulen el derecho al voto libre y secreto de las mujeres.</li>
        <li>Que difamen, calumnien, injurien o realicen cualquier expresión que denigre a las mujeres en el ejercicio de sus funciones políticas, con base en estereotipos de género, cuyo objetivo o resultado sea el de menoscabar su imagen pública y/o limitar sus derechos políticos.</li>
        <li>Que limiten o nieguen arbitrariamente el uso de cualquier recurso y/o atribución inherente al cargo político que ocupa una mujer, impidiendo el ejercicio del cargo en condiciones de igualdad.</li>
    </ul>
        </p>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        La violencia política en razón de género solo está tipificada en los códigos penales de 9 estados de la República, siendo estos el Estado de México, Guanajuato, Morelos, Nuevo León, Oaxaca, Quintana Roo, Sonora, Veracruz y Zacatecas. Lo anterior, refleja que la mayoría de las conductas de la violencia política de género, no están reconocidas ni sancionadas por los códigos penales de cada entidad y en algunos casos, tampoco es sancionable por vías electoral ni administrativa.
        <br /><br />
        Afortunadamente, con el paso del tiempo y como respuesta a las necesidades de la sociedad en materia de violencia de género, se han creado diversos instrumentos de atención, canalización, procedimientos e instituciones que brindan contención emocional y que ponen a disposición de las mujeres, diversos servicios a fin de poder prevenir, atender y sancionar la violencia política contra las mujeres, estos son<sup>4</sup>:
        </p>
        <h4 className="color_gris_claro texto_tamano" ><strong>Instituciones de atención</strong></h4>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        <ul>
            <li>Instituto Nacional Electoral (Unidad Técnica de lo Contencioso Electoral).</li>
            <li>Fiscalía Especializada para la Atención de Delitos Electorales (FEPADE),</li>
            <li>Tribunal Electoral del Poder Judicial de la Federación.</li>
            <li>Subsecretaría de Derechos Humanos, Población y Migración de la Secretaría de Gobernación (SEGOB).</li>
            <li>Comisión Ejecutiva de Atención a Víctimas (CEAV).</li>
            <li>Comisión Nacional de los Derechos Humanos (CNDH).</li>

        </ul>
        Por todo lo anterior, es necesario ponerle un alto a la violencia contra las mujeres en todos los ámbitos de la sociedad y poder asegurar condiciones óptimas donde las mujeres tengan espacios de liderazgo y cargos de decisión, derruyendo estereotipos de género y recordando siempre la importancia de tener representación femenina en altos cargos de elección popular, pues solamente así alcanzaremos la sociedad igualitaria que necesitamos, para construir e impulsar políticas públicas creadas por ellas y para todas.
        </p>
        <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify" }}
          >
              <hr style={{ marginLeft: '20px', marginRight: '20px' }} />
              <br /><br />
              <small>
                  <sup><strong>1</strong></sup><a href="https://centralelectoral.ine.mx/2020/12/31/recibe-ine-lista-de-entidades-donde-los-partidos-politicos-nacionales-pretenden-postular-a-candidatas-a-la-gubernatura/" target="_blank" className="link_sin_decoracion"> Lista de Entidades donde los Partidos Políticos Nacionales pretenden postular a candidatas a la gubernatura. Instituto Nacional Electoral. </a>
                  <br />
                  <sup><strong>2</strong></sup><a href="https://dof.gob.mx/nota_detalle.php?codigo=5591565&fecha=13/04/2020" target="_blank" className="link_sin_decoracion"> Ley General de Acceso de las Mujeres a Una Vida Libre de Violencia. Diario Oficial de la Federación, Abril de 2020.</a>
                  <br />
                  <sup><strong>3</strong></sup> Protocolo para la atención de la violencia política contra las mujeres en razón de género. Secretaría de Gobernación, et. al., pp. 56.<a href="" target="_blank" className="link_sin_decoracion"></a>
                  <br />
                  <sup><strong>4</strong></sup><a href="https://www.cndh.org.mx/sites/all/doc/OtrosDocumentos/Doc_2018_056.pdf" target="_blank" className="link_sin_decoracion"> Violencia política contra las mujeres en razón de género. Comisión Nacional de los Derechos Humanos. </a>
                  <br />
              </small>
          </p>
            </Col>
           
        </Row>
        </>
    );
}