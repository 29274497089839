import React from 'react';
import { Row, Col } from 'antd';

export const HomeOffice2 = () => {
    return(
        <Row >
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                <br />
                <p className="color_naranja titulo_tamano" style={{textAlign:'center'}}>Home Office 2.0. Planeación y Organización en la Nueva Normalidad</p>
                <iframe width="100%" height="550" src="https://www.youtube.com/embed/ajzOnejnhAo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <br /><br />
            </Col>
        </Row>
    );
}