import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import src_img_logo from './assets/images/sitio/header/Maf_Logo_COLOR.png';
import 'antd/dist/antd.css';

import './App.css';

import { Master } from './components/sitio/master/Master';
import { MasterFirmas } from './components/sitio/master/MasterFirmas';

function App() {
  return (
   <div>
     <img src={src_img_logo} alt="Maf_Logo_COLOR" style={{display:'none'}} />
      <Router>
      <Switch>
        <Route path="/">
          <Master />
        </Route>
        <Route path="/">
          <MasterFirmas />
        </Route>
      </Switch>
    </Router>
   </div>
    

  );
}

export default App;
