import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Carousel, Typography, Divider, Modal } from 'antd';
import { CaretLeftFilled, CaretRightFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';
import Slider from 'react-slick';

import img_asesoria_juridica from '../../../assets/images/sitio/servicios/consultoriaempresarial.jpg';
import img_derechos_empresas from '../../../assets/images/sitio/servicios/asuntoscorporativos.jpg';
import img_acompanamiento from '../../../assets/images/sitio/servicios/asesoriajuridica_maf.jpg';
import img_proteccion_datos from '../../../assets/images/sitio/servicios/servicios_protecciondatospersonales.jpg';
import img_latirgo_estrategico from '../../../assets/images/sitio/servicios/otros_servicios.jpg';
import img_energia from '../../../assets/images/sitio/servicios/servicios_energia.jpg';
import img_telecomunicaciones from '../../../assets/images/sitio/servicios/servicios_telecomunicaciones.jpg';
import img_transporte_carga from '../../../assets/images/sitio/servicios/servicios_autotransporte.jpg';
import img_aseria_ambiental from '../../../assets/images/sitio/servicios/servicios_medioambiente.jpg';
import img_contrataciones from '../../../assets/images/sitio/servicios/auditorias.jpg';
import img_estrategia_seguridad from '../../../assets/images/sitio/servicios/servicios_estrategiaseguridad_justicia.jpg';
import img_capacitacion from '../../../assets/images/sitio/servicios/Maf_EstrategiaDeSeguridadYJusticia_Home.jpg';
import img_planeacion from '../../../assets/images/sitio/servicios/planeacion.jpg';
import img_home from '../../../assets/images/sitio/servicios/Maf_PlaneacionYReintegracionDeProcesos_Home.jpg';
import img_regulacion_sanitaria from '../../../assets/images/sitio/servicios/servicios_regulacionSanitaria.jpg';
import img_material_electoral from '../../../assets/images/sitio/servicios/servicos_electoral.jpg';


const { Paragraph } = Typography;

const servicios = [
    { index:1, nombre: 'FORTALECIMIENTO DE RESPONSABILIDAD SOCIAL EMPRESARIAL', url: "/servicios/", id:'#responsabilidad-social', img_src: img_asesoria_juridica, texto: [<><ul><li>Integramos sistemas de gestión de Responsabilidad Social corporativas a través de la creación e integración de un órgano gestor, promotor y de liderazgo, definido como Comité de RSE. </li></ul></>] },
    { index:2, nombre: 'DERECHOS DE EMPRESA Y PRÁCTICAS SOCIETARIAS', url: "/servicios/", id:"#", img_src: img_derechos_empresas, texto: [<><ul><li>Las empresas son fuentes de trabajo que se encuentran inmersas en su operatividad con una serie de riesgos, por lo cual requieren protección jurídica, MAF es una firma experta para otorgar asesoría en:<ul><li>Asesorías Legales, mercantil, civil, fiscal y penal.</li>
    <li>Estrategias Financieras. </li>
    <li>Elaboración de contratos y convenios civiles, mercantiles y atípicos.</li>
    <li>Modelos de cumplimiento de ley (Compliance).</li>
    <li>Elaboración y análisis de diversos documentos organizacionales (actas constitutivas, de asambleas, etc.)</li>
    <li>Políticas internas.</li>
    <li>Reingeniería en procesos</li>
    </ul></li></ul></>] },
    { index:3, nombre: 'ACOMPAÑAMIENTO LEGISLATIVO Y CABILDEO', url: "/servicios/", id:'#', img_src: img_acompanamiento, texto: [<><ul><li>Evaluamos de manera permanente el entorno legislativo, político y social para conocer la situación parlamentaria sobre temas de interés para nuestros clientes. En este sentido, nos especializamos en: <ul><li>Desarrollo de estrategias de cabildeo para impulso, seguimiento o contención de iniciativas en el ámbito Local y Federal.</li>
    <li>Emisión de opiniones sobre el impacto y alcance jurídico de disposiciones regulatorias.</li>
    <li>Emisión de opiniones sobre el impacto y alcance jurídico de disposiciones regulatorias.</li>
    <li>Elaboración de iniciativas en el ámbito federal o local.</li>
    <li>Monitoreo de iniciativas en el ámbito local y federal.</li>
    </ul></li></ul></>] },
    { index:4, nombre: 'PROTECCIÓN DE DATOS PERSONALES Y SEGURIDAD DE LA INFORMACIÓN', url: "/servicios/", id:'#', img_src: img_proteccion_datos, texto: [<><ul><li>Asesoría integral para el cumplimiento de las obligaciones de Protección de Datos Personales y Protección de la Información.</li>
    <li>Elaboración de Avisos de Privacidad.</li>
    <li>Asesoría para atender solicitudes de Derechos ARCO (son los derechos de Acceso, Rectificación Cancelación y Oposición, que el titular de los datos personales puede ejercer donde den tratamiento a sus datos personales).</li>
    </ul></>] },
    { index:5, nombre: 'LITIGIO ESTRATÉGICO, ADMINISTRATIVO Y FISCAL', url: "/servicios/", id:'#', img_src: img_latirgo_estrategico, texto: [<><ul><li>Brindamos asesoría integral a nuestros clientes frente a los actos de las autoridades administrativas. (IMSS, PROFECO, INFONAVIT, SCT, Estatales o municipales), a través de los siguientes servicios:<ul>
        <li>Estrategias de acompañamiento, litigio e impulso de diversos medios de impugnación, tales como: Recursos Administrativos, Juicios de Nulidad y Amparos.	</li>
<li>Representación y asistencia personalizada ante las autoridades durante los procedimientos administrativos o jurisdiccionales ya sea ante autoridades Federales o Estatales, así como los procedimientos 	jurisdiccionales.</li>
</ul></li></ul></>] },
    { index:6, nombre: 'ENERGÍA', url: "/servicios/", id:'#', img_src: img_energia, texto: [<><ul><li>Asuntos Regulatorios.</li>
    <li>Diseño de soluciones integrales de acuerdo al tipo de proyecto. </li>
    <li>Apoyo técnico en proyectos energéticos en México.</li>
    <li>Desarrollo de proyectos de energía renovable, eficiencia energética y energía no renovable.</li>
    </ul></>] },
    { index:7, nombre: 'TELECOMUNICACIONES', url: "/servicios/", id:'#', img_src: img_telecomunicaciones, texto: [<><ul><li>Asesoría técnico - jurídica en materia de política pública y regulación regulatoria de telecomunicaciones o radiodifusión.</li>
    <li>Acompañamiento en procedimientos administrativos seguidos ante el Instituto Federal de Telecomunicaciones.</li>
    <li>Defensa legal ante las autoridades competentes en materia de telecomunicaciones y radiodifusión.</li>
    <li>Asesoría técnico - jurídica en materia de Competencia Económica en los sectores de telecomunicaciones o radiodifusión.</li>
    <li>Asesoría en el diseño, implementación y operación de proyectos técnicos en telecomunicaciones y radiodifusión.</li>
    </ul></>] },
    { index:8, nombre: 'TRANSPORTES DE CARGA', url: "/servicios/", id:'#', img_src: img_transporte_carga, texto: [<><ul><li>Ofrecemos servicios de asesoría y acompañamiento en asuntos jurídicos, de regulación normativa, capacitación y operación logística:<ul>
        <li>Asesoría jurídica, fiscal y administrativa para el cumplimiento de la regulación en materia de autotransporte.</li>
<li>Acompañamiento en la implementación del CFDI y el complemento Carta Porte desde el punto de vista del autotransportista o usuario.</li>
<li>Orientación y acompañamiento en la recuperación de unidades y mercancías detenidas.</li>
<li>Asesoría y seguimiento en denuncias de robo al autotransporte de carga, bodegas o centro de distribución (CEDIS).</li>
<li>Tácticas corporativas sobre Responsabilidad Social Empresarial (RSE).</li>
<li>Consultoría en aplicación eficiente de recursos utilizados en las operaciones de transporte y logística.</li>
<li>Enlace con autoridades federales, estatales y municipales, así como, con cámaras y asociaciones de los diferentes modos de transporte.</li>
<li>Capacitamos de manera integral a directivos, administradores y operadores en materia de seguridad vial y prevención del delito, contando con una Plataforma de Aprendizaje en Línea.</li>
<li>En colaboración con la Asociación Nacional de Transporte Privado (ANTP) brindamos capacitación y evaluación a conductores a través de nuestro Simulador de Manejo Móvil: TrackSIM, para fortalecer la cultura y educación vial en el país</li>
<li>Desarrollo documental de: Manuales de organización y procedimientos, códigos de conducta, sistemas de denuncia, control y auditoría.</li>
</ul></li></ul></>] },
    { index:9, nombre: 'ASESORÍA EN MATERIA AMBIENTAL', url: "/servicios/", id:'#', img_src: img_aseria_ambiental, texto: [<><ul><li>Amplia experiencia en el sector de regulación medioambiental.<ul>
        <li>Asesoría técnico-jurídica en materia de política pública, cumplimiento regulatorio y operativo de la normatividad medioambiental en materia Federal, Estatal y Municipal.</li>
<li>Acompañamiento en procedimientos administrativos seguidos ante Las Procuradurías Estatales del Medio Ambiente.</li>
<li>Defensa legal ante las Procuradurías Estatales del Medio Ambiente y a nivel federal. </li>
<li>Auditoría Medioambiental.</li>
<li>Trámites especializados para la obtención de los permisos necesarios en materia ambiental, a nivel federal, estatal y municipal</li>
</ul></li></ul></>] },
    { index:10, nombre: 'CONTRATACIONES PÚBLICAS Y AUDITORÍAS', url: "/servicios/", id:'#', img_src: img_contrataciones, texto: [<><ul><li>Para el Sector Público<ul>
        <li>Blindar expedientes de proyectos o procedimientos de contratación dada la relevancia mediática o social.</li>
<li>Realizar auditorías en tiempo real a fin de detectar “focos rojos” y disminuir el riesgo de observaciones, en el momento mismo en que se desarrolla el proyecto.</li>
<li>Diseñar controles que permitan administrar riesgos que se detecten durante el desarrollo de procedimientos administrativos.</li>
</ul></li>
<li>Para el Sector Privado<ul>
<li>Acompañamiento durante la generación de propuestas en procedimientos de contratación, incluyendo el uso de la plataforma Compranet. </li>
<li>Auditoría de cumplimiento que garantice la correcta integración de expedientes que se deben generar, producto de la administración de contratos formalizados con dependencias o entidades del sector público.</li>
</ul></li></ul></>] },
    { index:11, nombre: 'ESTRATEGIA DE SEGURIDAD Y JUSTICIA', url: "/servicios/", id:'#', img_src: img_estrategia_seguridad, texto: [<><ul><li>○	Diseño e implementación de Programas de Prevención Social de la violencia y el delito, con participación ciudadana.<ul>
        <li>Análisis para la Identificación de factores de riesgo.</li>
<li>Diagnósticos situacionales.</li>
<li>Planes de acción que contemplan resultados a corto, mediano y largo plazo.</li>
<li>Identificación de programas y recursos alineados a la consecución de metas establecidas en los planes de desarrollo gubernamentales. </li>
<li>Generación y fortalecimiento de políticas públicas.</li>
<li>Actividades con la finalidad de impulsar la regeneración del tejido social.</li>
<li>Estrategias de comunicación.</li>
</ul></li></ul></>] },
    { index:12, nombre: 'CAPACITACIÓN Y PROFESIONALIZACIÓN', url: "/servicios/", id:'#', img_src: img_capacitacion, texto: [<><ul><li>Hemos desarrollado una metodología de Enseñanza-Aprendizaje con carácter andragógico. A través de la participación activa de los asistentes, se generará un proceso de elementos teóricos.
        <ul><li>Contamos con Programas de Capacitación para el Sector Público y Privado, a la medida de las necesidades de nuestros clientes, implementados por docentes especializados, con amplia experiencia y reconocimiento.</li></ul></li>
        <li>Ofrecemos tres modalidades de capacitación<ul>
        <li>Presencial</li>
<li>En línea</li>
<li>Mixta</li>
</ul></li></ul></>] },
    { index:13, nombre: 'EVALUACIÓN DE PROGRAMAS SOCIALES', url: "/servicios/", id:'#', img_src: img_planeacion, texto: [<><ul><li>Sólida experiencia en la evaluación de políticas Públicas para:<ul>
        <li>Medir la eficiencia y eficacia de las acciones implementadas.</li>
<li>Mejorar la transparencia y rendición de cuentas ante la sociedad.</li>
<li>Mejora continua de las acciones políticas y programas.</li>
<li>Orientar el gasto público de forma eficiente.</li>
</ul></li></ul></>] },
    { index:14, nombre: 'DIAGNÓSTICO INSTITUCIONAL Y REESTRUCTURACIÓN ORGANIZACIONAL, SEGURIDAD PÚBLICA Y FISCALÍAS', url: "/servicios/", id:'#', img_src: img_home, texto: [<><ul><li>Analizar y establecer las necesidades de la Instituciones de Seguridad Pública y Procuración de Justicia en alineación con las leyes vigentes, procesos y procedimientos.</li>
    <li>Establecer una operación funcional, especializada y con suficiencia de personal, por medio del conocimiento de los requerimientos de recursos humanos, capacitación e infraestructura tecnológica que se requiere para brindar servicios eficientes para el ejercicio de sus competencias.</li>
    </ul></>] },
    { index:15, nombre: 'REGULACIÓN SANITARIA', url: "/servicios/", id:'#', img_src: img_regulacion_sanitaria, texto: [<><ul><li>Consulta de clasificación de alimentos, suplementos alimenticios y cosméticos.</li>
    <li>Certificados de uso de Suelo.</li>
    <li>Licencias sanitarias para establecimientos.</li>
    <li>Auditorías internas y externas de buenas prácticas de fabricación.</li>
    <li>Defensa legal ante las autoridades jurisdiccionales derivado de los actos de autoridad de las instancias sanitarias.</li>
    <li>Autorizaciones Sanitarias: registros medicamentos, insumos para la salud, biotecnológicos, permisos de importación.</li>
    <li>Certificados de exportación y de libre venta. </li>
    <li>Certificados de buenas prácticas de fabricación (GMP).</li>
    <li>Avisos y permisos sanitarios de la publicidad.</li>
    <li>Visitas de revisión de condiciones sanitarias de establecimientos.</li>
    <li>Farmacovigilancia y tecnovigilancia.</li>
    <li>Cumplimiento de regulación sanitaria de productos cosméticos, alimentos, suplementos alimenticios, plaguicidas, entre otros. </li>
    </ul></>] },
    { index:16, nombre: 'MATERIA ELECTORAL', url: "/servicios/", id:'#', img_src: img_material_electoral, texto: [<><ul><li>Asesoría integral, auditoría y cumplimiento de los procesos y normativa del proceso electoral nacional.</li>
    <li>Juicios de protección de los derechos electorales del ciudadano</li>
    <li>Juicio de inconformidad.</li>
    <li>Recursos de reconsideración.</li>
    <li>Recursos de apelación.</li>
    <li>Impugnación de resultados electorales.</li>
    <li>Nulidades electorales.</li>
    </ul></>] },
]
export const CarouselServicios = (params, props) => {
    const [ver_popup, setVerPopup] = useState(false);
    const [ver_persona, setVerPersona] = useState(0);
    const [iniciar_modal, setIniciarModal] = useState(true);
    const [tipo_popup, setTipoPopup] = useState(1);
    const [activar, setActivar] = useState(false);
    const ref_carousel = React.createRef();
    
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (

            <CaretRightFilled className={className} style={{ ...style, display: "block", color: '#fc5d17', fontSize: '34px' }} onClick={onClick} />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <CaretLeftFilled className={className} style={{ ...style, display: "block", color: '#fc5d17', fontSize: '34px' }} onClick={onClick} />
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        draggable: false,
    };
    useEffect(()=>{
        if(window.location.pathname === '/servicios')
        {
            setActivar(true)
        }else{
            setActivar(false)
        }
    },[window.location.pathname])
    useEffect(() => {
        console.log(ref_carousel);
        console.log('ver_popup',ver_popup);

        if (!ver_popup && iniciar_modal) {
            setIniciarModal(false);
        }

        if (ref_carousel.current) {

            ref_carousel.current.slickGoTo(ver_persona);
        }
        setTipoPopup(tipo_popup);
    }, [ref_carousel, ver_popup])

    let ver_servicios = servicios.map((servicio, index) => {
        return (
            <Col xs={12} sm={8} md={6} lg={6} className="contenedor_servicio">
                <Paragraph className="texto_banner_servicios_home">
                    <br />
                    <div className="container_imagen_hover">
                        <img alt={servicio.nombre} src={servicio.img_src} style={{ width: '100%' }} className="img_banner_servicios imgen_servicio_hover" />
                        <div class={activar ? "middle_imgaen_hover color_gris" : "middle_imgaen_hover color_blanco"}>
                            {servicio.nombre}<br /><br />
                            {activar ?
                            <><a href="mailto:contacto@mafyasociados.com" class="text_imgen_hover">¡CONTÁCTANOS!</a><br /><br />
                            <span onClick={() => { setVerPopup(servicio.index);setVerPersona(index) }} class="text_imgen_hover">VER MÁS</span>
                            </>
                            :
                            <><a href="mailto:contacto@mafyasociados.com" class="text_imgen_hover">¡CONTÁCTANOS!</a><br /><br />
                            <span onClick={() => { setVerPopup(servicio.index);setVerPersona(index) }} class="text_imgen_hover">VER MÁS</span>
                            </>
                            }
                            
                        </div>
                    </div>
                    <br />
                </Paragraph>
            </Col>
        );

    });
    let ver_servicios_modal = servicios.map((servicio, index)=>{
        return(
            <>
             <Col xs={24} md={23}>
                <strong>{servicio.nombre}</strong><br /><br />
                <p>{servicio.texto}</p>
                </Col>
            </>
        )
    })

    let ver_movil_servicios = servicios.map((servicio, index) => {
        return (
            <>
            <Row>
                <Col offset={2} span={20}>
                    <img alt={servicio.nombre} src={servicio.img_src} style={{ width: '100%' }} className="" />
                <div class={activar ? "color_gris" : "color_blanco"}>
                            {servicio.nombre}<br /><br />
                            {activar ?
                            <><a href="mailto:contacto@mafyasociados.com" class="text_imgen_hover">¡CONTÁCTANOS!</a><br /><br />
                            <span onClick={() => { setVerPopup(servicio.index);setVerPersona(index) }} class="text_imgen_hover">VER MÁS</span>
                            </>
                            :
                            <><a href="mailto:contacto@mafyasociados.com" class="text_imgen_hover">¡CONTÁCTANOS!</a><br /><br />
                            <span onClick={() => { setVerPopup(servicio.index);setVerPersona(index) }} class="text_imgen_hover">VER MÁS</span>
                            </>
                            }
                            
                        </div>
                </Col>
            </Row>
            </>
        );

    });
    return (
        <>
            <Row justify="space-around" align="middle">
                {activar ?
                <>
                
            </> :<>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 0 }}>
                <Paragraph className="titulo_seccion_servicios_home color_blanco" >NUESTROS SERVICIOS</Paragraph>
            </Col>
            <Col xs={{ span: 20, offset: 0 }} md={{ span: 0, offset: 0 }}>
                <Paragraph className="titulo_seccion_servicios_movil" >NUESTROS SERVICIOS</Paragraph>
            </Col></>}
            <Col xs={{ span: 20, offset: 0 }}>
                <p className={activar ? 'texto_servicio color_gris' : 'texto_servicio color_blanco' }>
                    Ofrecemos una amplia gama de servicios especializados en los siguientes ámbitos:
                </p>
            </Col>
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 20, offset: 0 }}>
                    <Row>{ver_servicios}</Row>
                </Col>
                <Col xs={{ span: 18, offset: 0 }} md={{ span: 0, offset: 0 }}>
                    <Slider {...settings} className="slider_nosotros">
                        {ver_movil_servicios}
                    </Slider>
                </Col>
            </Row>
            <Modal
                style={{ backgroundColor: '#ffffff', top: '5%', padding: '0px', zIndex: '3' }}
                title={null}
                footer={null}
                closable={true}
                visible={iniciar_modal || ver_popup}
                onCancel={() => { setVerPopup(false); }}
                bodyStyle={{ backgroundColor: '#ffffff', margin: '0px', padding: '20px 40px 20px 30px', width: '100%', zIndex: '3' }}
                width="750px"
                className="pop_semblanza"
                forceRender={true}
            >
                <Slider ref={ref_carousel} {...settings} className="slider_nosotros">
                    {ver_servicios_modal}
                </Slider>
            </Modal>
        </>
    );

};