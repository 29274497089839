import React from 'react';
import { Row, Col } from 'antd';

import img_src_header from '../../../../assets/images/paginas/conapred/header_conapred_articuli.png'

export const Conapred = () => {
    return (
        <Row >
            <Col span={24}>
            <img alt="Articulo Conapred" src={img_src_header} style={{ width: '100%' }} />
            </Col>
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} style={{ textAlign: 'right' }}>
                
                <br />
                <p className="color_gris_claro texto_tamano" style={{ textAlign: 'justify' }}>
                <span style={{ color:'#eb4b1c'}}><strong>"DEJEMOS VIVIR AL CONAPRED. FORTALEZCAMOS NUESTRAS INSTITUCIONES"</strong></span><br/><br/>
                Por Mario Antonio Ávila
                    <br /><br />
                    En días recientes se han generado pronunciamientos por desaparecer el Consejo Nacional para Prevenir la Discriminación (CONAPRED), bajo los argumentos de que no ha dado resultados, la población mexicana no lo conoce y su supresión conllevaría generar ahorros en el presupuesto público, para que así sus funciones sean asumidas por la Secretaria de Gobernación.<br/><br/>
                    En ese sentido, es de suma importancia saber qué es y cuales son las funciones del Consejo, para entonces poder dimensionar las implicaciones que tendría su desaparición.<br/><br/>
                    CONAPRED, es un <strong>órgano administrativo descentralizado con personalidad jurídica y patrimonio propio, sectorizado en la Secretaría de Gobernación.</strong> Nace en el año 2003 por la necesidad de hacer frente a las desigualdades graves y a la discriminación que se encuentra en la base estructural de muchas de las formas del relacionamiento social en nuestro país. Su existencia se debe no sólo a la posibilidad de actuar contra los casos de discriminación que puedan ocurrir, sino también para la importante tarea de combatir las prácticas discriminatorias que tienen raíces históricas profundas y cuyos efectos continuos hacen que millones de personas sean, económica, laboral o culturalmente colocadas en una situación de vulnerabilidad.<br/><br/>
                    Es decir que es la instancia del Gobierno de México encargada de verificar y vigilar el cumplimiento del objeto de la Ley para prevenir y eliminar todas las formas de discriminación que se ejerzan contra cualquier persona en los términos del Artículo 1 de la Constitución Política de los Estados Unidos Mexicanos, así como de promover la igualdad de oportunidades y de trato.<br/><br/>
                    Entre sus funciones principales se encuentra la promoción de la aplicación de acciones afirmativas, buenas prácticas y experiencias exitosas en materia de no discriminación, en las instituciones públicas, privadas , así como en la sociedad civil en genera; además, es el encargado de conocer e investigar los presuntos casos de discriminación que se presenten, cometidos por personas servidoras públicas, poderes públicos federales o particulares y velar porque se garantice el cumplimiento de todas las resoluciones de su Asamblea, entre otras funciones relevantes en la materia.<br/><br/>
                    <strong>Entonces, ¿cómo podría afectar la desaparición del CONAPRED?,</strong> la desaparición del Consejo representaría, per se, la pérdida del órgano rector de políticas públicas en materia de combate a la discriminación.<br/><br/>
                    Así mismo se pondría en riesgo los avances logrados por el Consejo en materia de igualdad y prevención de la discriminación al suprimir el órgano autónomo encargado de velar por los intereses de los grupos vulnerables ante conductas discriminatorias, dejando desamparadas, entre otras, a personas del espectro LGBTI+, personas con discapacidad y todas aquellas que por razones de salud, creencias o condiciones socioeconómicas o cualesquiera otras pueden ser objeto de discriminación o abusos por parte de otras.
                    Por otra parte, esta situación podría generar la pérdida de fuerza y empuje en el diseño de políticas públicas para prevenir y sancionar la discriminación, además de generar una observancia endeble al dejar de señalarse e imponerse medidas de nivelación, inclusión y acciones afirmativas que tanto han costado a la población mexicana entender, pero han servido para sentar bases para el recorrido que implica la eliminación de este tipo de conductas nocivas.<br /><br />
                    Por último, la desaparición de la CONAPRED significaría una violación al principio de progresividad de los Derechos Humanos, mismo que establece que éstos y sus mecanismos de defensa siempre deben de buscar avanzar y no retroceder. Dicho sea de paso, infringiría los Tratados Internacionales de los que el Estado Mexicano es parte, referentes a la prevención, atención y eliminación de las distintas expresiones de discriminación, lo que conlleva un incumplimiento a los compromisos adoptados con y ante la comunidad internacional.<br /><br />
                    Por lo anterior, la desaparición de CONAPRED, pone en grave riesgo los avances logrados en materia de igualdad y prevención de la discriminación al dejar de existir la instancia que hasta este momento protege los derechos de los grupos vulnerables a sufrir discriminación por parte de autoridades, empresas y personas físicas, y sancionaba las conductas discriminatorias.<br /><br />
                    Desde la sociedad civil, hacemos un llamado a revisar el marco normativo que dota al Consejo de facultades y atribuciones para hacer más eficaz su labor en función de continuar con la eliminación de todos los tipos de discriminación, sin llegar a su supresión.
                    <br /><br />
                    
                </p>

            </Col>
        </Row>
    );
}