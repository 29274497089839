import React from 'react';
import { Col, Row } from 'antd';

export const Focampo = () => {

    return (
        <>
            <table width="710" cellspacing="0" cellpadding="0" border="0" style={{ color: 'rgb(0,0,0)', fontSize: 'medium', fontFamily: 'Helvetica', border: '0px solid gray' }}>
                <tbody>
                    <tr>
                        <td width="250" height="94" valign="center" rowspan="3">
                            <a href="https://www.asesoriapenalintegral.com/" target="_blank">
                                <img src="https://www.thethree.com.mx/api/logo_api-.jpg" style={{ display: 'block' }} width="250" height="94" alt="Logotipo API" title="API" />
                            </a>
                        </td>
                        <td width="460" style={{ textAlign: 'left', verticalAlign: 'middle' }}>
                            <span style={{ fontSize: '16px', color: 'gray', fontWeight: 'bold', padding: '0px 10px' }}>
                                <strong>Lic. Fabio Alberto Emmanuel Ocampo V&aacute;zquez</strong></span>
                        </td>
                    </tr>
                    <tr>
                        <td width="460" height="20" valign="bottom" style={{ textAlign: 'left', verticalAlign: 'top' }}>
                            <span style={{ fontSize: '12px', color: '#2E338C', fontWeight: '700', lineHeight: '8px', padding: '5px 10px', letterSpacing: '1' }}>Coordinador de Asuntos Jur&iacute;dicos y Legislativos</span>
                        </td>
                    </tr>
                    <tr>
                        <td width="460" height="44" style={{ textAlign: 'left', verticalAlign: 'middle', padding: '0px 10px 5px 10px' }}>
                            <span style={{ fontSize: '12px', color: 'gray', fontWeight: '800', lineHeight: '20px' }}>Email&#58;</span>
                            <span style={{ fontSize: '12px', color: 'gray', fontWeight: '500', lineheight: '10px' }}><a style={{ textDecoration: 'none', color: 'gray' }} href="mailto:focampo@asesoriapenalintegral.com" target="_blank"> focampo@asesoriapenalintegral.com</a></span>
                            <br />
                            <span style={{ fontSize: '12px', color: 'gray', fontWeight: '800', lineHeight: '20px' }}>Oficina&#58;</span><span style={{ fontSize: '12px', color: 'gray', fontWeight: '500' }}><a style={{ textDecoration: 'none', color: 'gray' }} href="tel:+525578251014" target="_blank"> +52 (55) 7825 1014</a> al 17</span>
                            <br />
                            <span style={{ fontSize: '12px', color: 'gray', fontWeight: '800', lineHeight: '20px' }}>Celular&#58;</span><span style={{ fontSize: '12px', color: 'gray', fontWeight: '500' }}><a style={{ textDecoration: 'none', color: 'gray' }} href="tel:+525548115196" target="_blank"> +52 (55) 4811 5196</a></span>
                            <br />
                            <span style={{ fontSize: '12px', color: 'gray', fontWeight: '800', lineHeight: '20px' }}>Direcci&oacute;n&#58;</span><span style={{ fontSize: '12px', color: 'gray', fontWeight: '500' }}><a style={{ textDecoration: 'none', color: 'gray' }} href="https://bit.ly/3cZyn4i" target="_blank"> Av. Isaac Newton 232, Polanco V Secci&oacute;n, 11560</a></span>
                        </td>
                    </tr>
                    <tr>
                    </tr>
                </tbody>
            </table>
            <table width="710" cellspacing="0" cellpadding="0" border="0" style={{ color: 'rgb(0,0,0)', fontSize: 'medium', fontFamily: 'Helvetica' }}>
                <tbody>
                    <tr>
                        <td width="710" height="10" valign="bottom">
                            <img src="https://www.mafyasociados.com/firmas/recursos/blanco.gif" style={{ display: 'block' }} width="710" height="10" />
                        </td>
                    </tr>
                    <tr>
                        <td height="15" align="left" bgcolor="#FFFFFF" style={{ fontFamily: 'Arial', fontSize: '9px', color: '#a2a2a2' }}>
                            <div align="justify">
                                <font style={{ fontSize: '10px' }}><strong>Aviso de privacidad de informaci&oacute;n:</strong></font><br /><br />
                            La informaci&oacute;n contenida en este correo electr&oacute;nico as&iacute; como cualquier archivo adjunto y/o documentos vinculados al mismo, est&aacute; dirigida &uacute;nicamente para el uso del individuo(s) o entidad a la que fue enviada y puede contener informaci&oacute;n privilegiada, confidencial o de propiedad intelectual protegida por la ley. Cualquier difusi&oacute;n, distribuci&oacute;n o copia de este correo electr&oacute;nico o su contenido est&aacute; prohibida. Esta notificaci&oacute;n sirve como 
                            <a href="https://www.asesoriapenalintegral.com/" title="Aviso de privacidad" target="_blank" style={{fontFamily:'Arial, Helvetica, sans-serif', fontSize:'9px',color:'#a2a2a2',textDecoration:'underline'}}>aviso de privacidad</a> de informaci&oacute;n de Asesoría Penal Integral y de manifiesto de cualquier acuerdo de confidencialidad o no divulgaci&oacute;n.<br /><br />
                            Si usted recibi&oacute; esta comunicaci&oacute;n por error o no es el destinatario al que fue enviado, favor de notificar al remitente de inmediato y borrar este mensaje de su servidor.
                        </div></td>
                    </tr>
                </tbody>
    </table>
        </>
    );
}
