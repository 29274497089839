import React from "react";
import { Row, Col } from "antd";
import MetaTags from "react-meta-tags";
import Helmet from "react-helmet";
import DocumentMeta from "react-document-meta";

import img_src_header from "../../../assets/images/paginas/resistir_para_existir/re_existiendo.png";

export const ResistirParaExisitir = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Lo que nació como un proyecto escolar para la universidad, evolucionó para convertirse en una herramienta" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.mafyasociados.com/re-exisitiendo" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Emilio Lira" />
        <meta name="author" content="Emilio Lira" />
        <meta property="og:title" content="RESISTIR PARA EXISTIR. ALTERNATIVAS ALIMENTARIAS EN LA CDMX" />
        <meta property="og:description" content="Lo que nació como un proyecto escolar para la universidad, evolucionó para convertirse en una herramienta" />
        <meta property="og:url" content="https://www.mafyasociados.com/re-exisitiendo" />
        <meta property="og:site_name" content="MAF & Asociados" />
        <meta property="og:image" content="https://mafyasociados.com/archivos/vistas/re_existiendo.png" />
        <meta property="og:image:secure_url" content="https://mafyasociados.com/archivos/vistas/re_existiendo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Lo que nació como un proyecto escolar para la universidad, evolucionó para convertirse en una herramienta" />
        <meta name="twitter:title" content="RESISTIR PARA EXISTIR. ALTERNATIVAS ALIMENTARIAS EN LA CDMX" />
        <meta name="twitter:image" content="https://mafyasociados.com/archivos/vistas/re_existiendo.png" />
      </Helmet>
      <Row>
        <Col span={24}>
          <img alt="Articulo Resistir para existir" src={img_src_header} style={{ width: "100%" }} />
        </Col>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
          <br />
            <br />
        <p
            className="color_naranja titulo_tamano "
            style={{ textAlign: "center"}}
          >
            <strong>
            RESISTIR PARA EXISTIR. ALTERNATIVAS ALIMENTARIAS EN LA CDMX.
            </strong>
            
          </p>
          <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify" }}
          >
            Por Emilio Lira
            <br />
            <br />
            Lo que nació como un proyecto escolar para la universidad, evolucionó para convertirse en una herramienta de corte social que pretende volver a conectar el lazo entre personas productoras y personas consumidoras a nivel local, el cual ha sido carcomido por el paso del tiempo, la evolución urbana, la gentrificación y la comodidad que ahora nos dan las aplicaciones móviles a través del comercio electrónico.
            <br /><br />
            En este sentido, tuve el gusto de entrevistar a esta gran pequeña colectiva feminista, integrada por Claudia ♑︎, Samantha ♓︎, Isamar ♒ ︎y Regina ♈︎. De quienes, en unos pocos párrafos trataré de documentar el proceso creativo, los alcances e intenciones que tiene este extraordinario producto creado por ellas y que se denomina (Re) existiendo. 
            <br />
            <br />
            ‘Resistir para existir’ fue lo que llevó a ponerle nombre al directorio, pues en un entorno en el que las pequeñas y medianas personas productoras se enfrentan a los embates de empresas trasnacionales, que monopolizan el mercado, es que surge la necesidad de resistir desde sus trincheras. 
            <br />
            <br />
            Ante esto, tres estudiantes de Geografía y una de Diseño y Comunicación Visual, han desarrollado lo que hoy conocemos como <a href="https://drive.google.com/file/d/1VfgYYOKVoNdmu3i3UGeFPOSH_zoSff-H/view?fbclid=IwAR0XDqhlNg119RInxsXZakZtVx4IqDJhTpdkGiGUBW_IP9jUyQsUGKbcK5c" target="_blank">el Directorio de Alternativas Alimentarias en la Ciudad de México.</a> <em>(Re) existiendo</em> ha conglomerado, en esta primera fase, un total de 26 puntos de venta de personas productoras localizadas en varias alcaldías de la capital mexicana y dos específicamente en el Estado de México. <u>Las categorías que contemplan van desde cárnicos y derivados animales, miel, cacao, semillas, hasta frutas, verduras, hortalizas, conservas y bebidas; señalando un rango de precios de 100 a más de 300 pesos.</u>
            <br />
            <br />
            Al platicar con ellas mencionaron que la idea surgió a partir de <u>la preocupación sobre lo que consumimos,</u> lo que dio pie a visibilizar otras formas, a nivel local y comunitario, de las que nos hacemos sobre bienes alimentarios. Pues, muy atinadamente, mencionan que el mercado se ha ido monopolizando por grandes empresas que deciden lo que la mayoría de nosotros consumimos.
            <br />
            <br />
            <strong>FAMILIARIZACIÓN CON EL ENTORNO</strong>
            <br />
            <br />
            Su proceso de creación, parte elemental de este proyecto, surge a raíz del levantamiento de información realizado por medio de la investigación de recomendaciones, redes sociales y trato directo con las productoras y productores. <em>“Al contactar a estas personas te das cuenta de que hay muchos proyectos que no son conocidos</em> —menciona Regina—, <em>y muchos de ellos son cooperativas que buscan transformar su entorno a través del fortalecimiento de los lazos comunitarios y del esfuerzo de su producción.”</em>
            <br />
            <br />
            Algo que llama la atención de este directorio es encontrar <u>el nombre de las personas productoras de estos puntos de venta.</u> <em>“Al tener el contacto con les productores, te das cuenta de todo el amor que le ponen a sus productos y la historia que llevan consigo. Todes son increíbles, así como la comunidad que se está formando”,</em> menciona Samantha. Y es una realidad, la comodidad de las personas consumidoras ha llegado a tal punto en que somos ajenas a nuestros espacios inmediatos, lo que ha llevado consigo la pérdida de la cohesión social a nivel comunitario.
            <br />
            <br />
            <strong>“¿QUÉ NO ENFRENTAN LAS PRODUCTORAS Y PRODUCTORES AGRÍCOLAS?”</strong>
            <br />
            <br />
            Al preguntar cuáles eran los retos que enfrentan las productoras y productores agrícolas, la respuesta fue concreta. <em>“¿Qué no enfrentan?”</em> En un país donde estas personas se ven azotadas por la explotación, inseguridad, el desplazamiento forzado, salarios paupérrimos por jornada, falta de educación, entre otras cuestiones; aunado a ello, se ven en una amplia desventaja, ya que desde la cosecha de la siembra hasta el consumo final pueden existir de entre <u>3 a 7 intermediarios,</u> lo que no solo eleva el último costo, sino que deja a las personas productoras fuera de una distribución equitativa de la riqueza.
            <br />
            <br />
            <em>“También están los problemas acerca del suelo. Esta agricultura intensiva está matándolo completamente con los agroquímicos que usan”,</em> dice Samantha. <em>“Siempre se prioriza lo económico sobre la vida reproductiva. Tal vez en un punto de venta te puedes encontrar con un pan de $70.00 pero al ver todo lo que hay detrás te vuelves consciente sobre lo que estás pagando y vale la pena”,</em> apunta Regina. <em>“A la vez, la existencia de estas formas alternas indica que hay algo mal con el método convencional, que hay una necesidad de hacer las cosas diferentes. Creo que el surgimiento y permanencia de estas nuevas propuestas son un llamado de atención para voltearles a ver”,</em> abona Isamar. <em>“Las personas consumidoras no comprenden los ciclos de producción de la tierra y las empresas multinacionales sobreexplotan los recursos porque la demanda es parte del problema, el hecho de que todo esté disponible todo el tiempo es un factor que también golpea a las pequeñas producciones”,</em> concluye Claudia.
            <br />
            <br />
            <strong>EL FUTURO DE (RE) EXISTIENDO</strong>
            <br />
            <br />
            Muchos son los objetivos del directorio, además de visibilizar las alternativas existentes, señalan la importancia de <u>descentralizar la adquisición de productos,</u> pues tal como se puede observar en el mapa del directorio, las alcaldías con mayor poder adquisitivo son las que cuentan con un número más amplio de puntos de venta. <u>Lo que se ha desencadenado como una moda, debe de permanecer como un estilo de vida.</u> La producción existe en la periferia y la distribución en la centralización, por lo que, en las siguientes ediciones del directorio, se plantea mapear aquellos lugares de la periferia para darles visibilidad y que esta práctica se comience a esparcir por todo el país. Sumado a lo anterior, este proyecto busca rescatar la producción y el consumo local, a través de la solidaridad, el respeto y el reconocimiento del trabajo de las productoras y productores. <em>(Re) existiendo</em> también busca crear, a través de la interconexión de estos puntos de venta y personas productoras, redes comunitarias para el apoyo mutuo, que permitan la reconstrucción del tejido social.
            <br />
            <br />
            <strong>¿QUÉ HACE FALTA?</strong>
            <br />
            <br />
            Como se mencionó, en esta primera edición se recogieron únicamente opciones alimentarias, sin embargo, se plantea en futuras adiciones incluir opciones de higiene, cosmética natural y más productos. <u>Un hallazgo importante en la recolección de información fue encontrar que no hay alternativas de consumo para las infancias, ya sea como alimentos, botanas o incluso comida chatarra; y tampoco para gente adulta mayor,</u> por lo que quieren promover no solo la distribución de esos productos, sino la visibilización de estos sectores. Ya que como lo mencionan <em>“tanto la infancia como la vejez importan”.</em>
            <br />
            <br />
            Si bien es cierto que las grandes empresas productoras tienen mucho por hacer para establecer una cadena de valor más justa y equitativa, también nosotros tenemos mucho poder a la hora de elegir nuestros productos y las fuentes de dónde y cómo provienen. Seamos conscientes sobre lo que consumimos, a quienes les consumimos y cómo aporta a nuestra economía, entorno y cohesión social. 

            <br />
          </p>
          <p
            className="color_naranja texto_tamano"
            style={{ textAlign: "center" }}
          >
            <strong>
            ¡CREAR REDES COMUNITARIAS, DE ESO SE TRATA!
            </strong>
            <br />
            <br />
          </p>
        </Col>

        <Col span={20} offset={2}></Col>
      </Row>
    </>
  );
};
