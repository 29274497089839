import React from 'react';
import { Row, Col } from 'antd';
import '../../../assets/css/sitio/directorio.css';

import img_src_directorio from '../../../assets/images/paginas/directorio-emergente/maf.jpg';

import img_src_directorio_1 from '../../../assets/images/sitio/directorio/banner_directoriogeneral_noestassola_imgn_3.jpg';
import img_src_directorio_2 from '../../../assets/images/sitio/directorio/directoriogeneral_noestassola_imgn_3.png';

export const DirectorioEmergente = () => {

    return(
        <Row justify="center" align="middle">
            <Col span={20}>
            <img alt="Directorio Emergnte" src={img_src_directorio_1} style={{width:'100%'}} />
            </Col>
            <Col span={20}>
                <p className="texto_banner_directorio">
                    Ahora que pasarás más tiempo en casa, <span style={{color:'rgb(50,24,100)'}}>RECUERDA QUE LA VIOLENCIA NO ES NORMAL.</span><br />
                    Si necesitas ayuda, no dudes en llamar.
                </p>
            </Col>
            <Col span={20} className="contenedor_directorio">
                <Row>
                    <Col xs={24} md={8}>
                        <p className="titulo_directorio">
                            Atención en todos <br />los estados del país.
                        </p>
                        <img alt="img 911" src={img_src_directorio_2} style={{width:'100%'}} />
                        <br /><br />
                        <p className="texto_datos_directorio">
                        <strong>Aguascalientes</strong><br />
                        <a className="link_directorio" href="tel:+524499182550" target="_blank">449 918 2550</a> y <a  className="link_directorio" href="tel:+524499102128"  target="_blank">449 910 2128</a>
                        <br /><br />
                        <strong>Baja California</strong><br />
                        <a className="link_directorio" href="tel:+526865586364" target="_blank">686 558 6364</a> y <a className="link_directorio" href="tel:+5266460808888" target="_blank">664 608 08888</a>
                        <br /><br />
                        <strong>Baja California Sur</strong><br />
                        <a className="link_directorio" href="tel:+526126881236" target="_blank">612 688 1236</a> y <a className="link_directorio" href="tel:+526121222945" target="_blank">612 122 2945</a>
                        <br /><br />
                        <strong>Campeche</strong><br />
                        <a className="link_directorio" href="tel:+529818112656" target="_blank">981 811 2656</a> y <a className="link_directorio" href="tel:+529818116086" target="_blank">981 811 6086</a>
                        <br /><br />
                        <strong>Chiapas</strong><br />
                        <a className="link_directorio" href="tel:+529614629340" target="_blank">961 4629 340</a>
                        <br /><br />
                        <strong>Chihuahua</strong><br />
                        <a className="link_directorio" href="tel:+526144293505" target="_blank">614 429 3505</a> ext. 15311
                        <br /><br />
                        <strong>Ciudad de México</strong><br />
                        <a className="link_directorio" href="tel:+525556581111" target="_blank">55 5658 1111</a> y <a className="link_directorio" href="tel:+525555122836" target="_blank">55 5512 2836</a>
                        <br /><br />
                            <strong>Coahuila</strong><br />
                            <a className="link_directorio" href="tel:+52075" target="_blank">075</a> y <a className="link_directorio" href="tel:+528444344875" target="_blank">844 434 4875</a>
                        
                        </p>
                    </Col>
                    <Col xs={24} md={8}>
                        <p className="texto_datos_directorio">
                            <br />
                            <strong>Colima</strong><br />
                            <a className="link_directorio" href="tel:+52075" target="_blank">075</a> y <a className="link_directorio" href="tel:+52911" target="_blank">911</a>
                            <br /><br />
                            <strong>Durango</strong><br />
                            <a className="link_directorio" href="tel:+52911" target="_blank">911</a>
                            <br /><br />
                            <strong>Estado de México</strong><br />
                            <a className="link_directorio" href="tel:+528001084053" target="_blank">800 108 4053</a>
                            <br /><br />
                            <strong>Guanajuato</strong><br />
                            <a className="link_directorio" href="tel:+52075" target="_blank">075</a> y <a className="link_directorio" href="tel:+524731640369" target="_blank">473 164 0369</a>
                            <br /><br />
                            <strong>Guerrero</strong><br />
                            <a className="link_directorio" href="tel:+527444866198 " target="_blank">744 486 6198</a> y <a className="link_directorio" href="tel:+527474719530" target="_blank">747 471 9530</a>
                            <br /><br />
                            <strong>Hidalgo</strong><br />
                            <a className="link_directorio" href="tel:+527717189205" target="_blank">771 718 9205</a>
                            <br /><br />
                            <strong>Jalisco</strong><br />
                            <a className="link_directorio" href="tel:+523336583170" target="_blank">3336583170</a> y <a className="link_directorio" href="tel:+523333456166" target="_blank">333 345 6166</a>
                            <br /><br />
                            <strong>Michoacán</strong><br />
                            <a className="link_directorio" href="tel:+524435822082" target="_blank">443 582 2082</a>
                            <br /><br />
                            <strong>Morelos</strong><br />
                            <a className="link_directorio" href="tel:+527771730066" target="_blank">777 173 0066</a>
                            <br /><br />
                            <strong>Nayarit</strong><br />
                            <a className="link_directorio" href="tel:+523112170377" target="_blank">311 217 0377</a> y <a className="link_directorio" href="tel:+523112176515" target="_blank">311 217 6515</a>
                            <br /><br />
                            <strong>Nuevo León</strong><br />
                            <a className="link_directorio" href="tel:+528113005391" target="_blank">811 300 5391</a> y <a className="link_directorio" href="tel:+528113004875" target="_blank">811 300 4875</a>
                            <br /><br />
                            <strong>Oaxaca</strong><br />
                            <a className="link_directorio" href="tel:+529512072666" target="_blank">951 207 2666</a> y <a className="link_directorio" href="tel:+529514277502" target="_blank">951 427 7502</a>
                        </p>
                    </Col>
                    <Col xs={24} md={8}>
                        <p className="texto_datos_directorio">
                            <br />
                            <strong>Puebla</strong><br />
                            <a className="link_directorio" href="tel:+522222323738" target="_blank">222 232 3738</a>
                            <br /><br />
                            <strong>Querétaro</strong><br />
                            <a className="link_directorio" href="tel:+524422164757" target="_blank">442 216 4757</a> y <a className="link_directorio" href="tel:+528000083568" target="_blank">800 008 3568</a>
                            <br /><br />
                            <strong>Quintana Roo</strong><br />
                            <a className="link_directorio" href="tel:+528005009559" target="_blank">800 500 9559</a>
                            <br /><br />
                            <strong>San Luis Potosí</strong><br />
                            <a className="link_directorio" href="tel:+52911" target="_blank">911</a>
                            <br /><br />
                            <strong>Sinaloa</strong><br />
                            <a className="link_directorio" href="tel:+526677520672" target="_blank">6677520672</a> y <a className="link_directorio" href="tel:+526677520673" target="_blank">6677520673</a>
                            <br /><br />
                            <strong>Sonora</strong><br />
                            <a className="link_directorio" href="tel:+526621083200" target="_blank">662 108 3200</a>
                            <br /><br />
                            <strong>Tabasco</strong><br />
                            <a className="link_directorio" href="tel:+529933166813" target="_blank">993 316 6813</a> y <a className="link_directorio" href="tel:+529933166351" target="_blank">993 316 6351</a>
                            <br /><br />
                            <strong>Tamaulipas</strong><br />
                            <a className="link_directorio" href="tel:+528341101622" target="_blank">834 110 1622</a>
                            <br /><br />
                            <strong>Tlaxcala</strong><br />
                            <a className="link_directorio" href="tel:+528008387070" target="_blank">800 838 7070</a>
                            <br /><br />
                            <strong>Veracruz</strong><br />
                            <a className="link_directorio" href="tel:+52075" target="_blank">075</a> y <a className="link_directorio" href="tel:+528009068537" target="_blank">800 906 8537</a>
                            <br /><br />
                            <strong>Yucatán</strong><br />
                            <a className="link_directorio" href="tel:+529999233711" target="_blank">999 923 3711</a> y <a className="link_directorio" href="tel:+529999233719" target="_blank">999 923 3719</a>
                            <br /><br />
                            <strong>Zacatecas</strong><br />
                            <a className="link_directorio" href="tel:+52911" target="_blank">911</a>
                        </p>
                    </Col>
                </Row>
            </Col>
            <Col span={20} offset={2} className="">
                <br /><br />
            </Col>
            <Col  span={22}>
            <div data-type="AwesomeTableView" data-filters="" data-viewID="-MBoQUnHDauL2dDMLzmY"></div>
            </Col>
            
            <Col span={20} offset={2} className="">
                <br /><br />
            </Col>
        </Row>
    );
}