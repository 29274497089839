import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import categoria_responsabilidad_social from './ResponsabilidadSocial.json';
import categoria_tips_empresariales from './TipsEmpresariales.json';
import categoria_violencia_genero from './ViolenciaGenero.json';
import categoria_webinars from './Webinars.json';
import categoria_publicaciones from './Publicaciones.json';
import categoria_articulos from './Articulos.json';
import categoria_todos from './Todos.json';


const img_src_webinars = [
    require('../../../assets/images/sitio/noticias/Maf_Masculinidad.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_HomeOffice.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_CiberSegNoticias.jpg')
];

let categoria_seleccionada, img_src_categoria_seleccionada;

export const CardsWebinars = (props) => {

    const { categoria } = props;

    console.log(categoria);
    categoria_seleccionada = categoria_webinars.filter(articulo =>{
       for (let i = 0; i < articulo.categorias.length; i++){
         if(articulo.categorias[i] == categoria){
           return true;
         }
       }
       return false;
    });
    img_src_categoria_seleccionada = img_src_webinars;
    let cards_categoria = categoria_seleccionada.slice(0).reverse().map(categoria => {

        return(
            <Col xs={{span:24, offset:0}} sm={{span:20, offset:2}} md={{span:12, offset:0}} className="contenedor_item_categoria">
                
                    <br />
                    <p class="titulo_item_categoria">
                        { categoria.titulo}
                    </p>
                    <hr class="linea_citem_ategoria" />
                    <p class="fecha_item_categoria">
                        { categoria.fecha}
                    </p>
                    <p class="info_item_categoria">
                        { categoria.info}
                    </p>
                    <a target={categoria.externo ? '_blank' : ''} href={categoria.url} className="link_item_categoria">
                    
                    <img alt="Categoria" src={img_src_categoria_seleccionada[categoria.index_img]} className="img_item_categoria" />
                    </a>
            </Col>
        );
    });

    return (
        <Row>
            { cards_categoria }
        </Row>
    );
}