import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Link } from "react-router-dom";
import { CarouselEquipo } from './CarouselEquipo';
import '../../../assets/css/sitio/nosotros.css';

import src_img_icono_nosotros_vision from '../../../assets/images/sitio/nosotros/icono_nosotros-vision-.png';
import src_img_icono_nosotros_valores from '../../../assets/images/sitio/nosotros/icono_nosotros-mision.png';
import src_img_icono_nosotros_mision from '../../../assets/images/sitio/nosotros/icono_nosotros-valores.png';
import img_alianza_art from '../../../assets/images/sitio/nosotros/alianzas/Maf_ART.jpg';
import img_alianza_axxis from '../../../assets/images/sitio/nosotros/alianzas/Maf_Axxis.jpg';
import img_alianza_dane from '../../../assets/images/sitio/nosotros/alianzas/Maf_Dane.jpg';
import img_alianza_inver from '../../../assets/images/sitio/nosotros/alianzas/Maf_Inver.jpg';
import img_alianza_manuel_barrera from '../../../assets/images/sitio/nosotros/alianzas/Maf_ManuelBarrera.jpg';
import img_alianza_san_roman from '../../../assets/images/sitio/nosotros/alianzas/Maf_SanRoman.jpg';
import img_maria from '../../../assets/images/sitio/nosotros/Maf_NuestroEquipo/07_Maf_NuestroEquipo_LourdesGaribay.jpg';

const { Paragraph } = Typography;

export const Nosotros = () => {

    return (
        <>
            <Row className="contenedor_seccion_inicio">
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} className="banner_nosotros">
                    <Row>
                        <Col span={20} offset={2}>

                            <Paragraph className="titulo_nosotros">
                                <br /><br />
                            </Paragraph>
                        </Col>
                        {/*<Col span={20} offset={2} style={{display:'none'}}>
                            <Row justify="space-around">
                                <Col span={7}>
                                    <img alt="Misión" src={src_img_icono_nosotros_mision} className="img_icono_nosotros" />
                                    <br />
                                    <Paragraph className="titulo_iconos">
                                        MISIÓN
                                </Paragraph>
                                    <Paragraph className="texto_iconos">
                                        Ser un socio estratégico para el logro de los
                                        objetivos de nuestros clientes, tanto en el
                                        ámbito público como privado, con una visión
                                        holística e innovadora. Trabajando bajo los más
                                        altos estándares de responsabilidad social y
                                        pensando siempre en un bien común.
            </Paragraph>
                                </Col>
                                <Col span={7}>
                                    <img alt="Visión" src={src_img_icono_nosotros_vision} className="img_icono_nosotros" />
                                    <br />
                                    <Paragraph className="titulo_iconos">
                                        VISIÓN
            </Paragraph>
                                    <Paragraph className="texto_iconos">
                                        Convertirnos en el referente de los servicios de
                                        consultoría especializada, que permita a
                                        nuestros socios estratégicos lograr resultados
                                        sobresalientes, trabajando siempre con las
                                        mejores prácticas del mercado, profesionalismo
                                        e innovación. Nuestros socios estratégicos nos
                                        valorarán por nuestros resultados y ética
                                        profesional.
            </Paragraph>
                                </Col>
                                <Col span={5}>
                                    <img alt="Valores" src={src_img_icono_nosotros_valores} className="img_icono_nosotros" />
                                    <br />
                                    <Paragraph className="titulo_iconos">
                                        VALORES
            </Paragraph>
                                    <Paragraph className="texto_iconos">
                                        <ul className="ul_iconos">
                                            <li className="li_icono">Ética</li>
                                            <li className="li_icono">Eficacia</li>
                                            <li className="li_icono">Innovación</li>
                                            <li className="li_icono">Objetividad</li>
                                            <li className="li_icono">Profesionalismo</li>
                                        </ul>
                                    </Paragraph>
                                </Col>
                            </Row>
    </Col>*/}
                        <Col span={24}>
                            <Row style={{ display: 'non' }} className="contenedor_frase_nosotros">
                                <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                                    <Row align="middle">
                                        <Col xs={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 0 }} >
                                            <img src={img_maria} className="img_frase_doctora" />
                                        </Col>
                                        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 0 }}>
                                            <Paragraph className="texto_frase_doctora">
                                                <strong>¡La experiencia, la creatividad y los resultados importan!<br />
                                                    ¡Un equipo con estupenda actitud... Es esencial!</strong>
                                            </Paragraph>
                                            <Paragraph className="texto_frase_doctora_2">
                                                <i>Ma. de Lourdes Garibay Bordón</i>
                                            </Paragraph>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>




                <Col xs={{ span: 24, offset: 0 }} md={{ span: 0, offset: 0 }} style={{ display: 'none' }} className="banner_nosotros_movil">
                    <Row>
                        <Col span={20} offset={2}>

                            <Paragraph className="titulo_nosotros_movil">
                                <strong>EL MEJOR EQUIPO PROFESIONAL</strong> <br />
                                A SU SERVICIO
                            </Paragraph>
                        </Col>

                        <Col span={20} offset={2} className="contenedor_icono">
                            <img alt="Misión" src={src_img_icono_nosotros_mision} className="img_icono_nosotros" />
                            <br />
                            <Paragraph className="titulo_iconos_movil">
                                MISIÓN
                            </Paragraph>
                            <Paragraph className="texto_iconos">
                                Ser un socio estratégico para el logro de los
                                objetivos de nuestros clientes, tanto en el
                                ámbito público como privado, con una visión
                                holística e innovadora. Trabajando bajo los más
                                alto estándares de responsabilidad social y
                                pensando siempre en un bien común.
                            </Paragraph>
                        </Col>

                        <Col span={20} offset={2} className="contenedor_icono">
                            <img alt="Visión" src={src_img_icono_nosotros_vision} className="img_icono_nosotros" />
                            <br />
                            <Paragraph className="titulo_iconos_movil">
                                VISIÓN
                            </Paragraph>
                            <Paragraph className="texto_iconos">
                                Convertirnos en el referente de los servicios de
                                consultoría especializada, que permita a
                                nuestros socios estratégicos lograr resultados
                                sobresalientes, trabajando siempre con las
                                mejores prácticas del mercado, profesionalismo
                                e innovación. Nuestros socios estratégicos nos
                                valorarán por nuestros resultados y ética
                                profesional.
                            </Paragraph>
                        </Col>

                        <Col span={20} offset={2} className="contenedor_icono">
                            <img alt="Valores" src={src_img_icono_nosotros_valores} className="img_icono_nosotros" />
                            <br />
                            <Paragraph className="titulo_iconos_movil">
                                VALORES
                            </Paragraph>
                            <Paragraph className="texto_iconos_valores_movil">
                                Ética<br />
                                Eficacia<br />
                                Innovación<br />
                                Objetividad<br />
                                Profesionalismo<br />


                            </Paragraph>
                        </Col>



                    </Row>
                </Col>
            </Row>
            <Row className="contenedor_seccion_equipo">
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 12, offset: 2 }}>
                    <Paragraph className="titulo_seccion_equipo">NUESTRO EQUIPO</Paragraph>
                </Col>
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 8, offset: 0 }} style={{ textAlign: 'right' }}>

                </Col>
                <Col span={24}></Col>

                <Col xs={{ span: 20, offset: 2 }} md={{ span: 0, offset: 0 }} style={{ textAlign: 'center' }}>
                    <Paragraph className="titulo_seccion_equipo_movil">NUESTRO EQUIPO
                        <br />

                    </Paragraph>
                </Col>

                <Col xs={{ span: 24, offset: 0 }} >
                    <CarouselEquipo />
                </Col>


            </Row>

        </>

    );
}
