import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import categoria_responsabilidad_social from './ResponsabilidadSocial.json';
import categoria_tips_empresariales from './TipsEmpresariales.json';
import categoria_violencia_genero from './ViolenciaGenero.json';
import categoria_webinars from './Webinars.json';
import categoria_publicaciones from './Publicaciones.json';
import categoria_articulos from './Articulos.json';
import categoria_todos from './Todos.json';


const img_src_responsabilidad = [
    require('../../../assets/images/sitio/noticias/Maf_911.jpg')
];

const img_src_tips_empresariales = [
    require('../../../assets/images/sitio/noticias/Maf_EmergenciaSanitaria_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_RecomendacionesTransportistas_not.jpg')
];

const img_src_violencia_genero = [
    require('../../../assets/images/sitio/noticias/Maf_911.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Masculinidad.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Estereotipos_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_PrivilegiosMaculinos.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_ViolenciaDeGeneroDigital_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_ContenidoConProposito.jpg'),
    require('../../../assets/images/sitio/noticias/representacionfemenina_780.jpg'),
    require('../../../assets/images/sitio/noticias/pobrezaygenero-780.jpg')
];

const img_src_webinars = [
    require('../../../assets/images/sitio/noticias/Maf_Masculinidad.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_HomeOffice.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_CiberSegNoticias.jpg')
];

const img_src_publicaciones = [
    require('../../../assets/images/sitio/noticias_doctora/Maf_17Marzo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_09Abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_23Abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_07Mayo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_21Mayo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_04Junio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_18Junio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_02Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_16Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_30Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_13Agosto.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_01Octubre.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_11feb.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_25_feb.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_11mzo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_25mzo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_8abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_22abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_6mayo.jpg')
];
const img_src_articulos = [
    require('../../../assets/images/sitio/noticias/conapred.jpg'),
    require('../../../assets/images/sitio/noticias/impuestorosa.jpg'),
    require('../../../assets/images/sitio/noticias/resisitirparaexistir_2.jpg'),
    require('../../../assets/images/sitio/noticias/protegetusdatos.png'),
    require('../../../assets/images/sitio/noticias/leydeamnistia-bannerart.jpg')
];

const img_src_todos = [
    require('../../../assets/images/sitio/noticias/Maf_911.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_EmergenciaSanitaria_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_RecomendacionesTransportistas_not.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Masculinidad.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Estereotipos_not.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_17Marzo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_09Abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_23Abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_07Mayo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_21Mayo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_04Junio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_18Junio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_02Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_16Julio.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_30Julio.jpg'),
    require('../../../assets/images/sitio/noticias/conapred.jpg'),
    require('../../../assets/images/sitio/noticias/impuestorosa.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_13Agosto.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_PrivilegiosMaculinos.jpg'),
    require('../../../assets/images/sitio/noticias/resisitirparaexistir_2.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_ViolenciaDeGeneroDigital_not.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/Maf_01Octubre.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_ContenidoConProposito.jpg'),
    require('../../../assets/images/sitio/noticias/representacionfemenina_780.jpg'),
    require('../../../assets/images/sitio/noticias/pobrezaygenero-780.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_11feb.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_25_feb.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_11mzo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_25mzo.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_8abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_22abril.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/SOLDEMEXICO_6mayo.jpg'),
    require('../../../assets/images/sitio/noticias/protegetusdatos.png'),
    require('../../../assets/images/sitio/noticias/leydeamnistia-bannerart.jpg'),
    require('../../../assets/images/sitio/noticias_doctora/soldemexico_-4noviembre.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_Masculinidad.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_HomeOffice.jpg'),
    require('../../../assets/images/sitio/noticias/Maf_CiberSegNoticias.jpg')
];



let categoria_seleccionada, img_src_categoria_seleccionada;

export const CardsCategoria = (props) => {

    const { categoria } = props;

    /*switch (categoria) {
        case 'responsabilidad_social': 
        categoria_seleccionada = categoria_responsabilidad_social; 
        img_src_categoria_seleccionada = img_src_responsabilidad;
        break;
        case 'tips_empresariales': 
        categoria_seleccionada = categoria_tips_empresariales; 
        img_src_categoria_seleccionada = img_src_tips_empresariales;
        break;
        case 'violencia_de_genero': 
        categoria_seleccionada = categoria_violencia_genero; 
        img_src_categoria_seleccionada = img_src_violencia_genero;
        break;
        case 'webinars': 
        categoria_seleccionada = categoria_webinars; 
        img_src_categoria_seleccionada = img_src_webinars;
        break;
        case 'publicaciones': 
        categoria_seleccionada = categoria_publicaciones; 
        img_src_categoria_seleccionada = img_src_publicaciones;
        break;
        case 'articulos': 
        categoria_seleccionada = categoria_articulos; 
        img_src_categoria_seleccionada = img_src_articulos;
        break;
        case 'todos': 
        categoria_seleccionada = categoria_todos; 
        img_src_categoria_seleccionada = img_src_todos;
        break;
        default: categoria_seleccionada = categoria_todos; img_src_categoria_seleccionada = img_src_todos; break;
    }*/
    
    console.log(categoria);
    categoria_seleccionada = categoria_todos.filter(articulo =>{
       for (let i = 0; i < articulo.categorias.length; i++){
         if(articulo.categorias[i] == categoria){
           return true;
         }
       }
       return false;
    });
    img_src_categoria_seleccionada = img_src_todos;
    let cards_categoria = categoria_seleccionada.slice(0).reverse().map(categoria => {

        return(
            <>
            <Col xs={{span:24, offset:0}} sm={{span:20, offset:2}} md={{span:12, offset:0}} className="contenedor_item_categoria">
                <Row align="bottom">
                    <Col span={24}>
                    <div className="altura_contenedor_1">
                        <div  className="altura_contenedor_2">
                            <div>
                            <p class="titulo_item_categoria">
                        {categoria.titulo}
                    </p>
                            </div>
                        </div>
                    </div>
                    </Col>
                    <Col span={24}>
                        <hr class="linea_citem_ategoria" />
                    </Col>
                    <Col span={24}>
                    <p class="info_item_categoria">
                { categoria.fecha}<br />
                    { categoria.info}
                </p>

                <br />
                <a target={categoria.externo ? '_blank' : ''} href={categoria.url} className="link_item_categoria">
                
                <img alt="Categoria" src={img_src_categoria_seleccionada[categoria.index_img]} className="img_item_categoria" />
                </a>
                </Col>
                </Row>
                
                
        </Col>
            </>
        );
    });

    return (
        <Row>
            { cards_categoria }
        </Row>
    );
}