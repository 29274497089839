import React from 'react';
import { Row, Col } from 'antd';

import img_src_header from '../../../assets/images/paginas/webinars/micromachismo/header_micromachismo.jpg';
import img_src_header_fromow from '../../../assets/images/paginas/webinars/micromachismo/machismo_dra_fromow.png';
import img_src_header_jose from '../../../assets/images/paginas/webinars/micromachismo/machismo_jose_arturo.png';
import img_src_header_fernando from '../../../assets/images/paginas/webinars/micromachismo/machismo_fernando.png';

export const Micromachismo = () => {
    return(
        <Row >
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                <img alt="Articulo Micromachismo" src={img_src_header} style={{width:'100%'}} />
                <br /><br />
                <iframe width="100%" height="550" src="https://www.youtube.com/embed/X0BO3fq7hdQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br />
                <Row>
                    <Col xs={{span:20, offset:2}} md={{span:8, offset:0}} style={{textAlign:'center', paddingLeft:'30px', paddingRight:'30px'}}>
                        <img alt="Header fromow" src={img_src_header_fromow} style={{width:'60%'}} />
                        <p className="color_gris_claro texto_tamano" style={{textAlign:'justify'}}>
                        Destacada abogada mexicana egresada de la Universidad Nacional Autónoma de México, cursó la Maestría en Salud Pública por el Instituto Nacional de Salud Pública. Y en el extranjero, su formación se distingue por la Maestría en Tributación Fiscal por el Centro de Estudios Financieros de Madrid, y el Doctorado en Derecho por la Universidad Complutense de Madrid, España.
                        <br />
                            <details>
                                <summary>Leer más</summary>
                                <p>Desde hace más de dos décadas, su trayectoria profesional ha sido notable en distintas áreas del derecho, como el corporativo, administrativo, penal, electoral y fiscal.<br /><br />
                                A lo largo de su trayectoria profesional, se ha distinguido por un liderazgo ideal, tanto en el ámbito gubernamental como en el corporativo, derivado de su capacidad de innovar, planear y comunicar la creación de estrategias integrales y transversales dirigidas a metas y objetivos claros, y con enfoque a resultados.<br /><br />
                                Hoy en día, se desarrolla como experta en el diagnostico, diseño, cabildeo, implementación y evaluación de estrategias integrales en temas políticos, jurídicos y legislativos que, a través del impulso de tácticas en beneficio del entorno donde se desenvuelvan, sea social, publico o privado, su compromiso y responsabilidad, le permiten lograr resultados contundentes. Ha continuado sumando acciones y propuestas que fortalezcan la operación eficiente y coordinada de sistema penal acusatorio.<br /><br />
                                Resalta su experiencia laboral en el sector privado en el Grupo Empresarial Cemex, Grupo Maseca, y MetLife México; y en el sector público federal, fue Titular de la Fiscalía Especializada para la Atención de Delitos Electorales (FEPADE); Titular de la Unidad Coordinadora de Vinculación y Participación Social de la Secretaria de Salud y Titular de la Secretaria Técnica del Consejo de Coordinación para la Implementación del Sistema de Justicia Penal Acusatorio de la Secretaria de Gobernación. <br /><br />
                                En el ámbito académico, ha sido profesora de licenciatura y posgrado en las materias Derecho Administrativo, Derecho Penal Electoral y Estructura Jurídico Político del Estado Mexicano, en la Facultad de Derecho de la Universidad Nacional Autónoma de México. Ha sido conferencista y articulista nacional e internacional.<br /><br />Ha recibido diversos reconocimientos y distinciones por su trayectoria profesional y su contribución a la cultura de la legalidad, derechos humanos, democracia, gobernanza e implementación de la reforma penal.<br /><br />
                                Actualmente es la Directora General de MAF y Asociados en donde ha logrado articular esfuerzos entre instituciones del sector público y privado en temas de prevención del delito y violencia de genero. Ha realizado actividades de reestructuración de modelos de atención a victimas y capacitación para atención del fenómeno de la violencia. Ha trabajado en los estados de Veracruz, Puebla, Jalisco y con instituciones a nivel federal. Asimismo, asesora a diversas Cámaras Empresariales (Coparmex, Concamin y ANTP, CCE, ASUME) para articular esfuerzos sobre el combate a la delincuencia y favorezcan el entorno social.<br /><br />
                                Destaca hoy su participación en un proyecto de fortalecimiento del Estado de Derecho y anticorrupción que está siendo generado por el Gobierno de Reino Unido para apoyar a nivel federal y local a México. 
                                </p>
                            </details>
                        </p>
                    </Col>
                    <Col xs={{span:20, offset:2}} md={{span:8, offset:0}} style={{textAlign:'center', paddingLeft:'30px', paddingRight:'30px'}}>
                        <img alt="Articulo Micromachismo" src={img_src_header_jose} style={{width:'60%'}} />
                        <p className="color_gris_claro texto_tamano" style={{textAlign:'justify'}}>
                        Psicólogo por la UNAM, Especialista en Dirección Estratégica de Recursos Humanos por la Universidad Complutense de Madrid. Especialista en Estrategias del Pensamiento Creativo por el Tec de Monterrey. Especialista en Ciencias de la Felicidad por Berkeley University.
      <details>
        <summary>Leer más</summary>
        <p>Premio Nacional Juvenil de Ciencia y Tecnología en México.<br /><br />
          Reconocimiento por la UNAM para Fundación Carolina de España como Jóvenes Líderes de Latinoamérica.<br /><br />
          En el sector privado ha colaborado en las áreas estratégicas de Desarrollo Organizacional y Comercial para empresas como General Motors, América Móvil Telcel, AT&T, Teletech, entre otros. <br /><br />
          Colabora en medios digitales y tradicionales como TV, radio, revistas y diarios con temas de desarrollo humano estratégico y sostenible.<br /><br />
        Ha desarrollado programas y colaborado en temas de Desarrollo Humano Sostenible, Alta Dirección, Gobernanza y DO, así como Resiliencia para Gobierno Federal, Estatales y Locales, así empresas como: E&Y, Samsung, Telcel, Ford, General Motors, Pearson, Carl Zeiss Vision, L´Oréal, Royal Canin, Sanborns, Devlyn, Henkel, INDITEX, BBVA, Telefónica, PWC, entre otros.
      </p>
      </details>
                        </p>
                    </Col>
                    <Col xs={{span:20, offset:2}} md={{span:8, offset:0}} style={{textAlign:'center', paddingLeft:'30px', paddingRight:'30px'}}>
                        <img alt="Header Fernando Micromachismo" src={img_src_header_fernando} style={{width:'60%'}} />
                        <p className="color_gris_claro texto_tamano" style={{textAlign:'justify'}}>
                       
        Licenciado en Sociología, Maestro en Política Criminal por la UNAM FES-Acatlán y Maestro en Criminología por el Instituto de Formación Profesional de la PGJDF. Actualmente colaborador del proyecto de Derechos Humanos con la Asociación Mexicana de Resiliencia AC, además capacitador en temas de Seguridad Pública, Protocolos de Atención a Víctimas del Delito y Métodos alternativos de Resolución de Conflictos.
      
      <details>
        <summary>Leer más</summary>
        <p>Docente en la Universidad del Valle de México, Coordinador y Docente del Diplomado en Derechos Humanos y Seguridad con la Universidad Iberoamericana, docente en la Universidad de Ciencias Jurídicas de Morelos, Instituto para la Paz y el Desarrollo y el Museo Memoria y Tolerancia. Conferencista en Derechos Humanos para Grupo CIE, OCESA y capacitador para el Gobierno del Estado de Michoacán con la Secretaria de Igualdad Sustantiva en proyectos de Prevención de Violencia de Género y Masculinidades.  Socio Fundador de Isonomía Asociación Civil en capacitación en Derechos Humanos.</p>
      </details>
                        </p>
                    </Col>
                </Row>
            </Col>
            <Col span={20} offset={2}>
                <p className="color_naranja titulo_tamano" style={{textAlign:'center'}}>"Descubriendo cómo vivo mi masculinidad”</p>
                <p className="color_gris_claro texto_tamano" style={{textAlign:'justify'}}>
                Sólo tienes que responder “SI” o “NO” a las situaciones que se presentan a continuación.
                <br /><br />
1. Te ha pasado que al compartir o platicar con alguien cercano a ti, sobre el cuidado que haces de tus hijas o hijos, recibes comentarios como: “Ahora eres niñera”, “Sólo eso te faltaba”, “Tu mujer ha de estar muy ocupada”, “Mañana me vas a decir que te pusiste a cocinar”.
<br /><br />
2. Has identificado si en los momentos íntimos con tu pareja, antepones tu placer sexual por encima del suyo, llegando incluso a no preguntar sobre sus gustos o necesidades.
<br /><br />
3. Consideras, crees o has defendido la postura de que el hombre es quien debe proveer y brindar seguridad a la familia y en la casa.
<br /><br />
4. Has usado las palabras provocador o incitante para describir el atuendo de una mujer.
<br /><br />
5. Te has sentido molesto, frustrado, incómodo o incluso con poca estima, cuando el sueldo de tu novia o esposa es más alto que el tuyo.
<br /><br />
6. Crees o has compartido la idea de que, si una mujer crece en su trabajo o profesión, es porque saca ventaja de sus atributos femeninos y no por sus habilidades o capacidades.
<br /><br />
7. Has pensado que: las mujeres son más machistas que los hombres o las mujeres son más violentas que los hombres.
<br /><br />
8. Has realizado alguno de los siguientes comentarios a una mujer: “para cuándo los hijos”, “tan bonita y tan soltera”, “para cuándo la boda”, “mejor cásate”, “se te va a ir el último tren”, “mejor ponte a cocinar” o “ser mamá es lo mejor que te puede pasar en la vida”.
<br /><br />
9. Has utilizado frases como: “esa no es forma de hablar para una señorita”, “las señoritas no salen vestidas así”, “esas no son formas de conducirse para una dama”, “eres una dramática”, “estás exagerando” o “de seguro estás en tus días”.
<br /><br />
10. Has mencionado o defendido la postura de que una mujer que ya tiene pareja, no necesita estar conociendo nuevas personas y mucho menos hombres.
<br /><br />
11. Has usado la palabra “feminazi” para describir el comportamiento de una mujer.
<br /><br />
12. Has pensando que en una pareja del mismo sexo alguien debe ser el hombre.
<br /><br />
13. Has considerado que el feminicidio no debería existir puesto que es lo mismo que un homicidio.
<br /><br />
14. Cuando se habla de violencia contra las mujeres argumentas que los hombres también sufren de violencia.
<br /><br />
15. Has pensado que el feminismo significa odiar a los hombres.
                </p>
                <p className="color_gris_oscuro titulo_tamano" style={{textAlign:'center', fontWeight:'bold'}}>
                ¿Te identificaste con alguno de los puntos?<br />
¡Eso es machismo!
                </p>
            </Col>

        </Row>
    );
}