import React from 'react';
import { Row, Col } from 'antd';
import Helmet from "react-helmet";

import img_src_header from '../../../assets/images/paginas/ley_aministia/leydeamnistia_art.jpg';
import img_src_header_movil from '../../../assets/images/paginas/ley_aministia/leydeamnistia-bannerart.jpg';

import img_1 from '../../../assets/images/paginas/ley_aministia/UASJ_de_la_SEGOB_.png';
import img_2 from '../../../assets/images/paginas/ley_aministia/ProcesoEjecPenalH.jpeg';

import { Link } from 'react-router-dom';

export const LeyAmistia = () => {
    return(
        <>
        <Helmet>
        <meta name="description" content=" Amnistía es un término que coloquialmente se identifica como “perdón”, sin embargo, la definición jurídica atiende a diversas características y la más importante hace referencia al “olvido”." />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.mafyasociados.com/a-un-anio-de-la-ley-de-aminstia" />
        <meta property="og:locale" content="es_MX" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content=" Sandra Evelyn Esquivel Flores e Itzbe Rodríguez Ciurana" />
        <meta name="author" content=" Sandra Evelyn Esquivel Flores e Itzbe Rodríguez Ciurana" />
        <meta name="keywords" content="Ley de amnistía, observatorio de amnistías, Comisión de amnistía, Unidad de apoyo al sistema de justicia, Formulario de solicitud de amnistía, Solicitudes de amnistía, extinción de la responsabilidad penal anteriormente decretada, primeros cinco casos de liberación por procedencia de solicitud de amnistía, justicia social, derechos humanos, Justicia Transicional, aborto, homicidio en razón de parentesco, delitos cometidos contra la salud, Delitos perpetrados por personas indígenas a quienes no se les haya garantizado un debido proceso, delito de sedición, robo simple y sin violencia, Sandra Evelyn Esquivel" />
        <meta property="og:title" content="A UN AÑO DE LA LEY DE AMNISTÍA "/>
        <meta property="og:description" content=" Amnistía es un término que coloquialmente se identifica como “perdón”, sin embargo, la definición jurídica atiende a diversas características y la más importante hace referencia al “olvido”." />
        <meta property="og:url" content="https://www.mafyasociados.com/a-un-anio-de-la-ley-de-aminstia" />
        <meta property="og:site_name" content="MAF & Asociados" />
        <meta property="og:image" content="https://mafyasociados.com/archivos/vistas/leydeamnistia-bannerart.jpg" />
        <meta property="og:image:secure_url" content="https://mafyasociados.com/archivos/vistas/leydeamnistia-bannerart.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content=" Amnistía es un término que coloquialmente se identifica como “perdón”, sin embargo, la definición jurídica atiende a diversas características y la más importante hace referencia al “olvido”." />
        <meta name="twitter:title" content="A UN AÑO DE LA LEY DE AMNISTÍA. " />
        <meta name="twitter:image" content="https://mafyasociados.com/archivos/vistas/leydeamnistia-bannerart.jpg" />
      </Helmet>
        <Row >
            <Col xs={0} md={24}>
                <img alt="Artiuculo Ley Aminstía" src={img_src_header} style={{width:'100%'}} />
            </Col>
            <Col xs={24} md={0}>
                <img alt="Artiuculo Ley Aminstía" src={img_src_header_movil} style={{width:'100%'}} />
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
            <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}><br />
              Por Sandra Evelyn Esquivel Flores e Itzbe Rodríguez Ciurana
            </p>
    
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    Amnistía es un término que coloquialmente se identifica como “perdón”, sin embargo, la definición jurídica atiende a diversas características y la más importante hace referencia al “olvido”. En México, contamos con una ley que permite que, personas privadas de la libertad en centros de reinserción social, que cometieron ciertos delitos de menor impacto, puedan solicitar su libertad y la conclusión de su proceso penal.
    <br /><br />
    Existen personas que desconocen la existencia de esta ley. Algunas más, ignoran los supuestos aplicables y el procedimiento requerido para solicitar la extinción de la responsabilidad adquirida. Por estas razones, es importante conocer la ley que lo prevé, qué se ha hecho hasta ahora y qué es necesario hacer en el futuro.
    </p>
    <h2 className="color_gris_claro titulo_tamano" ><strong>¿Qué es la Ley de Amnistía?</strong></h2>
    
    <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
    La Oficina del Alto Comisionado de las Naciones Unidas para los Derechos Humanos (ACNUDH), define a la amnistía como la anulación retrospectiva de la responsabilidad jurídica anteriormente determinada<sup>1</sup>.  Es decir, jurídicamente la amnistía es la causa de extinción de la responsabilidad penal y la manera de concluir los procesos penales previamente iniciados, prevenir los futuros y en general, la absolución de los delitos cometidos.
      <br /><br />
      En México, la <a href="http://www.diputados.gob.mx/LeyesBiblio/pdf/LAmn_220420.pdf" className="link_articulo" target="_blank">Ley de Amnistía</a> fue publicada en el Diario Oficial de la Federación el 22 de abril de 2020. Para el 18 de junio del mismo año, se expidió el acuerdo que ordenaba la creación de la <strong>Comisión de Amnistía</strong> quien sería la encargada de vigilar el cumplimiento y la aplicación de la Ley. Dicha Comisión está conformada por un representante de la <strong>Secretaría de Seguridad Pública Ciudadana </strong>(SSPC), la <a href="http://justicia.segob.gob.mx/es/UASJ/Ley_de_Amnistia" className="link_articulo" target="_blank">Unidad de Apoyo al Sistema de Justicia</a> (UASJ) adscrita a la <strong>Secretaría de Gobernación, el Instituto Nacional de las Mujeres</strong> (INMUJERES), el <strong>Instituto Nacional de los Pueblos Indígenas</strong> (INPI) y presidido por la titular de la Secretaría de Gobernación. A través de las sesiones de la Comisión, se determina la procedencia o improcedencia de las solicitudes realizadas. Aquellas procedentes, son enviadas a las y los jueces federales que corresponda para la determinación final.
        
        </p>
        <h2 className="color_gris_claro titulo_tamano" ><strong>Cifras durante el primer año de vigencia de la ley</strong></h2>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Paulina Téllez Martínez, titular de la <strong>Unidad de Apoyo al Sistema de Justicia (UASJ)</strong>, quien funge como Secretaria Técnica de la Comisión de Amnistía, informó que para el mes de enero del 2021, se recibieron aproximadamente un total de 1,115 solicitudes provenientes de diferentes Entidades de la República, de las cuales 173 son de mujeres y las 942 restantes de hombres. Asimismo, de la cifra total, 67 solicitudes corresponden a casos de personas indígenas. Las Entidades que solicitaron amnistía son las siguientes<sup>2</sup>:
        <ul>
          <li>183 solicitudes recibidas provenientes de Sinaloa</li>
          <li>158 solicitudes recibidas provenientes de Baja California</li>
          <li>141 solicitudes recibidas provenientes de Chiapas</li>
          <li>61 solicitudes recibidas provenientes de San Luis Potosí</li>
          <li>52 solicitudes recibidas provenientes de Guerrero</li>
          <li>50 solicitudes recibidas provenientes de Jalisco</li>
          <li>38 solicitudes recibidas provenientes de Nayarit</li>
          <li>35 solicitudes recibidas provenientes de Estado de México</li>
          <li>35 solicitudes recibidas provenientes de Ciudad de México</li>
          <li>32 solicitudes recibidas provenientes de Quintana Roo</li>
        </ul>
        
        </p>
        <p style={{textAlign:'center'}}>
        <img src={img_1} alt="UASJ_de_la_SEGOB" className="img_articulos_100" />
        </p>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Para el mes de abril del 2021 y de acuerdo con los datos de la Comisión de Amnistía, se dio a conocer que existe un total de 38 casos en los que se han llevado a cabo audiencias en instancias judiciales federales en diversas Entidades Federativas, notificando la confirmación de los primeros cinco casos de liberación por procedencia de solicitud, destacando que aún tiene un total de 967 casos en revisión y 388 casos resueltos.<sup>3</sup>
        <br /><br />
        Para poder descifrar el alcance y el significado de los números anteriores, es necesario comprender cómo funciona la ley, por ello, te explicamos un poco sobre ella y su procedimiento.
        </p>
        <h2 className="color_gris_claro titulo_tamano" ><strong>¿Cómo funciona la Ley de Amnistía?</strong></h2>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        El artículo 1º de la ley establece los supuestos de los delitos bajo los cuales se puede decretar amnistía en favor de las personas que hayan sido procesadas o recibieron una sentencia firme, destacando entre ellos los delitos de aborto, determinados delitos contra la salud y aquellos cometidos por personas indígenas que no contaron con una defensa legal adecuada y en su lengua, tal como se ilustra en la siguiente imagen:
          </p>
          <p style={{textAlign:'center'}}>
        <img src={img_2} alt="UASJ_Secretaria_de_Gobernacion" className="img_articulos_50" />
        <p style={{textAlign:'center', color:'black'}}><i>Fuente: UASJ Secretaría de Gobernación</i></p>
        </p>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Con la intención de dar seguimiento a la implementación y respeto de la ley, se creó el <a href="https://observatoriodeamnistias.org/" className="link_articulo" target="_blank" >Observatorio de Amnistía</a>, originado por la coalición de diversas organizaciones de la sociedad civil, dentro de las que destacan aquellas dedicadas a la justicia social, derechos humanos, asesoría y defensa legal. El observatorio cuenta con un mapa interactivo de casos donde se ve reflejado el proceso de implementación, así como conocer si ya cuentan con propuestas de iniciativas en sus congresos locales e incluso el número de beneficiarios de amnistía en cada una de las Entidades Federativas.
        </p>
        <h2 className="color_gris_claro titulo_tamano" ><strong>¿Cómo se inicia el procedimiento?</strong></h2>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        El procedimiento inicia con una solicitud que puede realizarse a través del portal electrónico de la UASJ. Esta solicitud puede realizarse mediante 3 formularios distintos dependiendo del tipo de solicitante, enlaces que dejamos a continuación:
        <ul>
        <li><a href="http://justicia.segob.gob.mx/work/models/UASJ/Documentos/PDF/Formulario_persona_interesada.pdf" className="link_articulo" target="_blank">Persona interesada</a>;</li>
        <li><a href="http://justicia.segob.gob.mx/work/models/UASJ/Documentos/PDF/Formulario_familiares_y_representante_legal.pdf" className="link_articulo" target="_blank">Familiares o representantes legales</a> y;</li>
        <li><a href="http://justicia.segob.gob.mx/work/models/UASJ/Documentos/PDF/Formulario_organismos_publicos.pdf" className="link_articulo" target="_blank">Organismos públicos defensores de derechos humanos</a>.</li>
        </ul>
        En cualquiera de los supuestos anteriores, el contenido del formulario requiere los siguientes datos:
        <ul>
          <li>Datos generales</li>
          <li>Situación jurídica </li>
          <li>Documentación del interesado</li>
          <li>Firma de la solicitud</li>
        </ul>
        Esta información se remitirá a los miembros de la Comisión de Amnistía, quienes se encargarán de analizar el caso en particular para posteriormente enviarlo a un Juzgado Federal quien tomará la determinación final.
        </p>
        <h2 className="color_gris_claro titulo_tamano" ><strong>Acciones para el futuro</strong></h2>
        <p className="color_gris_claro texto_tamano" style={{ textAlign:'justify'}}>
        Uno de los grandes problemas que actualmente enfrenta la Ley es la escasa difusión acerca de los procedimientos, la falta de transparencia en cuanto a las solicitudes realizadas, el estado que guardan los procesos ya solicitados y los aspectos legales faltantes que se necesitan para la consecución del procedimiento de amnistía de aquellas personas que ya iniciaron su proceso. En este sentido, es necesario que las autoridades le den difusión a lo establecido por la Ley que nos ocupa, dentro de aquellos espacios estratégicos en donde el alcance puede ser mayor y de utilidad para las personas procesadas que así lo requieran. Aunado a lo anterior, es necesario que se cuente con información estadística que hable específicamente de las solicitudes y procesos de amnistía dado la dificultad de obtener cifras verídicas de los sitios oficiales.
        <br /><br />
        Es de especial relevancia que las y los actores encargados de la aplicación y vigilancia de la ley, otorguen una atención eficiente y efectiva a todos y cada una de las personas solicitantes, disminuyendo los tiempos procesales y eliminando los aspectos burocráticos respecto del procedimiento, intentando garantizar pleno respeto a los derechos humanos de las y los interesados en todo momento.
        <br /><br />
        </p>
                <p
            className="color_gris_claro texto_tamano"
            style={{ textAlign: "justify" }}
          >
              <hr style={{ marginLeft: '20px', marginRight: '20px' }} />
              <small>
                  <sup><strong>1</strong></sup> Instrumentos del Estado de Derecho para sociedades que han salido de un conflicto. Amnistías. Alto Comisionado de las Naciones Unidas para los Derechos Humanos.
                  <br />
                  <sup><strong>2</strong></sup><a href="https://www.youtube.com/watch?v=8nUPxZEMbs0&t=284s" target="_blank" className="link_articulo"> Reporte de la Unidad de Apoyo al Sistema de Justicia de la SEGOB. Gobierno de México.</a>
                  <br />
                  <sup><strong>3</strong></sup><a href="https://www.gob.mx/segob/prensa/confirman-primeras-cinco-liberaciones-por-procedencia-de-amnistia" target="_blank" className="link_articulo">  Comunicado. Confirman primeras 5 liberaciones por procedencia de amnistía. Boletín de prensa Secretaría de Gobernación.</a>
                  <br />
              </small>
          </p>
            </Col>
           
        </Row>
        </>
    );
}