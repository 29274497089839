import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

export const AvisoPrivacidad = () => {

    return(
        <Row>
            <Col span={24}><br /></Col>
            <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}  >
                <p className="color_naranja titulo_aviso" style={{textAlign:'center'}}>AVISO DE PRIVACIDAD</p>
                <p className='color_gris_claro texto_aviso' style={{textAlign:'center'}}>
                En cumplimiento a lo mandatado por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, se le informa que:
                </p>
                <p className='color_gris_claro texto_aviso' style={{textAlign:'justify'}}>
                MAFR CONSULTORÍA ESTRATÉGICA, S.C., con domicilio en Avenida Newton No. 232-PB, Col. Polanco Chapultepec, Delegación Miguel Hidalgo, CP 11560, en la Ciudad de México, es responsable del uso y la protección de sus Datos Personales, y sobre el particular le informamos lo siguiente:
                <br /><br />
                Los datos que nos proporciona serán necesarios para elaborar CFDI (facturas) con los requisitos señalados por las leyes fiscales que correspondan, elaboración de cotizaciones, convenios y contratos, para realizar cobranza, para llevar a cabo los servicios profesionales contratados, cumplimiento de contratos celebrados, solicitar información sobre el impacto y resultado de los servicios otorgados, identificar al titular.
                <br /><br />
                Los datos personales que se les solicitan serán los siguientes: Nombre, domicilio fiscal, domicilio particular, Registro Federal de Contribuyentes, número telefónicos fijos, números telefónicos móviles, correo electrónico, los cuales serán tratados de manera lícita, bajo los principios de información, calidad, finalidad, lealtad y proporcionalidad y que serán protegidos y resguardados por MAF Consultoría Estratégica, S.C. a través de la Dirección de Administración y Finanzas.
                <br /><br />
                MAFR graba (audios y videos) y toma fotografías de los eventos que realiza tales como conferencias, eventos culturales, recreativos, de difusión, (de manera presencial y en línea), entre otros. Si usted ingresa o transita por las instalaciones de la MAFR o ingresa al portal en línea acepta que podrá ser grabado y/o fotografiado, consintiendo que la MAFR podrá utilizar, distribuir o transmitir las grabaciones y/o fotografías para la impartición ÚNICAMENTE de cursos, clases y otras actividades según lo establece el artículo 87 de la Ley Federal del Derecho de Autor, por lo que autoriza a MAFR para pueda ser incluida su imagen y el material que utilice, puedan ser transmitidas y/o retransmitidas y/o grabadas para su posterior utilización, ya sea línea o por cualquier medio electrónico que MAFR administra.
                <br /><br />
                Asimismo, se informa que tiene el derecho de acceder a los datos personales que tenemos sobre usted; solicitarnos la corrección de su información o que la eliminemos de nuestros registros o la dejemos de usar, así como de retirarnos tu consentimiento para utilizarla, a través del ejercicio de los Derechos ARCO consistente en:
                <br /><br />
                <strong>ACCESO:</strong> Conocer qué datos personales tenemos de Usted, para qué los utilizamos y las condiciones del uso que les damos.
                <br /><br />
                <strong>RECTIFICACIÓN:</strong> Solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta.
                <br /><br />
                <strong>CANCELACIÓN:</strong> Solicitar la eliminación de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme lo señala esta Aviso de Privacidad o bien han dejado de ser necesarios para la finalidad para que fueron recabados.
                <br /><br />
                <strong>OPOSICIÓN:</strong> Se puede oponer al uso de sus datos personales.
                <br /><br />
                En caso de que el titular de los datos personales quiera acceder, rectificar, cancelar u oponerse al uso de los datos proporcionados a MAF Consultoría Estratégica, S.C. es decir ejercer sus Derechos ARCO, deberá dirigir su solicitud por escrito a María Rebeca Félix Ruiz, con domicilio en av. Avenida Isac Newton No. 232-PB, Col. Polanco Chapultepec, Delegación Miguel Hidalgo, CP 11560, en la Ciudad de México o al correo electrónico <a className='link_sin_decoracion color_gris_claro' href="mailto:comiteintegridad@mafyasociados.com">comiteintegridad@mafyasociados.com</a>, con la siguiente información:
                </p>
                <ul className='color_gris_claro texto_aviso' style={{textAlign:'justify'}}>
                    <li>Nombre del titular de los datos personales o de su representante legal (Acreditar la representación legal mediante Poder Notarial)</li>
                    <li>Señalar domicilio y dirección de correo electrónico.</li>
                    <li>Copia de identificación oficial vigente (del titular de los datos personales y su representante legal)</li>
                    <li>Descripción clara y precisa de los datos personales respecto de los cuales requiera ejercer sus Derechos ARCO.</li>
                    <li>Cualquier elemento y documento que facilite la localización de los datos personales.</li>
                    <li>En el caso de solicitudes de RECTIFICACIÓN, el titular o su representante legal deberá indicar, adicional a lo señalado, las modificaciones a realizarse y aportar la documentación que sustente su petición.</li>
                </ul>
                <p className='color_gris_claro texto_aviso' style={{textAlign:'justify'}}>
                Una vez recibida la solicitud de ejercicio de Derechos ARCO, MAF Consultoría Estratégica, S.C., dentro de un plazo máximo de 20 días contados a partir de la recepción de la referida solicitud, informará a usted la determinación adoptada, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los 15 días siguientes a la fecha en que se comunica la respuesta. Los plazos anteriormente mencionados podrán ser ampliados una sola vez por un período igual, siempre y cuando así lo justifiquen las circunstancias del caso. Si la solicitud versa sobre la entrega de datos personales, se llevará a cabo la misma previa la acreditación de la identidad del solicitante o representante legal de manera presencial.
                <br /><br />
                Si el presente Aviso de Privacidad llegare a tener alguna modificación, la misma se le hará saber mediante comunicados electrónicos o bien con la publicación de avisos en el Portal de internet de MAF Consultoría Estratégica, S.C., a saber: <Link className='link_sin_decoracion color_gris_claro' to="/">www.mafyasociados.com</Link>.
                <br /><br />
                En caso de requerir alguna aclaración acerca del uso de sus datos personales, puede contactar a María Rebeca Félix Ruiz, comunicándose a los números telefónicos <a className='link_sin_decoracion color_gris_claro' href="tel:+525578251014">(55) 7825 1014</a> al 17 o mediante correo electrónico a la dirección electrónica <a className='link_sin_decoracion color_gris_claro' href="mailto:comiteintegridad@mafyasociados.com">comiteintegridad@mafyasociados.com</a>.
                <br /><br />
                He leído, doy mi consentimiento y acepto las condiciones del Aviso de Privacidad
                </p>
            </Col>
            <Col span={24}><br /><br /></Col>
        </Row>
    );
}
